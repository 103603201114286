import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayoutAppBar from './LayoutAppBar';
import LayoutDrawer from './LayoutDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '64px',
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
      height: 'calc(100vh - 56px)',
    },
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4rem',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.1)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      outline: `1px solid ${theme.palette.primary.main}`,
    },
    body: {
      overflow: 'hidden',
    },
  },
}));

type AppLayoutProps = {
  showDrawer?: boolean;
  children?: Array<React.ReactNode> | React.ReactNode;
};

export default function AppLayout(props: AppLayoutProps): JSX.Element {
  const { showDrawer = true, children } = props;
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(showDrawer);

  const handleMobileDrawerToggle = () => {
    if (showDrawer) {
      setMobileDrawerOpen(!mobileDrawerOpen);
    }
  };

  const handleDrawerToggle = () => {
    if (showDrawer) {
      setDrawerOpen(!drawerOpen);
    }
  };

  return (
    <>
      <LayoutAppBar onMobileDrawerToggle={handleMobileDrawerToggle} drawerOpen={drawerOpen} showDrawer={showDrawer} />
      <div className={classes.root}>
        {showDrawer ? (
          <div className={classes.drawer}>
            <LayoutDrawer
              onMobileDrawerToggle={handleMobileDrawerToggle}
              mobileDrawerOpen={mobileDrawerOpen}
              drawerOpen={drawerOpen}
              onDrawerToggle={handleDrawerToggle}
            />
          </div>
        ) : null}
        <main className={classes.content}>{children}</main>
      </div>
    </>
  );
}
