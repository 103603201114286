import { Repository } from '@top-solution/utils';
import { Company, CompanyCreatePayload } from '../entities/Company';
import { Compilation } from '../entities/Stats';

class CompaniesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/companies',
    });
  }

  async getList(): Promise<Array<Company>> {
    const { data } = await this.axios.get(`/`);
    return data;
  }

  async get(id: string): Promise<Company> {
    const { data } = await this.axios.get(`/${id}`);
    return data;
  }

  async create(company: CompanyCreatePayload): Promise<Company> {
    const { data } = await this.axios.post('/', company);
    return data;
  }

  async update(id: string, company: CompanyCreatePayload): Promise<Company> {
    const { data } = await this.axios.put(`/${id}`, {
      ...company,
      logo: company.logo?.match(/^https:\/\//) ? undefined : company.logo,
      credits: undefined,
    });
    return data;
  }

  async delete(id: string): Promise<Company> {
    const { data } = await this.axios.delete(`/${id}`);
    return data;
  }

  async getCompanyStats(id: string): Promise<Array<Compilation>> {
    const { data } = await this.axios.get(`/${id}/compilations`, {
      params: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    return data.map((comp: { day: number; number: number }) => {
      return {
        day: new Date(comp.day),
        number: comp.number,
      };
    });
  }
}

export default new CompaniesRepository();
