import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CompanyMenu from './CompanyMenu';
import ProfileMenu from './ProfileMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  shift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px) !important`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  withDrawer: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(7)}px)`,
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
}));

type LayoutAppBarProps = {
  onMobileDrawerToggle: () => void;
  drawerOpen: boolean;
  showDrawer: boolean;
};

export default function LayoutAppBar(props: LayoutAppBarProps): JSX.Element {
  const classes = useStyles();
  const { onMobileDrawerToggle, showDrawer, drawerOpen } = props;

  const handleDrawerIconButtonClick = () => {
    onMobileDrawerToggle();
  };

  return (
    <AppBar
      position="absolute"
      elevation={0}
      className={clsx(classes.root, showDrawer && classes.withDrawer, drawerOpen && classes.shift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerIconButtonClick}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {!showDrawer ? (
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            測 HAKA
          </Typography>
        ) : null}
        {showDrawer ? <CompanyMenu /> : null}
        {showDrawer ? (
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        ) : null}
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
