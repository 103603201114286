import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

import * as locales from '@material-ui/core/locale';
import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const getTheme = (locale: string): Theme => {
  let selectedLocale;

  switch (locale) {
    case 'it':
      selectedLocale = locales.itIT;
      break;
    case 'en':
      selectedLocale = locales.enUS;
      break;
    default:
      selectedLocale = locales.itIT;
  }

  let theme = createMuiTheme(
    {
      palette: {
        primary: {
          light: '#4dabf5',
          main: '#2196f3',
          dark: '#1769aa',
          contrastText: '#fff',
        },
        success: {
          main: green['600'],
          light: green['200'],
          dark: green['800'],
          contrastText: '#fff',
        },
        warning: {
          main: orange['600'],
          light: orange['200'],
          dark: orange['800'],
          contrastText: '#fff',
        },
      },
    },
    selectedLocale
  );

  // Overrides
  theme = {
    ...theme,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#18202c',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          colorPrimary: {
            color: '#fff',
          },
        },
      },
    },
  };

  return theme;
};

export default getTheme;
