import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RequestError, RequestState } from '@top-solution/utils';
import AssessmentsRepository from '../../api/AssessmentsRepository';
import CampaignsRepository from '../../api/CampaignsRepository';
import { Assessment, CreateAssessmentPayload } from '../../entities/Assessment';
import { Company } from '../../entities/Company';
import { RootState } from '../../store/reducers';
import { assessmentActions } from '../../store/reducers/assessments';

type UseAssessments = {
  assessmentsById: Record<Assessment['id'], Assessment>;
  readAssessmentList: (campaignId: Company['id']) => void;
  createAssessment: (assessment: CreateAssessmentPayload) => Promise<Assessment | undefined>;
  deleteAssessment: (assessmentId: Assessment['id']) => Promise<Assessment['id'] | undefined>;
  recallAssessor: (campaingId: string, assessorId: string) => void;
  readListRequest: RequestState;
  createAssessmentRequest: RequestState;
  deleteAssessmentRequest: RequestState;
  recallAssessmentRequest: RequestState;
};

export default function useAssessments(): UseAssessments {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      AssessmentsRepository.updateAuthToken(token);
    }
  }, [token]);

  const assessmentsById = useSelector((state: RootState) => state.assessments.byId);

  const readListRequest = useSelector((state: RootState) => state.assessments.requests.readList);
  const createAssessmentRequest = useSelector((state: RootState) => state.assessments.requests.create);
  const deleteAssessmentRequest = useSelector((state: RootState) => state.assessments.requests.delete);
  const recallAssessmentRequest = useSelector((state: RootState) => state.assessments.requests.recall);

  const readAssessmentList = useCallback(
    async (campaignId: string) => {
      dispatch(assessmentActions.readAssessmentListRequest());
      let assessments: Array<Assessment>;

      try {
        assessments = await AssessmentsRepository.getList(campaignId);
      } catch (error) {
        dispatch(assessmentActions.readAssessmentListFailure({ error: new RequestError(error) }));
        return;
      }

      dispatch(assessmentActions.readAssessmentListSuccess({ response: assessments }));
    },
    [dispatch]
  );

  const createAssessment: UseAssessments['createAssessment'] = useCallback(
    async (assessment) => {
      dispatch(assessmentActions.createAssessmentRequest());
      let response: Assessment;

      try {
        response = await AssessmentsRepository.create(assessment);
      } catch (error) {
        dispatch(assessmentActions.createAssessmentFailure({ error: new RequestError(error) }));
        throw new RequestError(error);
      }

      dispatch(assessmentActions.createAssessmentSuccess({ response }));
      return response;
    },
    [dispatch]
  );

  const deleteAssessment: UseAssessments['deleteAssessment'] = useCallback(
    async (assessmentId) => {
      dispatch(assessmentActions.deleteAssessmentRequest());
      try {
        await AssessmentsRepository.delete(assessmentId);
      } catch (error) {
        dispatch(assessmentActions.deleteAssessmentFailure({ error: new RequestError(error) }));
        return;
      }

      dispatch(assessmentActions.deleteAssessmentSuccess({ response: assessmentId }));
      return assessmentId;
    },
    [dispatch]
  );

  const recallAssessor: UseAssessments['recallAssessor'] = useCallback(
    async (campaignId, assessorId) => {
      dispatch(assessmentActions.recallAssessmentRequest());
      try {
        await CampaignsRepository.recallAssessor(campaignId, assessorId);
      } catch (error) {
        dispatch(assessmentActions.recallAssessmentFailure({ error: new RequestError(error) }));
        throw new RequestError(error);
      }

      dispatch(assessmentActions.recallAssessmentSuccess());
      return;
    },
    [dispatch]
  );

  return {
    assessmentsById,
    readAssessmentList,
    createAssessment,
    deleteAssessment,
    recallAssessor,
    readListRequest,
    createAssessmentRequest,
    deleteAssessmentRequest,
    recallAssessmentRequest,
  };
}
