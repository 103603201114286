/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    appFullName: 'Top Solution 測 HAKA',
    appShortName: '測 HAKA',
    yes: 'Sì',
    no: 'No',
    cancel: 'Annulla',
    confirm: 'Conferma',
    submit: 'Invia',
    save: 'Salva',
    add: 'Aggiungi',
    update: 'Aggiorna',
    delete: 'Elimina',
    retry: 'Riprova',
    ok: 'OK',
    close: 'Chiudi',
    next: 'Prosegui',
    back: 'Indietro',
    finish: 'Termina',
    search: 'Cerca',
    email: 'Email',
    password: 'Password',
    rowsPerPage: 'Righe',
    displayedRows: '{{from}}-{{to}} di {{count}}',
    employees: 'dipendenti',
    credits: 'Crediti',
    all: 'Tutti',
    from: 'Da',
    to: 'A',
  },
  form: {
    stringMinimum: 'Deve contenere almeno {{min}} caratteri',
    stringMaximum: 'Deve contenere al massimo {{max}} caratteri',
  },
  menu: {
    companySwitch: 'Cambia azienda',
    logout: 'Esci',
    addCredits: 'Ricarica',
    campaigns: 'Campagne',
    credits: 'Crediti',
    company: 'Azienda',
    companyData: 'Dati aziendali',
  },
  sidebar: {
    company: 'Azienda',
    companyOverview: 'Quadro Generale',
    companyData: 'Dettagli',
    campaigns: 'Campagne',
    peopleManagement: 'Personale',
    report: 'Report',
    seeReport: 'Guarda i report',
    employeesPerformance: 'Performance Dipendenti',
    executivesPerformance: 'Performance Dirigenti',
    360: '360',
  },
  errors: {
    unexpected: "D'oh!",
    required: 'Questo campo è obbligatorio',
    invalidMin: 'Deve contenere almeno {{chars}} caratteri',
    invalidMax: 'Deve contenere al massimo {{chars}} caratteri',
    invalidFormat: 'Formato non valido',
    invalidEmail: "Inserisci un'email valida",
    invalidPhone: 'Inserisci un numero di telefono valido',
    invalidVat: 'Deve contenere almeno 4 caratteri',
    httpErrorGeneric: 'Si è verificato un errore nel contattare il server',
  },
  assessmentsPage: {
    pageTitle: 'Scegli un questionario',
    pageHtmlTitle: 'Questionari',
    tablePollNameColumnTitle: 'Nome questionario',
    tableStatusColumnTitle: 'Stato',
    tableDueDateColumnTitle: 'Scadenza',
    selfEvaluation: 'Autovalutazione',
    expiresOn: 'Scade il ',
    expiredOn: 'Scaduto il ',
  },
  login: {
    title: 'Autenticazione',
    description: 'Clicca per essere reindirizzato alla pagina di autenticazione.',
    submit: 'Entra',
  },
  companiesManagement: {
    emptyList: 'Non hai ancora associato aziende al tuo account',
    select: "Seleziona l'azienda su cui operare",
    add: 'Nuova azienda',
    companyData: 'Anagrafica azienda',
    companyDataDescription:
      "Verifica attentamente tutti i campi, questi dati verranno utilizzati per l'emissione delle fatture.",
    deleteConfirmTitle: 'Sei sicuro di voler rimuovere {{name}}?',
    deleteConfirmDescription:
      'Questa operazione non è reversibile e comporta la perdita di tutto lo storico dei dati associati.',
  },
  company: {
    addCompany: 'Aggiungi azienda',
    name: 'Ragione sociale',
    email: 'Indirizzo email',
    pec: 'Indirizzo PEC',
    pecHelperText: 'Indirizzo Posta Elettronica Certificata',
    phone: 'Numero di telefono',
    address: 'Indirizzo sede legale',
    addressHelperText: "Inserisci l'indirizzo e seleziona una opzione dai suggerimenti",
    vatNumber: 'Partita IVA',
    sdiNumber: 'Codice Destinatario',
    sdiNumberHelperText: 'Codice destinario SdI per fatturazione elettronica',
    fiscalCode: 'Codice fiscale',
    fiscalCodeHelperText: 'Lascia vuoto se uguale alla Partita IVA',
    errorMissingEmailOrPhone: 'Compila il campo "Indirizzo email" oppure "Numero di telefono"',
    errorMissingPECorSDI: 'Compila il campo "Indirizzo PEC" oppure "Codice Destinatario"',
    size: 'Numero dipendenti',
    market: 'Settore',
    referralCode: 'Codice promozione',
    referralCodeHelperText: 'Se possiedi un codice promozionale inseriscilo qui',
    dragAndDropLogo: "Trascina qui un'immagine o clicca per aggiungere il logo dell'azienda",
    logoFileTooBigError: 'Il file caricato eccede le dimensioni massime consetite di 1MB',
    deleteUnspentCredit: "Impossibile cancellare i dati dell'azienda: l'azienda ha ancora dei crediti non spesi",
  },
  companySize: {
    xs: 'Fino a 9',
    sm: 'Da 10 a 19',
    md: 'Da 20 a 49',
    lg: 'Da 50 a 250',
    xl: 'Oltre 250',
  },
  companyMarket: {
    automotive: 'Automotive',
    finance: 'Finanza',
    ict: 'ICT',
    industry: 'Industria manifatturiera',
    healthcare: 'Sanità',
    public: 'Settore pubblico',
    services: 'Servizi',
  },
  peopleManagement: {
    title: 'Gestione del personale',
    emptyFilteredList: 'La ricerca non ha prodotto alcun risultato',
    emptyList: 'Non hai ancora aggiunto nessun dipendente',
    deleteConfirmTitle: 'Sei sicuro di voler rimuovere {{lastName}} {{firstName}}?',
    deleteConfirmDescription:
      'Questa operazione non è reversibile e comporta la perdita di tutto lo storico dei dati associati.',
    addDialogTitle: 'Aggiungi persona',
    addDialogDescription: 'Inserisci i dati del dipendente, tutti i campi sono obbligatori:',
    removePerson: 'Rimuovi persona',
    updateInfo: 'Aggiorna informazioni',
    departmentHelperText: 'Inserisci un reparto e premi invio per aggiungerlo',
  },
  person: {
    firstName: 'Nome',
    lastName: 'Cognome',
    email: 'Email',
    position: 'Ruolo',
    positionAndTitle: 'Ruolo e reparti',
    branch: 'Funzione',
    branches: 'Funzioni',
    hiredAt: 'Data di assunzione',
    departments: 'Reparti',
    department: 'Reparto',
  },
  position: {
    all: 'Tutti',
    worker: 'Operaio',
    apprentice: 'Apprendista',
    clerk: 'Impiegato',
    supervisor: 'Impiegato gestionale',
    manager: 'Quadro',
    executive: 'Dirigente',
  },
  branch: {
    all: 'Tutte',
    sales: 'Commerciale',
    purchasing: 'Acquisti',
    marketing: 'Marketing',
    administration: 'Amministrazione',
    hr: 'Risorse umane',
    rd: 'Ricerca & sviluppo',
    finance: 'Finance',
    quality: 'Qualità',
    operations: 'Operations',
    it: 'Reparto Informatica',
  },
  poll: {
    employeesPerformance: 'Performance dipendenti',
    executivesPerformance: 'Performance dirigenti',
    '360': 'Valutazione 360°',
  },
  campaignsManagement: {
    emptyList: 'Non hai ancora creato nessuna campagna per il questionario "{{name}}"',
    addDialogTitle: 'Nuova campagna',
    addDialogDescription: 'Inserisci i dati delle nuova campagna, tutti i campi sono obbligatori:',
    deleteAssessmentConfirmTitle: 'Sei sicuro di voler rimuovere la valutazione?',
    deleteAssessmentDescription: 'Questa operazione non è reversibile.',
  },
  campaign: {
    addCampaign: 'Aggiungi campagna',
    name: 'Nome',
    description: 'Descrizione',
    startDate: 'Inizio',
    endDate: 'Fine',
    fromTo: 'Dal {{startDate}} al {{endDate}}',
    status: 'Stato',
    scale: 'Scala della valutazione',
    start: 'Inizio campagna',
    end: 'Fine campagna',
  },
  campaignStatus: {
    all: 'Tutto',
    draft: 'Bozza',
    ready: 'Pronto',
    inProgress: 'In corso',
    closed: 'Chiuso',
  },
  assessmentManagement: {
    addDialogTitle: 'Nuova valutazione',
    addDialogDescription: 'Seleziona valutato e valutatore:',
    emptyFilteredList: 'La ricerca non ha prodotto alcun risultato',
    emptyList: 'Non hai ancora aggiunto nessuna valutazione',
  },
  assessment: {
    assessor: 'Valutatore',
    assessee: 'Valutato',
    status: 'Stato',
    selfAssessment: 'Autovalutazione',
    startAt: 'Iniziato il',
    endAt: 'Finito il',
    updatedAt: 'Ultimo aggiornamento',
    last_recall: 'Ultimo recall',
  },
  assessmentStatus: {
    all: 'Tutto',
    draft: 'Bozza',
    ready: 'Pronto',
    inProgress: 'In corso',
    closed: 'Chiuso',
    overdue: 'Scaduto',
  },
  assessmentPage: {
    thanksForCompiling: 'Grazie per aver compilato il questionario',
    completedPercentage: 'Questionario completo al: {{percentage}}%',
    backToAssessments: 'Torna alla lista dei questionari',
    answerAllQuestions: 'Rispondi a tutte le domande prima di teminare il questionario',
    textFieldsHaveErrors: 'Ricontrolla i testi inseriti prima di teminare il questionario',
    textMax500Chars: 'Il testo non deve contenere più di 500 caratteri',
    indications: 'Eventuali commenti integrativi',
    assessmentNotEditable: 'Il questionario non è modificabile',
    assessmentStatusNotValid: 'Il questionario non è modificabile in qunto è in stato "{{status}}"',
    finishModal: {
      title: 'Vuoi davvero terminare il questionario?',
      text: 'Terminare il questionario vuol dire non poter modificare piú le risposte in un secondo momento.',
      confirm: 'Termina',
      close: 'Annulla',
    },
    closeModal: {
      title: 'Vuoi davvero tornare alla lista dei questionari senza salvare?',
      text: "Tutte le modifiche apportate a partire dall'ultimo salvataggio saranno perse.",
      confirm: 'Chiudi',
      close: 'Annulla',
    },
    savedSnackbar: {
      message: 'Questionario salvato',
      error: 'Si è verificato un errore durante il salvataggio del questionario',
    },
    shortHelp: {
      beforeText: 'Nel valutare ogni singola capacità vi chiediamo di attenervi a queste indicazioni:',
      stars: {
        1: 'NON IN LINEA con le aspettative/richieste',
        2: 'PARZIALMENTE in linea con le aspettative/richieste',
        3: 'IN LINEA con le aspettative/richieste',
        4: 'TALVOLTA sopra le aspettative/richieste',
        5: 'COSTANTEMENTE sopra le aspettative/richieste',
      },
      afterText: 'Per maggiori informazioni, consulta la',
    },
  },
  pollsAuthPage: {
    title: 'Accedi ai tuoi questionari',
    description:
      'Scrivi qui il codice di accesso che ti è stato inviato via e-mail per procedere alla compilazione dei tuoi questionari:',
    accessToken: 'Codice',
    tokenNotValid: 'Il codice di accesso non è corretto',
  },
  overview: {
    chartTitle: 'Compilazioni Recenti',
    chartTooltip: '# di Compilazioni',
    chartNoData: 'Nessuna Compilazione Recente',
    errorCompilations: 'Errore nel recupero delle compilazioni',
    alertTitle: 'Avvisi',
    alertNoData: 'Nessun avviso',
  },
  companyMenu: {
    seeAll: 'Vedi tutte le aziende',
    add: 'Aggiungi azienda',
    all: 'Aziende',
  },
  companyData: {
    companyData: 'Dati aziendali',
    info: 'Informazioni',
    logo: 'Logo aziendale',
    users: 'Utenti',
    updateInfo: 'Aggiorna informazioni',
    updateCompanyInfo: "Aggiorna le informazioni sull'azienda",
    updateCompanyLogo: 'Aggiorna il logo aziendale',
    deleteCompanyData: "Cancella dati dell'azienda",
    removeCompany: 'Rimuovi azienda',
    removeCompanyText:
      "Quest'azione rimuoverà l'azienda e tutti i relativi dati dalla piattaforma, compresi gli assessment e i questionari. Quest'azione non è reversibile.",
    removeCompanyConfirmField: "Per confermare l'azione scrivi qui sotto il nome dell'azienda ( {{companyName}} ):",
  },
  dataTable: {
    noResult: 'Nessun risultato',
  },
  alert: {
    readyIn: 'Inizia il {{date}}',
    endIn: 'Si è conclusa il {{date}}',
    endAlert: 'manca {{ count }} questionario prima del {{ date }}',
    endAlert_plural: 'mancano {{ count }} questionari prima del {{ date }}',
    overdueAlert: ' e vi è un {{ count }} questionario non compilato',
    overdueAlert_plural: ' e vi sono {{ count }} questionari non compilati',
  },
  campaignsStatus: {
    tableTitle: 'Campagne',
    campaignName: 'Nome Campagna',
    statusCampaign: 'Stato',
    startAt: 'Inizio',
    endAt: 'Fine',
    pollType: 'Tipo',
    compilations: 'Compilazioni',
    noData: 'Nessuna campagna valida',
  },
  campaignDetailRow: {
    draft: 'Compilazioni in bozza',
    ready: 'Compilazioni pronte',
    in_progress: 'Compilazioni in corso',
    closed: 'Compilizaioni chiuse',
    overdue: 'Compilazioni scadute',
  },
  table: {
    noMatch: 'Nessun risultato corrispondente alla ricerca',
    toolTip: 'Ordina',
    columnHeaderTooltip: 'Ordina per ',
    next: 'Prossima pagina',
    previous: 'Pagina precedente',
    rowsPerPage: 'Righe per pagina:',
    displayRows: 'di',
    search: 'Cerca',
    downloadCsv: 'Scarica CSV',
    print: 'Stampa',
    viewColumns: 'Vedi Colonne',
    filterTable: 'Filtra Tabella',
    all: 'Tutto',
    filterTitle: 'Filtri',
    reset: 'Pulisci',
    showColumnTitle: 'Mostra Colonne',
    titleAria: 'Mostra/Nascondi Colonne',
    text: 'Righe selezionate',
    delete: 'Cancella',
    deleteAria: 'Cancella righe selezionate',
  },
  poolCampaigns: {
    currentCampaigns: 'Campagne attive',
    oldCampaigns: 'Campagne chiuse',
    actions: 'Azioni',
    details: 'Dettagli',
    open: 'Apri',
    deleteCampaign: {
      dialogTitle: 'Elimina campagna in bozza',
      dialogMessage: "Se confermi quest'azione, la campagna in bozza sarà eliminata:",
    },
    recall: 'Recall',
    recallConfirmTitle: 'Sei sicuro di voler lanciare il recall su {{name}}?',
    recallConfirmDescription:
      "Questa operazione manderà la mail di invito alla compilazione a TUTTI i compilatori associati alla campagna, evitarne l'abuso",
    errorCampaignNotInProgress: 'Campagna non iniziata',
    recallStarted: 'Invio Email iniziato con successo',
  },
  poolDetails: {
    seeReports: 'Vai ai reports',
    compilations: 'Compilazioni',
    recall: 'Recall',
    recallConfirmTitle: 'Sei sicuro di voler lanciare il recall su {{name}}?',
    recallConfirmDescription:
      "Questa operazione manderà la mail di invito alla compilazione al compilatore, evitarne l'abuso",
    errorAssessmentNotInProgress: 'Impossibile effettuare il recall assessment non attivo',
    recallStarted: 'Invio Email effettuato',
    errorRecall: "Errore nell'invio della mail",
    deleteAssessment: {
      dialogTitle: 'Elimina Assessment',
      dialogMessage: "Se confermi quest'azione, l'assessment sarà eliminato:",
    },
  },
  addAssessment: {
    success: 'Assessment aggiunto',
    error: 'Errore generico',
    errorconflict: 'Assessment già esistente',
    addAssessment: 'Aggiungi assessment',
    title: 'Nuovo assessment',
    assessor: 'Valutatore',
    assessee: 'Valutato',
    fieldRequired: 'Campo richiesto',
  },
  reports: {
    reports: 'Reports',
    error: 'Si è verificato un errore durante la generazione del report',
    successBatch: 'Ti è stata mandata una email con i report selezionati',
    dowload: 'Scarica',
    downloadReport: 'Report',
    downloadSelected: 'Scarica report selezionati',
    present: 'Presente',
    miss: 'Mancante',
    autopresent: 'Autovalutazione: Presente',
    automiss: 'Autovalutazione: Mancante',
    eteropresent: 'Eterovalutazione: Presente',
    eteromiss: 'Eterovalutazione: Mancante',
    autoCompleted: 'Autovalutazione',
    eteroCompleted: 'Eterovalutazione',
  },
};
