import { RequestError, RequestState } from '@top-solution/utils';

export function mutateRequestState(state: RequestState): void {
  state.inProgress = true;
}

export function mutateSuccessState(state: RequestState): void {
  state.inProgress = false;
  state.error = null;
  state.lastUpdate = new Date();
}

export function mutateErrorState(state: RequestState, error: RequestState['error']): void {
  state.inProgress = false;
  state.error = error;
}

export function requestState(): RequestState {
  return {
    inProgress: false,
    error: null,
    lastUpdate: null,
  };
}

export interface RequestFailureAction {
  error: RequestError;
}
