import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import EventNoteIcon from '@material-ui/icons/EventNote';

import { RequestError } from '@top-solution/utils';
import CompaniesRepository from '../../../../api/CompaniesRepository';
// import DataError from '../../../../entities/DataError';
import { Compilation } from '../../../../entities/Stats';
import { useCampaignResource } from '../../../../hooks/store/useCampaigns';
import DashedContainer from '../../../common/DashedContainer';
import DashboardAppLayout from '../../DashboardAppLayout';
import AlertSection from './AlertSection';
import CampaignsStatus from './CampaignsStatus';
import CompilationChart from './CompilationChart';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Overview(): JSX.Element {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper);
  const [companyCompilation, setCompanyCompilation] = useState<null | Compilation[]>(null);
  const [errorCompanyCompilation, setErrorCompanyCompilation] = useState<null | RequestError>(null);
  const { companyId } = useParams<{ companyId: string }>();
  const [campaignsById, campaignsReady] = useCampaignResource(companyId || '');
  const { t } = useTranslation(['overview']);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fn = async () => {
      try {
        if (companyId) {
          const compilations = await CompaniesRepository.getCompanyStats(companyId);
          setCompanyCompilation(compilations);
        }
      } catch (e) {
        setErrorCompanyCompilation(e);
      }
    };
    fn();
  }, [companyId]);

  useEffect(() => {
    if (errorCompanyCompilation) {
      if (errorCompanyCompilation.status === 400) {
        enqueueSnackbar(t('overview:errorCompilations'), { variant: 'error' });
      } else {
        throw errorCompanyCompilation;
      }
    }
  }, [enqueueSnackbar, errorCompanyCompilation, t]);

  const gotCompilationsLast30Days = (): boolean => {
    if (companyCompilation && companyCompilation.length) {
      const lastCompilation = companyCompilation[companyCompilation.length - 1];

      if (lastCompilation) {
        const lastCompilationDate = DateTime.fromJSDate(lastCompilation.day);
        const thirtyDaysAgo = DateTime.local().minus({ days: 30 });
        return lastCompilationDate > thirtyDaysAgo;
      }
    }
    return false;
  };

  return (
    <DashboardAppLayout>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={8}>
            <Paper className={fixedHeightPaper}>
              <Typography component="h1" variant="subtitle1" color="inherit" className={classes.wrapIcon}>
                <BarChartIcon className={classes.icon} /> {t('overview:chartTitle')}
              </Typography>
              {companyCompilation ? (
                gotCompilationsLast30Days() ? (
                  <CompilationChart compilations={companyCompilation} />
                ) : (
                  <Box height="20vh">
                    <DashedContainer>{t('overview:chartNoData')}</DashedContainer>
                  </Box>
                )
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
                  <CircularProgress />
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="subtitle1" color="inherit" className={classes.wrapIcon}>
                <EventNoteIcon className={classes.icon} /> {t('overview:alertTitle')}
              </Typography>
              {campaignsReady ? (
                Object.values(campaignsById).length ? (
                  <AlertSection />
                ) : (
                  <Box height="20vh">
                    <DashedContainer>{t('overview:alertNoData')}</DashedContainer>
                  </Box>
                )
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
                  <CircularProgress />
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <CampaignsStatus />
          </Grid>
        </Grid>
      </Container>
    </DashboardAppLayout>
  );
}
