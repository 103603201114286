import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, generatePath, useRouteMatch } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useAuth from '../../hooks/store/useAuth';
import { useCompaniesResource } from '../../hooks/store/useCompanies';
import { UserRoles } from '../../lib/constants/user-roles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listSubheader: {
      pointerEvents: 'none',
    },
    wrapperSelect: {
      flex: '1',
    },
    selectColor: {
      color: theme.palette.primary.contrastText,
    },
  })
);

const ProfileMenu = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { role } = useAuth();
  const match = useRouteMatch();
  const { companyId } = useParams<{ companyId: string }>();
  const { t } = useTranslation(['companyMenu']);

  const [companies] = useCompaniesResource();

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.value === 'seeAllCompany') {
      history.push('/');
      return;
    }
    if (event.target.value === 'addACompany') {
      history.push('/company/add');
      return;
    }

    history.push({
      pathname: generatePath(match.path.split('/:campaignId')[0] || '/dashboard/:companyId', {
        ...match.params,
        companyId: '' + event.target.value,
      }),
    });
  };

  const companiesListItems = Object.values(companies).map((company) => (
    <MenuItem value={company.id} key={company.id}>
      {company.name}
    </MenuItem>
  ));

  return (
    <div className={classes.wrapperSelect}>
      <Select
        classes={{ root: classes.selectColor, icon: classes.selectColor }}
        value={companyId ? companyId : ''}
        id="company-select"
        onChange={handleChange}
        disableUnderline
      >
        <MenuItem value="seeAllCompany">{t('companyMenu:seeAll')}</MenuItem>
        {role === UserRoles.ADMIN ? <MenuItem value="addACompany">{t('companyMenu:add')}</MenuItem> : null}
        <Divider />
        <ListSubheader className={classes.listSubheader}>{t('companyMenu:all')}</ListSubheader>
        {companiesListItems}
      </Select>
    </div>
  );
};

export default ProfileMenu;
