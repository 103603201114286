import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath, useParams, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';

import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, MuiThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import { Campaign } from '../../../../entities/Campaign';
import useAuth from '../../../../hooks/store/useAuth';
import useCampaigns, { useCampaignResource } from '../../../../hooks/store/useCampaigns';
import useMUIDatatableTranslations from '../../../../hooks/useMUIDatatableTranslations';
import { CampaignStatus } from '../../../../lib/constants/campaign-status';
import { UserRoles } from '../../../../lib/constants/user-roles';
import useConfirmDialog from '../../../common/ConfirmDialog/useConfirmDialog';
import DashedContainer from '../../../common/DashedContainer';
import DeleteDialog from '../../../common/DeleteDialog';
import DashboardAppLayout from '../../DashboardAppLayout';
import AddCampaignDialog from './AddCampaignDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  deleteMenuItem: {
    color: 'inherit',
  },
  shiftLeft: {
    marginLeft: theme.spacing(-1),
  },
}));

type TableContext = {
  rowData: Array<string>;
};

export default function PoolCampaigns(): JSX.Element {
  const classes = useStyles();
  const { companyId, pollId } = useParams<{ companyId: string; pollId: string }>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuCampaignId, setMenuCampaignId] = useState<null | Campaign['id']>(null);
  const history = useHistory();
  const match = useRouteMatch();
  const { role } = useAuth();
  const tableTranslation = useMUIDatatableTranslations();
  const [campaignsById, campaignsReady] = useCampaignResource(companyId || '');
  const { deleteCampaign, recallCampaign } = useCampaigns();
  const { t } = useTranslation(['poolCampaigns', 'campaignsStatus', 'campaignStatus', 'poll']);
  const { showConfirmDialog } = useConfirmDialog();
  const { enqueueSnackbar } = useSnackbar();

  const campaigns = useMemo(() => Object.values(campaignsById).filter((campaign) => campaign.poll.id === pollId), [
    campaignsById,
    pollId,
  ]);
  const activeCampaigns = useMemo(() => campaigns.filter((campaign) => campaign.status !== CampaignStatus.CLOSED), [
    campaigns,
  ]);

  const closedCampaigns = useMemo(() => campaigns.filter((campaign) => campaign.status === CampaignStatus.CLOSED), [
    campaigns,
  ]);

  const handleActionsClick = useCallback(
    (campaignId: Campaign['id']) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setMenuCampaignId(campaignId);
    },
    []
  );

  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCampaignRecall = (menuCampaignId: string) => {
    setAnchorEl(null);
    showConfirmDialog(
      {
        title: t('poolCampaigns:recallConfirmTitle', {
          name: `${campaignsById[menuCampaignId]?.name}`,
        }),
        message: t('poolCampaigns:recallConfirmDescription'),
        confirmButtonProps: {
          color: 'secondary',
        },
      },
      async () => {
        try {
          await recallCampaign(menuCampaignId);
          enqueueSnackbar(t('poolCampaigns:recallStarted'), { variant: 'success' });
        } catch (error) {
          if (error.status === 412) {
            enqueueSnackbar(t('poolCampaigns:errorCampaignNotInProgress'), { variant: 'error' });
          } else {
            throw error;
          }
        }
      }
    );
  };

  const columns = [
    {
      name: 'name',
      label: t('campaignsStatus:campaignName'),
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'status',
      label: t('campaignsStatus:statusCampaign'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: Campaign['status']) => t(value),
      },
    },
    {
      name: 'start_at',
      label: t('campaignsStatus:startAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Campaign['start_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'end_at',
      label: t('campaignsStatus:endAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Campaign['end_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'id',
      label: t('details'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: function OpenButton(id: string) {
          return (
            <Button
              className={classes.shiftLeft}
              color="primary"
              onClick={() => {
                history.push({
                  pathname: generatePath(match.path + '/:campaignId', { ...match.params, campaignId: '' + id }),
                });
              }}
            >
              {t('open')}
            </Button>
          );
        },
      },
    },
    ...(role === UserRoles.ADMIN
      ? [
          {
            name: 'actions',
            label: t('actions'),
            options: {
              filter: false,
              sort: false,
              customBodyRender: function OpenButton(_: unknown, context: TableContext) {
                const campaignId = context.rowData[4];
                if (typeof campaignId === 'string') {
                  return (
                    <IconButton
                      className={classes.shiftLeft}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleActionsClick(campaignId)}
                    >
                      <DotsVertical />
                    </IconButton>
                  );
                }
              },
            },
          },
        ]
      : []),
  ];

  const options: MUIDataTableProps['options'] = {
    filterType: 'dropdown',
    selectableRows: 'none',
    download: false,
    elevation: 1,
    print: false,
    viewColumns: false,
    pagination: true,
    textLabels: tableTranslation,
  };

  // Force muidatatable toolbar to have custom gutters
  const defaultTheme = useTheme();

  const theme = createMuiTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          gutters: {
            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          },
        },
      },
    },
  });
  const handleDeleteCampaign = useCallback(async () => {
    if (menuCampaignId) {
      await deleteCampaign(menuCampaignId);
    }
  }, [deleteCampaign, menuCampaignId]);

  return (
    <DashboardAppLayout>
      <Container maxWidth="md" className={classes.container}>
        <Box sx={{ marginTop: 0, marginBottom: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Typography component="h4" variant="h4" color="inherit">
            {t(`${pollId}`)}
          </Typography>
          {role === UserRoles.ADMIN ? <AddCampaignDialog /> : null}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {campaignsReady ? (
              activeCampaigns.length ? (
                <Box width="100%">
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title={t('currentCampaigns')}
                      data={activeCampaigns}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </Box>
              ) : (
                <Paper className={classes.paper}>
                  <Typography component="h6" variant="h6" color="inherit">
                    {t('currentCampaigns')}
                  </Typography>
                  <Box height="20vh" mt={2}>
                    <DashedContainer>{t('campaignsStatus:noData')}</DashedContainer>
                  </Box>
                </Paper>
              )
            ) : (
              <Paper className={classes.paper}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
                  <CircularProgress />
                </Box>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12}>
            {campaignsReady ? (
              closedCampaigns.length ? (
                <Box width="100%">
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      title={t('oldCampaigns')}
                      data={closedCampaigns}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </Box>
              ) : (
                <Paper className={classes.paper}>
                  <Typography component="h6" variant="h6" color="inherit">
                    {t('oldCampaigns')}
                  </Typography>
                  <Box height="20vh" mt={2}>
                    <DashedContainer>{t('campaignsStatus:noData')}</DashedContainer>
                  </Box>
                </Paper>
              )
            ) : (
              <Paper className={classes.paper}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
                  <CircularProgress />
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleActionsMenuClose}
        >
          <MenuItem
            onClick={() => {
              history.push({
                pathname: generatePath(match.path + '/:campaignId', {
                  ...match.params,
                  campaignId: '' + menuCampaignId,
                }),
              });
            }}
          >
            {t('open')}
          </MenuItem>
          {menuCampaignId && campaignsById[menuCampaignId]?.status === CampaignStatus.IN_PROGRESS ? (
            <MenuItem
              className={classes.deleteMenuItem}
              onClick={() => {
                handleCampaignRecall(menuCampaignId);
              }}
            >
              {t('poolCampaigns:recall')}
            </MenuItem>
          ) : null}
          {menuCampaignId && campaignsById[menuCampaignId]?.status === CampaignStatus.READY ? (
            <DeleteDialog
              onDelete={handleDeleteCampaign}
              deleteListItems={[
                <Typography key="0" variant="body1" color="textPrimary">
                  {campaignsById[menuCampaignId]?.name}
                </Typography>,
              ]}
              labels={{
                dialogTitle: t('poolCampaigns:deleteCampaign:dialogTitle'),
                dialogMessage: t('poolCampaigns:deleteCampaign:dialogMessage'),
              }}
              openComponent={(onClick) => (
                <MenuItem
                  className={classes.deleteMenuItem}
                  onClick={() => {
                    onClick();
                    handleActionsMenuClose();
                  }}
                >
                  {t('common:delete')}
                </MenuItem>
              )}
            />
          ) : null}
        </Menu>
      </Container>
    </DashboardAppLayout>
  );
}
