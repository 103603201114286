import React, { useContext, useState, useCallback } from 'react';
import ConfirmDialog from './ConfirmDialog';
import { ConfirmDialogParams, ConfirmDialogResult } from './ConfirmDialogTypes';

type CloseFn = (result: ConfirmDialogResult) => void;
type ContextDialogParams = null | ConfirmDialogParams;
type ContextCloseFn = null | CloseFn;
type ConfirmActionFn = () => Promise<unknown>;
type ContextOnOpen = (dialogParams: ConfirmDialogParams, confirmActionFn: ConfirmActionFn, closeFn: CloseFn) => void;

type ConfirmDialogContextType = {
  open: boolean;
  dialogParams: ContextDialogParams;
  onCloseFn: ContextCloseFn;
  onOpen: ContextOnOpen;
  loading: boolean;
};

const ConfirmDialogContext = React.createContext<ConfirmDialogContextType>({
  open: false,
  dialogParams: null,
  onCloseFn: null,
  onOpen: () => {
    /* */
  },
  loading: false,
});

/**
 * Hook that returns a function that shows a confirm dialog, optionally runs an asynchronous action on confirm,
 * and returns a promise.
 */
type UseConfirmDialogReturnType = {
  showConfirmDialog: (
    dialogParams: ConfirmDialogParams,
    confirmActionFn: ConfirmActionFn
  ) => Promise<ConfirmDialogResult>;
};

export default function useConfirmDialog(): UseConfirmDialogReturnType {
  const dialogContext = useContext(ConfirmDialogContext);

  /**
   * Show a confirm dialog, and resolves the returned promise once the use confirmed
   * or cancelled the action.
   *
   * @param dialogParams - The confirm dialog params, defining dialog appareance
   * @param confirmActionFn - Function to be run after the user chlicked on the confirm button.
   *    The dialog promise will be chained to the one retured by confirmActionFn.
   */
  const showConfirmDialog = useCallback(
    (dialogParams: ConfirmDialogParams, confirmActionFn: ConfirmActionFn) => {
      return new Promise<ConfirmDialogResult>((resolve) => {
        dialogContext.onOpen(dialogParams, confirmActionFn, (result) => {
          resolve(result);
        });
      });
    },
    [dialogContext]
  );

  return {
    showConfirmDialog,
  };
}

export const ConfirmDialogProvider = (props: { children: React.ReactNodeArray }): JSX.Element => {
  const [contextState, setContextState] = useState<Omit<ConfirmDialogContextType, 'onOpen'>>({
    open: false,
    dialogParams: null,
    onCloseFn: null,
    loading: false,
  });

  const onOpen = useCallback(
    (dialogParams: ConfirmDialogParams, confirmActionFn: ConfirmActionFn, closeFn: CloseFn) => {
      setContextState({
        loading: false,
        open: true,
        dialogParams,
        onCloseFn: async (result) => {
          if (confirmActionFn && result === 'Confirm') {
            setContextState({
              open: true,
              loading: true,
              dialogParams,
              onCloseFn: null,
            });

            await confirmActionFn();
          }

          setContextState({
            loading: false,
            open: false,
            dialogParams: null,
            onCloseFn: null,
          });
          closeFn(result);
        },
      });
    },
    [setContextState]
  );

  return (
    <ConfirmDialogContext.Provider value={{ ...contextState, onOpen }}>
      <ConfirmDialogContext.Consumer>
        {(value) => (
          <ConfirmDialog
            {...value.dialogParams}
            open={value.open || false}
            loading={value.loading}
            onClose={value.onCloseFn}
            message={value.dialogParams?.message || ''}
            title={value.dialogParams?.title || ''}
            cancelButtonText={value.dialogParams?.cancelButtonText}
            confirmButtonText={value.dialogParams?.confirmButtonText}
            cancelButtonProps={value.dialogParams?.cancelButtonProps}
            confirmButtonProps={value.dialogParams?.confirmButtonProps}
          />
        )}
      </ConfirmDialogContext.Consumer>
      {props.children}
    </ConfirmDialogContext.Provider>
  );
};
