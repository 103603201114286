import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneArea, DropzoneAreaProps } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useCompanies from '../../../../hooks/store/useCompanies';
import ProgressButton from '../../../common/ProgressButton';

const useLogoUploaderStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    outline: 0,
    width: theme.spacing(64),
    height: theme.spacing(32),
  },
  previewGrid: {
    justifyContent: 'center',
    width: '100%',
    margin: '0',
    position: 'absolute',
    bottom: 0,
    height: '100%',
    alignItems: 'center',
    backgroundColor: '#F0F0F0',
  },
  previewGridItem: {
    maxWidth: '60%',
    flexBasis: '60%',
  },
}));

type LogoUploaderProps = DropzoneAreaProps & {
  onBase64Change: (base64: string) => void;
};

const LogoUploader = (props: LogoUploaderProps) => {
  const { t } = useTranslation(['company']);
  const classes = useLogoUploaderStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <DropzoneArea
      acceptedFiles={['image/jpeg', 'image/png']}
      filesLimit={1}
      maxFileSize={1e6} //1MB
      dropzoneText={t('company:dragAndDropLogo')}
      showAlerts={false}
      dropzoneClass={classes.dropzone}
      previewGridClasses={{
        container: classes.previewGrid,
        item: classes.previewGridItem,
      }}
      onChange={(files: Array<File>) => {
        if (files.length > 0) {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.result) {
              props.onBase64Change(`${reader.result}`);
            }
          };
          const file = files[0];

          if (file) {
            reader.readAsDataURL(file);
          }
        }
      }}
      onAlert={(message) => {
        if (message.indexOf('File is too big') > 0) {
          enqueueSnackbar(t('company:logoFileTooBigError'), { variant: 'error' });
        }
      }}
    />
  );
};

type CompanyLogoDialogProps = {
  open: boolean;
  onClose: (value?: string) => void;
};

export default function CompanyLogoDialog(props: CompanyLogoDialogProps): JSX.Element {
  // const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open } = props;
  const [base64, setBase64] = useState<undefined | string>();
  const { updateCompanyRequest } = useCompanies();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t('companyData:updateCompanyLogo')}</DialogTitle>
      <DialogContent>
        <LogoUploader
          onBase64Change={(uploaded: string) => {
            setBase64(uploaded.substr(uploaded.indexOf(',') + 1, uploaded.length));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('common:cancel')}</Button>
        <ProgressButton
          color="primary"
          disabled={!base64}
          onClick={() => onClose(base64)}
          loading={updateCompanyRequest.inProgress}
        >
          {t('common:update')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
