import { Repository } from '@top-solution/utils';
import { CompanySize } from '../entities/CompanySize';

class CompanySizesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/companysizes',
    });
  }

  async getList(): Promise<Array<CompanySize>> {
    const { data } = await this.axios.get(`/`);
    return data;
  }
}

export default new CompanySizesRepository();
