import { RequestError } from '@top-solution/utils';
import { CompanySize } from '../../entities/CompanySize';
import { requestDefaultState } from '../utils';

export const READ_COMPANY_SIZES_REQUEST = 'READ_COMPANY_SIZES_REQUEST';
export const READ_COMPANY_SIZES_SUCCESS = 'READ_COMPANY_SIZES_SUCCESS';
export const READ_COMPANY_SIZES_FAILURE = 'READ_COMPANY_SIZES_FAILURE';

export const CREATE_COMPANY_SIZE_REQUEST = 'CREATE_COMPANY_SIZE_REQUEST';
export const CREATE_COMPANY_SIZE_SUCCESS = 'CREATE_COMPANY_SIZE_SUCCESS';
export const CREATE_COMPANY_SIZE_FAILURE = 'CREATE_COMPANY_SIZE_FAILURE';

export const DELETE_COMPANY_SIZE_REQUEST = 'DELETE_COMPANY_SIZE_REQUEST';
export const DELETE_COMPANY_SIZE_SUCCESS = 'DELETE_COMPANY_SIZE_SUCCESS';
export const DELETE_COMPANY_SIZE_FAILURE = 'DELETE_COMPANY_SIZE_FAILURE';

const initialState = {
  companySizes: {} as { [companyId: string]: CompanySize },
  requests: {
    readList: { ...requestDefaultState },
  },
};

export type CompanySizesState = typeof initialState;

export interface ReadCompaniesRequestAction {
  type: typeof READ_COMPANY_SIZES_REQUEST;
}
export interface ReadCompaniesSuccessAction {
  type: typeof READ_COMPANY_SIZES_SUCCESS;
  companySizes: Array<CompanySize>;
}
export interface ReadCompaniesFailureAction {
  type: typeof READ_COMPANY_SIZES_FAILURE;
  error: RequestError;
}

type ActionType = ReadCompaniesRequestAction | ReadCompaniesSuccessAction | ReadCompaniesFailureAction;

export default function companySizes(state = initialState, action: ActionType): CompanySizesState {
  switch (action.type) {
    case READ_COMPANY_SIZES_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_COMPANY_SIZES_SUCCESS: {
      return {
        ...state,
        companySizes: action.companySizes.reduce(
          (companySizes, companySize) => ({
            ...companySizes,
            [companySize.name]: companySize,
          }),
          {}
        ),
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case READ_COMPANY_SIZES_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
