import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { PopoverOrigin } from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CompanyPerson } from '../../../../entities/CompanyPerson';
import useCompanyPeople from '../../../../hooks/store/useCompanyPeople';
import useConfirmDialog from '../../../common/ConfirmDialog/useConfirmDialog';
import EditPersonDialog from './EditPersonDialog';

const anchorOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'center' };

type PersonMenuProps = {
  person: CompanyPerson;
  companyId: string;
  onPersonUpdate: (person: CompanyPerson) => void;
  departments: Array<string>;
};

export default function PersonMenu(props: PersonMenuProps): JSX.Element {
  const { t } = useTranslation();
  const { deleteCompanyPerson } = useCompanyPeople();
  const { showConfirmDialog } = useConfirmDialog();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemovePerson = () => {
    setAnchorEl(null);
    showConfirmDialog(
      {
        title: t('companiesManagement:deleteConfirmTitle', {
          name: `${props.person.first_name} ${props.person.last_name}`,
        }),
        message: t('companiesManagement:deleteConfirmDescription'),
        confirmButtonProps: {
          color: 'secondary',
        },
      },
      () => deleteCompanyPerson(props.companyId, props.person.id)
    );
  };

  return (
    <Tooltip title="Dettagli" placement="top" arrow>
      <div>
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          getContentAnchorEl={null}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <EditPersonDialog
            companyId={props.companyId}
            person={props.person}
            departments={props.departments}
            onDialogOpen={handleClose}
            onAddPerson={(personForm) =>
              props.onPersonUpdate({
                ...personForm,
                id: props.person.id,
              })
            }
          />
          <MenuItem onClick={handleRemovePerson}>{t('peopleManagement:removePerson')}</MenuItem>
        </Menu>
      </div>
    </Tooltip>
  );
}
