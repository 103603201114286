import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RequestState, Resource, useResource } from '@top-solution/utils';
import CompaniesRepository from '../../api/CompaniesRepository';
import { Company, CompanyCreatePayload } from '../../entities/Company';
import { RootState } from '../../store/reducers';
import {
  ReadCompanyRequestAction,
  READ_COMPANY_REQUEST,
  ReadCompanySuccessAction,
  READ_COMPANY_SUCCESS,
  ReadCompanyFailureAction,
  READ_COMPANY_FAILURE,
  ReadCompaniesRequestAction,
  READ_COMPANIES_REQUEST,
  ReadCompaniesSuccessAction,
  READ_COMPANIES_SUCCESS,
  ReadCompaniesFailureAction,
  READ_COMPANIES_FAILURE,
  CreateCompanyRequestAction,
  CREATE_COMPANY_REQUEST,
  CreateCompanyFailureAction,
  CREATE_COMPANY_SUCCESS,
  CreateCompanySuccessAction,
  CREATE_COMPANY_FAILURE,
  UpdateCompanyRequestAction,
  UPDATE_COMPANY_REQUEST,
  UpdateCompanyFailureAction,
  UPDATE_COMPANY_SUCCESS,
  UpdateCompanySuccessAction,
  UPDATE_COMPANY_FAILURE,
  DeleteCompanyRequestAction,
  DELETE_COMPANY_REQUEST,
  DeleteCompanySuccessAction,
  DELETE_COMPANY_SUCCESS,
  DeleteCompanyFailureAction,
  DELETE_COMPANY_FAILURE,
} from '../../store/reducers/companies';

type UseCompanies = {
  companies: Record<Company['id'], Company>;
  readCompany: (companyId: Company['id']) => void;
  readCompaniesList: () => void;
  createCompany: (company: CompanyCreatePayload) => Promise<Company | void>;
  updateCompany: (companyId: Company['id'], company: CompanyCreatePayload) => Promise<Company | undefined>;
  deleteCompany: (companyId: Company['id']) => Promise<Error | void>;
  readRequest: RequestState;
  readListRequest: RequestState;
  createCompanyRequest: RequestState;
  updateCompanyRequest: RequestState;
  deleteCompanyRequest: RequestState;
};

export default function useCompanies(): UseCompanies {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      CompaniesRepository.updateAuthToken(token);
    }
  }, [token]);

  const companies = useSelector((state: RootState) => state.companies.companies);

  const readRequest = useSelector((state: RootState) => state.companies.requests.read);
  const readListRequest = useSelector((state: RootState) => state.companies.requests.readList);
  const createCompanyRequest = useSelector((state: RootState) => state.companies.requests.create);
  const updateCompanyRequest = useSelector((state: RootState) => state.companies.requests.update);
  const deleteCompanyRequest = useSelector((state: RootState) => state.companies.requests.delete);

  const readCompany = useCallback(
    (companyId: string) => {
      dispatch<ReadCompanyRequestAction>({ type: READ_COMPANY_REQUEST });

      const read = async () => {
        try {
          const company = await CompaniesRepository.get(companyId);

          dispatch<ReadCompanySuccessAction>({
            type: READ_COMPANY_SUCCESS,
            company,
          });
        } catch (error) {
          dispatch<ReadCompanyFailureAction>({ type: READ_COMPANY_FAILURE, error });
        }
      };

      read();
    },
    [dispatch]
  );

  const readCompaniesList = useCallback(() => {
    dispatch<ReadCompaniesRequestAction>({ type: READ_COMPANIES_REQUEST });

    const read = async () => {
      try {
        const companies = await CompaniesRepository.getList();

        dispatch<ReadCompaniesSuccessAction>({
          type: READ_COMPANIES_SUCCESS,
          companies,
        });
      } catch (error) {
        dispatch<ReadCompaniesFailureAction>({ type: READ_COMPANIES_FAILURE, error });
      }
    };

    read();
  }, [dispatch]);

  const createCompany = useCallback(
    (company: CompanyCreatePayload) => {
      dispatch<CreateCompanyRequestAction>({ type: CREATE_COMPANY_REQUEST, company });

      const create = async () => {
        try {
          const companyRes = await CompaniesRepository.create(company);
          dispatch<CreateCompanySuccessAction>({ type: CREATE_COMPANY_SUCCESS });
          await readCompaniesList();
          return companyRes;
        } catch (error) {
          dispatch<CreateCompanyFailureAction>({ type: CREATE_COMPANY_FAILURE, error });
        }
      };

      return create();
    },
    [dispatch, readCompaniesList]
  );

  const updateCompany = useCallback(
    (companyId: string, company: CompanyCreatePayload) => {
      dispatch<UpdateCompanyRequestAction>({ type: UPDATE_COMPANY_REQUEST, company });

      const update = async () => {
        try {
          const companyRes = await CompaniesRepository.update(companyId, company);
          dispatch<UpdateCompanySuccessAction>({
            type: UPDATE_COMPANY_SUCCESS,
            companyId: companyId,
            company: companyRes,
          });
          await readCompaniesList();
          return companyRes;
        } catch (error) {
          dispatch<UpdateCompanyFailureAction>({ type: UPDATE_COMPANY_FAILURE, error });
        }
      };

      return update();
    },
    [dispatch, readCompaniesList]
  );

  const deleteCompany = useCallback(
    async (companyId: string): Promise<Error | void> => {
      dispatch<DeleteCompanyRequestAction>({ type: DELETE_COMPANY_REQUEST, companyId });

      try {
        await CompaniesRepository.delete(companyId);
      } catch (error) {
        dispatch<DeleteCompanyFailureAction>({ type: DELETE_COMPANY_FAILURE, error });
        return error;
      }

      dispatch<DeleteCompanySuccessAction>({
        type: DELETE_COMPANY_SUCCESS,
        companyId,
      });
    },
    [dispatch]
  );

  return {
    companies,
    readCompany,
    readCompaniesList,
    createCompany,
    updateCompany,
    deleteCompany,
    readRequest,
    readListRequest,
    createCompanyRequest,
    updateCompanyRequest,
    deleteCompanyRequest,
  };
}

export function useCompanyResource(companyId: string): Resource<Company | undefined> {
  const { companies, readCompany, readRequest } = useCompanies();

  const readCompanyWithId = useCallback(() => readCompany(companyId), [readCompany, companyId]);
  const company = useMemo(() => companies[companyId] || undefined, [companies, companyId]);

  const resourceName = useMemo(() => `company-${companyId}`, [companyId]);

  return useResource(resourceName, company, readCompanyWithId, readRequest);
}

export function useCompaniesResource(): Resource<Record<Company['id'], Company>> {
  const { companies, readCompaniesList, readListRequest } = useCompanies();

  return useResource(`companies`, companies, readCompaniesList, readListRequest);
}
