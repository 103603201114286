import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useCompanies, { useCompanyResource } from '../../../../hooks/store/useCompanies';
import LoaderView from '../../../common/LoaderView';
import CompanyLogoDialog from './CompanyLogoDialog';

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    padding: theme.spacing(),
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
    borderStyle: 'dashed',
    borderRadius: 4,
    height: theme.spacing(35),
  },
  logo: {
    cursor: 'pointer',
    width: theme.spacing(30),
    height: theme.spacing(30),
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
}));

function CompanyLogo() {
  const classes = useStyles();
  const { companyId } = useParams<{ companyId: string }>();

  const [company, companyRequest, companyErr] = useCompanyResource(companyId || '');
  const { updateCompany, updateCompanyRequest } = useCompanies();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (companyErr) {
      throw companyErr;
    }
  }, [companyErr]);

  const handleDialogClose = async (base64?: string) => {
    if (base64 && company) {
      await updateCompany(company.id, {
        ...company,
        logo: base64,
      });
    }

    setDialogOpen(false);
  };

  return (
    <LoaderView condition={!companyRequest.inProgress && Boolean(company)} minHeight="175px">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <div className={classes.logoContainer} onClick={() => setDialogOpen(true)}>
          {company && (
            <div
              className={classes.logo}
              style={{ backgroundImage: updateCompanyRequest.inProgress ? '' : `url(${company.logo})` }}
            />
          )}
          <Typography variant="caption">{'Clicca qui per cambiare il logo aziendale'}</Typography>
        </div>
      </Box>
      <CompanyLogoDialog open={dialogOpen} onClose={handleDialogClose} />
    </LoaderView>
  );
}

export default function CompanyLogoParamsWrapper(): JSX.Element | null {
  const { companyId } = useParams<{ companyId: string }>();

  if (!companyId) {
    return null;
  }

  return <CompanyLogo />;
}
