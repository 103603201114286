import { combineReducers } from 'redux';

import assessments from './assessments';
import auth from './auth';
import branches from './branches';
import campaigns from './campaigns';
import companies from './companies';
import companyMarkets from './companyMarkets';
import companyPeople from './companyPeople';
import companySizes from './companySizes';
import positions from './positions';

export const rootReducer = combineReducers({
  auth,
  branches,
  campaigns,
  companies,
  companyMarkets,
  companyPeople,
  companySizes,
  positions,
  assessments,
});

export type RootState = ReturnType<typeof rootReducer>;
