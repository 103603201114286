import { RequestError } from '@top-solution/utils';
import { Position } from '../../entities/Position';
import { requestDefaultState } from '../utils';

export const READ_POSITIONS_REQUEST = 'READ_POSITIONS_REQUEST';
export const READ_POSITIONS_SUCCESS = 'READ_POSITIONS_SUCCESS';
export const READ_POSITIONS_FAILURE = 'READ_POSITIONS_FAILURE';
const initialState = {
  positions: {} as { [positionName: string]: Position },
  requests: {
    readList: { ...requestDefaultState },
  },
};

export type PositionsState = typeof initialState;

export interface ReadPositionsRequestAction {
  type: typeof READ_POSITIONS_REQUEST;
}
export interface ReadPositionsSuccessAction {
  type: typeof READ_POSITIONS_SUCCESS;
  positions: Array<Position>;
}
export interface ReadPositionsFailureAction {
  type: typeof READ_POSITIONS_FAILURE;
  error: RequestError;
}

type ActionType = ReadPositionsRequestAction | ReadPositionsSuccessAction | ReadPositionsFailureAction;

export default function companies(state = initialState, action: ActionType): PositionsState {
  switch (action.type) {
    case READ_POSITIONS_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_POSITIONS_SUCCESS: {
      return {
        ...state,
        positions: action.positions.reduce(
          (positions, position) => ({
            ...positions,
            [position.name]: position,
          }),
          {}
        ),
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case READ_POSITIONS_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
