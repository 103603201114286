import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableMeta, MUIDataTableProps } from 'mui-datatables';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles, MuiThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Campaign } from '../../../../entities/Campaign';
import { useCampaignResource } from '../../../../hooks/store/useCampaigns';
import useMUIDatatableTranslations from '../../../../hooks/useMUIDatatableTranslations';
import { CampaignStatus } from '../../../../lib/constants/campaign-status';
import DashedContainer from '../../../common/DashedContainer';
import CampaignDetailRow from './CampaignDetailRow';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function CampaignsStatus(): JSX.Element {
  const classes = useStyles();
  const { companyId } = useParams<{ companyId: string }>();
  const tableTranslation = useMUIDatatableTranslations();
  const [campaignsById, campaignsReady] = useCampaignResource(companyId || '');
  const campaigns = Object.values(campaignsById).filter((campaign) => campaign.status !== CampaignStatus.DRAFT);
  const { t } = useTranslation(['overview', 'campaignsStatus', 'campaignStatus']);
  const match = useRouteMatch();
  const history = useHistory();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: t('campaignsStatus:campaignName'),
      options: {
        sort: true,
        filter: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: Campaign['name'], tableMeta: MUIDataTableMeta) => (
          <Link
            color="primary"
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              history.push({
                pathname: generatePath(match.path.replace('/overview', '') + '/polls/:pollId/:campaignId', {
                  ...match.params,
                  pollId: '' + campaignsById[tableMeta.rowData[5]]?.poll.id,
                  campaignId: '' + tableMeta.rowData[5],
                }),
              });
            }}
          >
            {value}
          </Link>
        ),
      },
    },
    {
      name: 'status',
      label: t('campaignsStatus:statusCampaign'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: Campaign['status']) => t(value),
      },
    },
    {
      name: 'start_at',
      label: t('campaignsStatus:startAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Campaign['start_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'end_at',
      label: t('campaignsStatus:endAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Campaign['end_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'poll',
      label: t('campaignsStatus:pollType'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: Campaign['poll']) => t(value.name),
      },
    },
    {
      name: 'id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
  ];

  const renderExpandableRow = useMemo(
    () =>
      function ExpandableRow(rowData: string[]) {
        const colSpan = rowData.length + 1;
        const lastRow = rowData[rowData.length - 1];
        if (lastRow && campaignsById[lastRow]) {
          const assStatus = campaignsById[lastRow]?.assessment_status;
          if (assStatus) {
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <CampaignDetailRow assessment={assStatus} />
                </TableCell>
              </TableRow>
            );
          }
        }
        return null;
      },
    [campaignsById]
  );

  const options: MUIDataTableProps['options'] = {
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    filterType: 'dropdown',
    selectableRows: 'none',
    download: false,
    elevation: 1,
    print: false,
    viewColumns: false,
    textLabels: tableTranslation,
    renderExpandableRow: renderExpandableRow,
  };

  const title = useMemo(
    () => (
      <Typography component="h1" variant="subtitle1" color="inherit" className={classes.wrapIcon}>
        <AssignmentIcon className={classes.icon} /> {t('campaignsStatus:tableTitle')}
      </Typography>
    ),
    [classes.icon, classes.wrapIcon, t]
  );

  // Force muidatatable toolbar to have custom gutters
  const defaultTheme = useTheme();

  const theme = createMuiTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          gutters: {
            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          },
        },
      },
    },
  });

  if (campaignsReady) {
    return campaigns.length ? (
      <Box width="100%">
        <MuiThemeProvider theme={theme}>
          <MUIDataTable title={title} data={campaigns} columns={columns} options={options} />
        </MuiThemeProvider>
      </Box>
    ) : (
      <Paper className={classes.paper}>
        {title}
        <Box height="20vh" mt={2}>
          <DashedContainer>{t('campaignsStatus:noData')}</DashedContainer>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {title}
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh" mt={2}>
        <CircularProgress />
      </Box>
    </Paper>
  );
}
