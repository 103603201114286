import React, { ErrorInfo } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RequestError } from '@top-solution/utils';
import errorImage from '../../assets/error.png';
// import DataError from '../../entities/DataError';

// Theme-dependent styles
const style = (theme: Theme) =>
  createStyles({
    logo: {
      backgroundImage: `url(${errorImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    disclaimer: {
      padding: theme.spacing(8),
      '& h1': {
        marginBottom: theme.spacing(4),
      },
      '& a': {
        marginTop: theme.spacing(4),
      },
    },
    error: {
      backgroundColor: '#eca4a4',
      margin: 0,
      padding: `24px 10vw`,
      whiteSpace: 'pre-wrap',
    },
  });

interface IProps {
  classes: {
    // Had to declare it manually: https://github.com/mui-org/material-ui/issues/17491
    logo: string;
    disclaimer: string;
    error: string;
  };
}

interface IState {
  hasError: boolean;
  lastError: null | Error;
}

class GlobalErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      hasError: false,
      lastError: null,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: RequestError, errorInfo: ErrorInfo) {
    if (process.env.REACT_APP_ENV) {
      datadogLogs.logger.error('Uncaught error', {
        name: error.name,
        message: error.message,
        code: error.code || '',
        method: error.method || '',
        url: error.url || '',
        detail: error.message || '',
        stack: errorInfo.componentStack,
      });
    } else {
      // eslint-disable-next-line
      console.error(error);

      this.setState({ lastError: error });
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {this.state.lastError ? (
            <Box width="100%" height="40vh" overflow="auto">
              <pre className={classes.error}>
                <code>
                  {this.state.lastError.message}
                  {this.state.lastError.stack}
                </code>
              </pre>
            </Box>
          ) : (
            <Box width="100%" height="40vh" className={classes.logo}></Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="60vh"
            className={classes.disclaimer}
          >
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              Looks like something went wrong!
            </Typography>
            <Typography component="p" variant="h6" color="inherit" align="center">
              We track these error automatically, but if the problem persist fell free to contact us.
            </Typography>
            <Typography component="p" variant="h6" color="inherit">
              In the meantime, try refreshing.
            </Typography>
            <Link underline="none" href="mailto:haka@topsolution.it" variant="h6">
              Contact support
            </Link>
          </Box>
        </Box>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withStyles(style)(GlobalErrorBoundary);
