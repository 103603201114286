import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useAuth from '../../hooks/store/useAuth';
import useCompanies from '../../hooks/store/useCompanies';
import { UserRoles } from '../../lib/constants/user-roles';
import CompanyCard from '../common/CompanyCard';
import LoaderView from '../common/LoaderView';
import AppLayout from '../layout/Layout';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  addCompany: {
    minHeight: 88,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Companies(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { role } = useAuth();
  const { companies, readCompaniesList, readListRequest } = useCompanies();

  useEffect(() => {
    readCompaniesList();
  }, [readCompaniesList]);

  const companyCards = Object.values(companies).map((company) => {
    return (
      <Grid key={company.id} item xs={12} md={6} lg={4}>
        <CompanyCard company={company} />
      </Grid>
    );
  });

  return (
    <AppLayout showDrawer={false}>
      <Container maxWidth="lg" className={classes.container}>
        <LoaderView condition={!readListRequest.inProgress && Boolean(companies)}>
          <Grid container spacing={3}>
            {companyCards}
            {role === UserRoles.ADMIN ? (
              <Grid key="add-company" item xs={12} md={6} lg={4}>
                <Card variant="outlined" onClick={() => history.push(`/company/add`)}>
                  <CardActionArea>
                    <CardContent className={classes.addCompany}>
                      <Typography color="primary" variant="h6">
                        {'+ Aggiungi azienda'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </LoaderView>
      </Container>
    </AppLayout>
  );
}
