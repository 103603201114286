import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Auth0 from '../../auth/Auth0';

type HandleAuthRedirectOpts = {
  children: React.ReactElement;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

const HandleAuthRedirect = ({ children }: HandleAuthRedirectOpts): JSX.Element => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth0.init().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fade>
    );
  }

  return <>{children}</>;
};

export default HandleAuthRedirect;
