import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Assessment } from '../../entities/Assessment';
import {
  mutateErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  requestState,
} from '../toolkitUtils';

const initialState = {
  byId: {} as Record<Assessment['id'], Assessment>,
  requests: {
    readList: requestState(),
    create: requestState(),
    delete: requestState(),
    recall: requestState(),
  },
};

export type AssessmentsState = typeof initialState;

interface ReadAssessmentListSuccessAction {
  response: Array<Assessment>;
}

interface CreateAssessmentSuccessAction {
  response: Assessment;
}

interface DeleteAssessmentSuccessAction {
  response: Assessment['id'];
}

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    readAssessmentListRequest(state) {
      mutateRequestState(state.requests.readList);
    },
    readAssessmentListSuccess(state, action: PayloadAction<ReadAssessmentListSuccessAction>) {
      const { response } = action.payload;

      state.byId = {};

      for (let i = 0; i < response.length; i++) {
        const assessment = response[i];
        if (assessment) {
          state.byId[assessment.id] = assessment;
        }
      }
      mutateSuccessState(state.requests.readList);
    },
    readAssessmentListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readList, action.payload.error);
    },
    createAssessmentRequest(state) {
      mutateRequestState(state.requests.create);
    },
    createAssessmentSuccess(state, action: PayloadAction<CreateAssessmentSuccessAction>) {
      const { response } = action.payload;

      state.byId[response.id] = response;
      mutateSuccessState(state.requests.create);
    },
    createAssessmentFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.create, action.payload.error);
    },
    deleteAssessmentRequest(state) {
      mutateRequestState(state.requests.delete);
    },
    deleteAssessmentSuccess(state, action: PayloadAction<DeleteAssessmentSuccessAction>) {
      const { response } = action.payload;

      delete state.byId[response];
      mutateSuccessState(state.requests.delete);
    },
    deleteAssessmentFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.delete, action.payload.error);
    },
    recallAssessmentRequest(state) {
      mutateRequestState(state.requests.recall);
    },
    recallAssessmentSuccess(state) {
      mutateSuccessState(state.requests.recall);
    },
    recallAssessmentFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.recall, action.payload.error);
    },
  },
});

export const assessmentActions = assessmentSlice.actions;

export default assessmentSlice.reducer;
