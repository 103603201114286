import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import * as yup from 'yup';

import { Company } from '../entities/Company';
import { CompanyMarket } from '../entities/CompanyMarket';
import { CompanySize } from '../entities/CompanySize';
import { useCompanyMarketsResource } from './store/useCompanyMarkets';
import { useCompanySizesResource } from './store/useCompanySizes';

export type CompanyFormForm = {
  name: string;
  company_size: string;
  company_market: string;
};

type CompanyFormFormErrors = { [key in keyof CompanyFormForm]: string };

const formInitialState: CompanyFormForm = {
  name: '',
  company_size: '',
  company_market: '',
};

const formInitialErrors = (): CompanyFormFormErrors => ({
  name: '',
  company_size: '',
  company_market: '',
});

type onSubmit = (event: React.FormEvent<HTMLFormElement>, formValues: CompanyFormForm) => void;

type CompanyFormReturnType = {
  loadingCondition: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  formErrors: CompanyFormFormErrors;
  formValues: CompanyFormForm;
  setFormValues: (form: CompanyFormForm) => void;
  companySizes: Record<Company['id'], CompanySize>;
  companyMarkets: Record<Company['id'], CompanyMarket>;
};

export default function useCompanyForm(onSubmit: onSubmit, initialState = formInitialState): CompanyFormReturnType {
  const { t } = useTranslation(['company', 'errors']);

  const [companySizes, companySizesRequest, companySizesErr] = useCompanySizesResource();
  const [companyMarkets, companyMarketsRequest, companyMarketsErr] = useCompanyMarketsResource();

  const [formSchema, setFormSchema] = useState<null | yup.AnyObjectSchema>(null);
  const [formValues, setFormValues] = useState<CompanyFormForm>(initialState);
  const [formErrors, setFormErrors] = useState<CompanyFormFormErrors>(formInitialErrors());
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validate = useCallback(
    async (values) => {
      if (!formSchema) {
        return false;
      }

      try {
        await formSchema.validate(values, { abortEarly: false });
        setFormErrors(formInitialErrors());
        return true;
      } catch (err) {
        if (err.name === 'ValidationError') {
          const errors = formInitialErrors();

          (err as yup.ValidationError).inner.forEach((error) => {
            if (error.path) {
              if (Object.keys(errors).indexOf(error.path) >= 0) {
                errors[error.path as keyof CompanyFormFormErrors] = error.message;
              }
            }
          });

          setFormErrors(errors);
          return Object.values(errors).filter((e) => e).length === 0;
        }

        return false;
      }
    },
    [formSchema, setFormErrors]
  );

  const debouncedValidate = useMemo(() => debounce(validate, 100), [validate]);

  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: t('errors:required'),
      },
      string: {
        min: ({ min }: { min: number }) => t('errors:invalidMin', { chars: min }),
        max: ({ max }: { max: number }) => t('errors:invalidMax', { chars: max }),
      },
    });

    const schema = yup.object({
      name: yup.string().min(3).max(64).required(),
      company_size: yup.string().required(),
      company_market: yup.string().required(),
    });

    setFormSchema(schema);
  }, [t]);

  useEffect(() => {
    if (formSubmitted) {
      debouncedValidate(formValues);
    }
  }, [debouncedValidate, formValues, formSubmitted]);

  useEffect(() => {
    if (companySizesErr || companyMarketsErr) {
      throw companySizesErr || companyMarketsErr;
    }
  }, [companySizesErr, companyMarketsErr]);

  useEffect(() => {
    setFormValues(initialState);
  }, [initialState]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormSubmitted(true);

    const isValid = await validate(formValues);
    if (isValid) {
      onSubmit(event, formValues);
    }
  };

  return {
    loadingCondition: !companySizesRequest.inProgress && !companyMarketsRequest.inProgress,
    handleSubmit,
    formErrors,
    formValues,
    setFormValues,
    companySizes,
    companyMarkets,
  };
}
