import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

type LoaderViewProps = {
  condition: boolean;
  minHeight?: string | number;
  children: React.ReactNode | React.ReactNodeArray;
};

const LoaderViewCircularProgress = React.memo(function LoaderViewCircularProgress(props: {
  minHeight?: string | number;
}) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={props.minHeight || '50vh'}>
      <CircularProgress disableShrink />
    </Box>
  );
});

export default function LoaderView(props: LoaderViewProps): JSX.Element {
  const [condition, setCondition] = useState(props.condition);

  useEffect(() => {
    if (condition !== props.condition) {
      setCondition(props.condition);
    }
  }, [condition, props.condition]);

  if (!condition) {
    return <LoaderViewCircularProgress minHeight={props.minHeight} />;
  }

  return <>{props.children}</>;
}
