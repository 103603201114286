import { RequestError } from '@top-solution/utils';
import { CompanyPerson } from '../../entities/CompanyPerson';
import { requestDefaultState } from '../utils';

export const READ_COMPANY_PEOPLE_REQUEST = 'READ_COMPANY_PEOPLE_REQUEST';
export const READ_COMPANY_PEOPLE_SUCCESS = 'READ_COMPANY_PEOPLE_SUCCESS';
export const READ_COMPANY_PEOPLE_FAILURE = 'READ_COMPANY_PEOPLE_FAILURE';

export const CREATE_COMPANY_PERSON_REQUEST = 'CREATE_COMPANY_PERSON_REQUEST';
export const CREATE_COMPANY_PERSON_SUCCESS = 'CREATE_COMPANY_PERSON_SUCCESS';
export const CREATE_COMPANY_PERSON_FAILURE = 'CREATE_COMPANY_PERSON_FAILURE';

export const UPDATE_COMPANY_PERSON_REQUEST = 'UPDATE_COMPANY_PERSON_REQUEST';
export const UPDATE_COMPANY_PERSON_SUCCESS = 'UPDATE_COMPANY_PERSON_SUCCESS';
export const UPDATE_COMPANY_PERSON_FAILURE = 'UPDATE_COMPANY_PERSON_FAILURE';

export const DELETE_COMPANY_PERSON_REQUEST = 'DELETE_COMPANY_PERSON_REQUEST';
export const DELETE_COMPANY_PERSON_SUCCESS = 'DELETE_COMPANY_PERSON_SUCCESS';
export const DELETE_COMPANY_PERSON_FAILURE = 'DELETE_COMPANY_PERSON_FAILURE';

const initialState = {
  byCompany: {} as { [companyId: string]: Array<CompanyPerson> },
  requests: {
    readList: { ...requestDefaultState },
    create: { ...requestDefaultState },
    update: { ...requestDefaultState },
    delete: { ...requestDefaultState },
  },
};

export type CompaniesState = typeof initialState;

export interface ReadCompanyPeopleRequestAction {
  type: typeof READ_COMPANY_PEOPLE_REQUEST;
  companyId: string;
}
export interface ReadCompanyPeopleSuccessAction {
  type: typeof READ_COMPANY_PEOPLE_SUCCESS;
  companyPeople: Array<CompanyPerson>;
  companyId: string;
}
export interface ReadCompanyPeopleFailureAction {
  type: typeof READ_COMPANY_PEOPLE_FAILURE;
  error: RequestError;
  companyId: string;
}

export interface CreateCompanyPersonRequestAction {
  type: typeof CREATE_COMPANY_PERSON_REQUEST;
  companyPerson: Omit<CompanyPerson, 'id'>;
  companyId: string;
}
export interface CreateCompanyPersonSuccessAction {
  type: typeof CREATE_COMPANY_PERSON_SUCCESS;
}
export interface CreateCompanyPersonFailureAction {
  type: typeof CREATE_COMPANY_PERSON_FAILURE;
  error: RequestError;
}

export interface UpdateCompanyPersonRequestAction {
  type: typeof UPDATE_COMPANY_PERSON_REQUEST;
  companyPerson: CompanyPerson;
  companyId: string;
}
export interface UpdateCompanyPersonSuccessAction {
  type: typeof UPDATE_COMPANY_PERSON_SUCCESS;
}
export interface UpdateCompanyPersonFailureAction {
  type: typeof UPDATE_COMPANY_PERSON_FAILURE;
  error: RequestError;
}

export interface DeleteCompanyPersonRequestAction {
  type: typeof DELETE_COMPANY_PERSON_REQUEST;
  companyId: string;
}
export interface DeleteCompanyPersonSuccessAction {
  type: typeof DELETE_COMPANY_PERSON_SUCCESS;
  companyId: string;
}
export interface DeleteCompanyPersonFailureAction {
  type: typeof DELETE_COMPANY_PERSON_FAILURE;
  error: RequestError;
}

type ActionType =
  | ReadCompanyPeopleRequestAction
  | ReadCompanyPeopleSuccessAction
  | ReadCompanyPeopleFailureAction
  | CreateCompanyPersonRequestAction
  | CreateCompanyPersonSuccessAction
  | CreateCompanyPersonFailureAction
  | UpdateCompanyPersonRequestAction
  | UpdateCompanyPersonSuccessAction
  | UpdateCompanyPersonFailureAction
  | DeleteCompanyPersonRequestAction
  | DeleteCompanyPersonSuccessAction
  | DeleteCompanyPersonFailureAction;

export default function companies(state = initialState, action: ActionType): CompaniesState {
  switch (action.type) {
    case READ_COMPANY_PEOPLE_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_COMPANY_PEOPLE_SUCCESS: {
      return {
        ...state,
        byCompany: {
          ...state.byCompany,
          [action.companyId]: action.companyPeople,
        },
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case READ_COMPANY_PEOPLE_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case CREATE_COMPANY_PERSON_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: true,
          },
        },
      };
    case CREATE_COMPANY_PERSON_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case CREATE_COMPANY_PERSON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case UPDATE_COMPANY_PERSON_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: true,
          },
        },
      };
    case UPDATE_COMPANY_PERSON_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case UPDATE_COMPANY_PERSON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case DELETE_COMPANY_PERSON_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: true,
          },
        },
      };
    case DELETE_COMPANY_PERSON_SUCCESS: {
      // const newCompanies = { ...state.companies };
      // delete newCompanies[action.companyId];

      return {
        ...state,
        // companyPeople: newCompanies,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case DELETE_COMPANY_PERSON_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
