import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import useCompanies, { useCompanyResource } from '../../../../hooks/store/useCompanies';
import useCompanyForm from '../../../../hooks/useCompanyForm';
import LoaderView from '../../../common/LoaderView';
import ProgressButton from '../../../common/ProgressButton';
import { SettingsList, SettingsListItem } from '../../../common/SettingsList';

function CompanyInfo() {
  const { companyId } = useParams<{ companyId: string }>();
  const { t } = useTranslation(['common', 'company']);
  const [editMode, setEditMode] = useState(false);

  const [company, companyRequest, companyErr] = useCompanyResource(companyId || '');
  const { updateCompany, updateCompanyRequest } = useCompanies();

  useEffect(() => {
    if (companyErr || updateCompanyRequest.error) {
      throw companyErr || updateCompanyRequest.error;
    }
  }, [companyErr, updateCompanyRequest.error]);

  const {
    loadingCondition,
    handleSubmit,
    formErrors,
    formValues,
    setFormValues,
    companySizes,
    companyMarkets,
  } = useCompanyForm((e, form) => {
    if (company) {
      updateCompany(company.id, form).then(() => {
        setEditMode(false);
      });
    }
  }, company);

  const buttons = editMode ? (
    [
      <ProgressButton key="submit" type="submit" color="primary" size="small" loading={updateCompanyRequest.inProgress}>
        {t('common:update')}
      </ProgressButton>,
      <Button key="reset" type="reset" size="small" disabled={updateCompanyRequest.inProgress}>
        {t('common:cancel')}
      </Button>,
    ]
  ) : (
    <Button size="small" onClick={() => setEditMode(!editMode)}>
      {t('companyData:updateInfo')}
    </Button>
  );

  if (!company) {
    return null;
  }

  return (
    <LoaderView condition={loadingCondition && !companyRequest.inProgress} minHeight="175px">
      <form onSubmit={handleSubmit} onReset={() => setEditMode(false)} autoComplete="off">
        <SettingsList>
          <SettingsListItem
            label={t('company:name')}
            value={company.name}
            editMode={editMode}
            editComponent={
              <TextField
                name="companyName"
                defaultValue={company?.name || ''}
                variant={'outlined'}
                size="small"
                fullWidth
                error={Boolean(formErrors.name)}
                helperText={formErrors.name}
                autoComplete="none"
                label={`${t('company:name')} *`}
                onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
              />
            }
          />
          <SettingsListItem
            label={t('company:size')}
            value={t(company?.company_size)}
            editMode={editMode}
            editComponent={
              <TextField
                name="companySize"
                defaultValue={company.company_size}
                variant={'outlined'}
                fullWidth
                size="small"
                error={Boolean(formErrors.company_size)}
                helperText={formErrors.company_size}
                autoComplete="none"
                select
                label={`${t('company:size')} *`}
                onChange={(e) => setFormValues({ ...formValues, company_size: e.target.value })}
              >
                {Object.values(companySizes).map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {t(name)}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
          <SettingsListItem
            label={t('company:market')}
            value={t(company?.company_market)}
            editMode={editMode}
            editComponent={
              <TextField
                name="companyMarket"
                defaultValue={company.company_market}
                variant={'outlined'}
                fullWidth
                size="small"
                error={Boolean(formErrors.company_market)}
                helperText={formErrors.company_market}
                autoComplete="none"
                select
                label={`${t('company:market')} *`}
                onChange={(e) => setFormValues({ ...formValues, company_market: e.target.value })}
              >
                {Object.values(companyMarkets).map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {t(name)}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
          <SettingsListItem label={t('common:credits')} value={company?.credits} />
        </SettingsList>
        <Box display="flex" flexDirection="row-reverse">
          {buttons}
        </Box>
      </form>
    </LoaderView>
  );
}

export default function CompanyInfoParamsWrapper(): JSX.Element | null {
  const { companyId } = useParams<{ companyId: string }>();

  if (!companyId) {
    return null;
  }

  return <CompanyInfo />;
}
