import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type ProgressButtonProps = ButtonProps & {
  children: string;
  loading: boolean;
};

export default function ProgressButton(props: ProgressButtonProps): JSX.Element {
  const classes = useStyles();
  const { children, loading, ...buttonProps } = props;

  return (
    <div className={classes.root}>
      <Button {...buttonProps} disabled={props.disabled || props.loading}>
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}
