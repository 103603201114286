import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';

import { ProgressButton } from '@top-solution/mui-inputs';
import theme from '../../../theme';

const deleteDialogTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: red,
  },
});

type DeleteDialogProps = {
  onDelete: () => Promise<void>;
  deleteListItems: Array<React.ReactNode>;
  labels: {
    openButtonText?: string | undefined;
    dialogTitle: string;
    dialogMessage: string;
  };
  openComponent?: ((onClick: () => void) => React.ReactNode) | void;
};

const useStyles = makeStyles((theme) => ({
  dialogText: {
    padding: theme.spacing(0, 2),
  },
  dialogContent: {
    padding: theme.spacing(0, 1),
  },
  deleteList: {
    maxHeight: 200,
    overflowY: 'auto',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(2),
  },
  openComponentContainer: {
    color: red[500],
  },
}));

/**
 * Generic delete dialog, meant to be wrapped and specialized.
 */
function BaseDeleteDialog(props: DeleteDialogProps, ref: React.Ref<HTMLButtonElement>): JSX.Element {
  const { deleteListItems, labels, onDelete } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteSelected = useCallback(async () => {
    setProgress(true);
    await onDelete();
    setProgress(false);
    setOpen(false);
  }, [onDelete]);

  return (
    <div>
      <ThemeProvider theme={deleteDialogTheme}>
        {props.openComponent ? (
          <div className={classes.openComponentContainer}>{props.openComponent(handleClickOpen)}</div>
        ) : (
          <Button variant="outlined" color="primary" onClick={handleClickOpen} ref={ref}>
            {labels.openButtonText}
          </Button>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">{labels.dialogTitle}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText className={classes.dialogText}>{labels.dialogMessage}</DialogContentText>
            <DialogContentText component="div">
              {deleteListItems?.length > 1 ? (
                <ul className={classes.deleteList}>{deleteListItems}</ul>
              ) : (
                <div className={classes.dialogText}>{deleteListItems}</div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              {t('common:cancel')}
            </Button>
            <ProgressButton onClick={deleteSelected} color="primary" inProgress={progress} autoFocus>
              {t('common:delete')}
            </ProgressButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

const DeleteDialog = React.forwardRef(BaseDeleteDialog);

export default DeleteDialog;
