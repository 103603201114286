import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Route, Redirect, Switch, useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCampaigns from '../../hooks/store/useCampaigns';
import { useCompanyResource } from '../../hooks/store/useCompanies';

import LoaderView from '../common/LoaderView';
import CompanyData from './DashboardViews/CompanyData/CompanyData';
import Overview from './DashboardViews/overview/Overview';
import People from './DashboardViews/people/People';
import PoolCampaigns from './DashboardViews/pool/PoolCampaigns';
import PoolDetails from './DashboardViews/pool/PoolDetails';
import Reports from './DashboardViews/pool/Reports';

const CompanyDashboard = () => {
  const match = useRouteMatch();
  const { companyId } = useParams<{ companyId: string }>();
  const [_, companyRequest, companyError] = useCompanyResource(companyId);
  const { readCampaignList, readListRequest: campaignsReadListRequest } = useCampaigns();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation(['errors']);

  useEffect(() => {
    if (!companyError && !companyRequest.inProgress) {
      readCampaignList(companyId);
    }
  }, [companyError, companyId, readCampaignList, companyRequest]);

  useEffect(() => {
    if (companyError) {
      // If the selected company from URL isn't in the company list, navigate to root
      if (companyError.status === 400 || companyError.status === 404) {
        history.push('/');
      } else {
        throw companyError;
      }
    }
  }, [companyError, history]);

  useEffect(() => {
    if (campaignsReadListRequest.error) {
      if (campaignsReadListRequest.error.status === 404) {
        enqueueSnackbar(t('errors:httpErrorGeneric'), { variant: 'error' });
      } else {
        throw campaignsReadListRequest.error;
      }
    }
  }, [campaignsReadListRequest.error, enqueueSnackbar, t]);

  return (
    <LoaderView condition={!companyRequest.inProgress} minHeight="100vh">
      <Switch>
        <Route path={`${match.path}/overview`} component={Overview} />
        <Route path={`${match.path}/company-data`} component={CompanyData} />
        <Route path={`${match.path}/people`} component={People} />
        <Route path={`${match.path}/polls/:pollId/:campaignId/reports`} component={Reports} />
        <Route path={`${match.path}/polls/:pollId/:campaignId`} component={PoolDetails} />
        <Route path={`${match.path}/polls/:pollId`} component={PoolCampaigns} />
        <Route render={() => <Redirect to={`${match.url}/overview`} />} />
      </Switch>
    </LoaderView>
  );
};

const CompanyDashboardWrapper = (): JSX.Element | null => {
  const { companyId } = useParams<{ companyId: string }>();

  if (!companyId) {
    return null;
  }

  return <CompanyDashboard />;
};

export default CompanyDashboardWrapper;
