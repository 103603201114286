import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

const SILENT_AUTHENTICATION_TIMEOUT = 10;

/**
 * @param {string} opts.domain - The Auth0 domain
 * @param {string} opts.client_id - The Client ID for this application: it looks like a random string (ie: 'kIGEKa7gQXid1V3kyzeDBTDRAZKC1hg3v')
 * @param {string} opts.redirect_uri - Where to redirect to after authenticating
 * @param {string} opts.logout_uri - Where to redirect to after logout. Defaults to opts.redirect_uri
 * @param {string} opts.audience  - The JWT audience: 'https://api.arduino.cc' is a good bet (it's also the default)
 * @param {string} opts.scope  - The scope: you should include at least 'openid profile'
 * @param {string} opts.state  - The state returned from redirect
 */
type Auth0Opts = {
  domain: string;
  client_id: string;
  redirect_uri: string;
  logout_uri?: string;
  audience: string;
  scope: string;
  state?: string;
};

export type Auth0AppState = {
  targetUrl: string;
};

/**
 * @param {object} opts.appState - Some state to keep and return when auth is re-initialized after login
 * @param {string} opts.redirect_uri - A redirect uri overriding the default one: this uri is passed to Auth0, so it should be whitelisted
 */
type LoginOpts = {
  redirect_uri?: string;
  appState?: Auth0AppState;
};

/**
 * Auth0 authenticates users using auth0-spa-js
 */

class Auth0 {
  auth0: Auth0Client | null = null;
  auth0Promise: Promise<Auth0Client> | null = null;
  _opts: Auth0Opts;
  onRedirectCallback?: (appState: Auth0AppState) => void;

  constructor(opts: Auth0Opts) {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.init = this.init.bind(this);
    if (!opts.state) {
      opts.state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    this._opts = opts;
  }

  async init(onRedirectCallback?: (appState: Auth0AppState) => void): Promise<Auth0Client> {
    if (this.auth0) {
      return this.auth0;
    }

    if (this.auth0Promise) {
      return this.auth0Promise;
    }

    this.onRedirectCallback = onRedirectCallback;
    this.auth0Promise = this.createAuth0Client(this._opts);
    this.auth0 = await this.auth0Promise;

    return this.auth0;
  }

  async createAuth0Client(opts: Auth0Opts) {
    const auth0 = await createAuth0Client({ authorizeTimeoutInSeconds: SILENT_AUTHENTICATION_TIMEOUT, ...opts });
    try {
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0.handleRedirectCallback();
        this.onRedirectCallback && this.onRedirectCallback(appState);
      } else {
        await this._getTokenSilently(auth0);
      }
    } catch (_) {
      // noop
    }

    return auth0;
  }

  async _getTokenSilently(auth0 = this.auth0) {
    try {
      return await auth0?.getTokenSilently();
    } catch (err) {
      if (err.error && err.error === 'timeout') {
        // eslint-disable-next-line
        console.error(
          `Silent authentication timed out after ${SILENT_AUTHENTICATION_TIMEOUT}s: was the Web Origin set for ${window.location.hostname}?`
        );
      }
      throw err;
    }
  }

  isInitialized() {
    return this.auth0 !== undefined;
  }

  async login(opts?: LoginOpts) {
    (await this.init()).loginWithRedirect({
      // eslint-disable-next-line camelcase
      redirect_uri: opts ? opts.redirect_uri : this._opts.redirect_uri,
      appState: opts ? opts.appState : null,
    });
  }

  async handleAuthentication() {
    await this.init();
    try {
      const result = await this.auth0?.handleRedirectCallback();
      return result ? result.appState : undefined;
    } catch (_) {
      // noop
    }
    return {};
  }

  async getAccessToken(): Promise<string> {
    await this.init();
    return this._getTokenSilently();
  }

  async getProfile() {
    return (await this.init()).getUser();
  }

  async logout() {
    (await this.init()).logout({
      returnTo: this._opts.logout_uri || this._opts.redirect_uri,
    });
  }

  async isAuthenticated() {
    return (await this.init()).isAuthenticated();
  }
}

export default new Auth0({
  audience: 'https://haka-api.topsolution.it',
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  redirect_uri: window.location.origin,
  scope: 'openid email profile roles permissions access:haka manage:haka',
  state: '',
});
