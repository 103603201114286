import React, { useState, Ref } from 'react';

import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';

import PersonDialog, { PersonDialogCommonProps } from './PersonDialog';

type EditPersonDialogProps = PersonDialogCommonProps & { onDialogOpen: () => void };

export default React.forwardRef(function EditPersonDialog(props: EditPersonDialogProps, ref: Ref<HTMLLIElement>) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(true);
    props.onDialogOpen();
  };

  return (
    <>
      <MenuItem onClick={handleClick} ref={ref}>
        {t('peopleManagement:updateInfo')}
      </MenuItem>
      <PersonDialog open={open} setOpen={setOpen} editMode={true} {...props} />
    </>
  );
});
