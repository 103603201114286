import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Company } from '../../../../entities/Company';
import useCompanies from '../../../../hooks/store/useCompanies';
import ProgressButton from '../../../common/ProgressButton';

const useStyles = makeStyles({
  confirmButton: {
    color: red[700],
  },
});

type CompanyDeleteDataDialogProps = {
  open: boolean;
  onClose: (value?: string) => void;
  company: Company;
};

export default function CompanyDeleteDataDialog(props: CompanyDeleteDataDialogProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { onClose, open, company } = props;
  const [confirmValue, setConfirmValue] = useState('');
  const { deleteCompany, deleteCompanyRequest } = useCompanies();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (deleteCompanyRequest.error) {
      if (deleteCompanyRequest.error.status === 400) {
        enqueueSnackbar(t('company:deleteUnspentCredit'), { variant: 'error' });
      } else {
        throw deleteCompanyRequest.error;
      }
    }
  }, [deleteCompanyRequest.error, enqueueSnackbar, t]);

  useEffect(() => {
    if (!open) {
      setConfirmValue('');
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleDeleteCompany = async () => {
    const err = await deleteCompany(company.id);
    onClose();
    if (!err) {
      history.push('/');
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t('companyData:removeCompany')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('companyData:removeCompanyText')}</DialogContentText>
        <DialogContentText>
          {t('companyData:removeCompanyConfirmField', { companyName: company.name })}
        </DialogContentText>
        <Box mt={2}>
          <TextField
            id="outlined-basic"
            placeholder={company.name}
            size="small"
            variant="outlined"
            fullWidth
            value={confirmValue}
            onChange={(e) => setConfirmValue(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('common:cancel')}</Button>
        <ProgressButton
          className={classes.confirmButton}
          disabled={confirmValue !== company.name}
          onClick={() => handleDeleteCompany()}
          loading={deleteCompanyRequest.inProgress}
        >
          {t('common:delete')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
