import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import en from './lib/i18n/en';
import it from './lib/i18n/it';

export default function i18n(): string {
  const lng = 'it';

  i18next.use(initReactI18next).init({
    fallbackLng: 'it',
    lng,
    interpolation: {
      escapeValue: false, // not needed for react
    },
    resources: { it, en },
  });

  return lng;
}
