import { Repository } from '@top-solution/utils';
import { CompanyPerson } from '../entities/CompanyPerson';

class CompaniesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/people',
    });
  }

  async getList(
    companyId: string
  ): Promise<Array<CompanyPerson & { branches: CompanyPerson['branches'] | undefined }>> {
    const { data } = await this.axios.get(`/`, { params: { company_id: companyId } });
    return data;
  }

  async create(companyId: string, companyPerson: Omit<CompanyPerson, 'id'>): Promise<CompanyPerson> {
    const { data } = await this.axios.post(`/`, {
      ...companyPerson,
      company_id: companyId,
      branches: companyPerson.branches.map((branch) => branch.id),
      position: companyPerson.position.name,
    });
    return data;
  }

  async update(companyId: string, companyPerson: CompanyPerson): Promise<void> {
    const { data } = await this.axios.put(`/${companyPerson.id}`, {
      ...companyPerson,
      company_id: companyId,
      branches: companyPerson.branches.map((branch) => branch.id),
      position: companyPerson.position.name,
    });
    return data;
  }

  async delete(id: string): Promise<CompanyPerson> {
    const { data } = await this.axios.delete(`/${id}`);
    return data;
  }
}

export default new CompaniesRepository();
