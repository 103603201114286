import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useCampaignResource } from '../../../../hooks/store/useCampaigns';
import { CampaignStatus } from '../../../../lib/constants/campaign-status';
import DashedContainer from '../../../common/DashedContainer';

export default function AlertSection(): JSX.Element {
  const { companyId } = useParams<{ companyId: string }>();
  const [campaignsById] = useCampaignResource(companyId || '');
  const campaigns = Object.values(campaignsById);
  const { t } = useTranslation(['overview', 'alert']);

  const recentReadyCampaigns = useMemo(
    () =>
      campaigns.filter(
        (el) => el.status === CampaignStatus.READY && DateTime.fromISO(el.start_at) < DateTime.local().plus({ days: 3 })
      ),
    [campaigns]
  );

  const recentIncompleteCampaigns = useMemo(
    () =>
      campaigns.filter(
        (el) =>
          el.status === CampaignStatus.IN_PROGRESS &&
          el.assessment_status.in_progress + el.assessment_status.ready > 0 &&
          DateTime.fromISO(el.end_at) < DateTime.local().plus({ days: 3 })
      ),
    [campaigns]
  );

  const recentClosedCampaigns = useMemo(
    () =>
      campaigns.filter(
        (el) => el.status === CampaignStatus.CLOSED && DateTime.fromISO(el.end_at) > DateTime.local().minus({ days: 3 })
      ),
    [campaigns]
  );

  if ([...recentReadyCampaigns, ...recentIncompleteCampaigns, ...recentClosedCampaigns].length < 1) {
    return (
      <Box height="20vh">
        <DashedContainer>{t('overview:alertNoData')}</DashedContainer>
      </Box>
    );
  }

  return (
    <Box width="100%" maxHeight="250px">
      <List dense={true}>
        {recentReadyCampaigns.map((el) => {
          return (
            <ListItem disableGutters key={el.id}>
              <ListItemText
                primary={el.name}
                secondary={t('alert:readyIn', { date: DateTime.fromISO(el.end_at).toISODate() })}
              />
            </ListItem>
          );
        })}
        {recentIncompleteCampaigns.map((el) => {
          return (
            <ListItem disableGutters key={el.id}>
              <ListItemText
                primary={el.name}
                secondary={t('alert:endAlert', {
                  count: el.assessment_status.in_progress + el.assessment_status.ready,
                  date: DateTime.fromISO(el.end_at).toISODate(),
                })}
              />
            </ListItem>
          );
        })}
        {recentClosedCampaigns.map((el) => {
          return (
            <ListItem disableGutters key={el.id}>
              <ListItemText
                primary={el.name}
                secondary={
                  t('alert:endIn', { date: DateTime.fromISO(el.end_at).toISODate() }) +
                  (el.assessment_status.overdue > 0
                    ? t('alert:overdueAlert', { count: el.assessment_status.overdue })
                    : '')
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
