import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campaign } from '../../entities/Campaign';
import {
  mutateErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  requestState,
} from '../toolkitUtils';

const initialState = {
  byId: {} as Record<Campaign['id'], Campaign>,
  requests: {
    readList: requestState(),
    create: requestState(),
    delete: requestState(),
    recall: requestState(),
  },
};

export type CampaignsState = typeof initialState;

interface ReadCampaignListSuccessAction {
  response: Array<Campaign>;
}

interface CreateCampaignSuccessAction {
  response: Campaign;
}

interface DeleteCampaignSuccessAction {
  response: Campaign['id'];
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    readCampaignListRequest(state) {
      mutateRequestState(state.requests.readList);
    },
    readCampaignListSuccess(state, action: PayloadAction<ReadCampaignListSuccessAction>) {
      const { response } = action.payload;

      state.byId = {};

      for (let i = 0; i < response.length; i++) {
        const campaign = response[i];
        if (campaign) {
          state.byId[campaign.id] = campaign;
        }
      }
      mutateSuccessState(state.requests.readList);
    },
    readCampaignListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readList, action.payload.error);
    },
    createCampaignRequest(state) {
      mutateRequestState(state.requests.create);
    },
    createCampaignSuccess(state, action: PayloadAction<CreateCampaignSuccessAction>) {
      const { response } = action.payload;

      state.byId[response.id] = response;
      mutateSuccessState(state.requests.create);
    },
    createCampaignFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.create, action.payload.error);
    },
    deleteCampaignRequest(state) {
      mutateRequestState(state.requests.delete);
    },
    deleteCampaignSuccess(state, action: PayloadAction<DeleteCampaignSuccessAction>) {
      const { response } = action.payload;

      delete state.byId[response];
      mutateSuccessState(state.requests.delete);
    },
    deleteCampaignFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.delete, action.payload.error);
    },
    recallCampaignRequest(state) {
      mutateRequestState(state.requests.recall);
    },
    recallCampaignSuccess(state) {
      mutateSuccessState(state.requests.recall);
    },
    recallCampaignFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.recall, action.payload.error);
    },
  },
});

export const campaignActions = campaignSlice.actions;

export default campaignSlice.reducer;
