import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import DatabaseRemove from 'mdi-material-ui/DatabaseRemove';

import useAuth from '../../../../hooks/store/useAuth';
import { UserRoles } from '../../../../lib/constants/user-roles';
import SectionCard from '../../../common/SectionCard';
import DashboardAppLayout from '../../DashboardAppLayout';
import CompanyDeleteData from './CompanyDeleteData';
import CompanyInfo from './CompanyInfo';
import CompanyLogo from './CompanyLogo';
import CompanyUsers from './CompanyUsers';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CompanyData = (): JSX.Element => {
  const { t } = useTranslation(['errors']);
  const { role } = useAuth();
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <DashboardAppLayout>
      <Container maxWidth="md">
        <Box mt={4} mb={3}>
          <Typography component="h4" variant="h4" color="inherit">
            {t('companyData:companyData')}
          </Typography>
        </Box>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={t('companyData:info')} />
          <Tab label={t('companyData:users')} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <SectionCard title={t('companyData:info')} icon={<BusinessIcon />}>
            <CompanyInfo />
          </SectionCard>
          <SectionCard title={t('companyData:logo')} icon={<CropOriginalIcon />}>
            <CompanyLogo />
          </SectionCard>
          {role === UserRoles.ADMIN ? (
            <SectionCard title={t('companyData:deleteCompanyData')} icon={<DatabaseRemove />}>
              <CompanyDeleteData />
            </SectionCard>
          ) : null}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <CompanyUsers />
        </TabPanel>
      </Container>
    </DashboardAppLayout>
  );
};

export default CompanyData;
