import React from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { LayoutDrawerMenu } from './LayoutDrawerMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid #404854',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  toggleDrawerButton: {
    color: theme.palette.common.white,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  rotateHalf: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
}));

type LayoutDrawerProps = {
  onMobileDrawerToggle: () => void;
  mobileDrawerOpen: boolean;
  drawerOpen: boolean;
  onDrawerToggle: () => void;
};

export default function LayoutDrawer(props: LayoutDrawerProps): JSX.Element {
  const { mobileDrawerOpen, onMobileDrawerToggle, drawerOpen, onDrawerToggle } = props;
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerToggle = () => {
    onDrawerToggle();
  };

  return (
    <div className={classes.root}>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileDrawerOpen}
          onClose={onMobileDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <LayoutDrawerMenu drawerOpen={true} />
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          anchor="left"
          classes={{
            paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
          }}
          open={drawerOpen}
        >
          <LayoutDrawerMenu drawerOpen={drawerOpen} />
          <div className={clsx(classes.toolbarIcon, !drawerOpen && classes.alignCenter)}>
            <Divider />
            <IconButton
              onClick={handleDrawerToggle}
              className={clsx(classes.toggleDrawerButton, !drawerOpen && classes.rotateHalf)}
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}
