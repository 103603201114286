import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'typeface-roboto';
import { Router } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

import { it } from 'date-fns/locale';
import { createBrowserHistory } from 'history';
import CssBaseline from '@material-ui/core/CssBaseline';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/lab';
import DateFnsAdapter from '@material-ui/lab/AdapterDateFns';
import { ResourceProvider } from '@top-solution/utils';
import Auth0, { Auth0AppState } from './auth/Auth0';
import App from './components/App';
import { ConfirmDialogProvider } from './components/common/ConfirmDialog/useConfirmDialog';
import HandleAuthRedirect from './components/common/HandleAuthRedirect';
import { LocalizedYupProvider } from './hooks/useLocalizedYup';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { getTheme } from './theme';

const history = createBrowserHistory();
const locale = i18n();

if (process.env.REACT_APP_ENV) {
  datadogLogs.init({
    clientToken: 'pubfc48f78b382cd29b655850df338a766a',
    // datacenter: Datacenter.EU,
    forwardErrorsToLogs: false,
    sampleRate: 100,
  });
  datadogLogs.addLoggerGlobalContext('app', 'haka-backoffice');
  datadogLogs.addLoggerGlobalContext('env', process.env.REACT_APP_ENV);
  datadogLogs.addLoggerGlobalContext('version', process.env.REACT_APP_VERSION);
  datadogLogs.addLoggerGlobalContext('commit', process.env.REACT_APP_COMMIT);
  datadogLogs.addLoggerGlobalContext('ref_name', process.env.REACT_APP_REFNAME);
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: Auth0AppState): void => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

Auth0.init(onRedirectCallback);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={it}>
      <LocalizedYupProvider>
        <Provider store={store}>
          <HandleAuthRedirect>
            <Router history={history}>
              <ThemeProvider theme={getTheme(locale)}>
                <ResourceProvider>
                  <ConfirmDialogProvider>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <App />
                  </ConfirmDialogProvider>
                </ResourceProvider>
              </ThemeProvider>
            </Router>
          </HandleAuthRedirect>
        </Provider>
      </LocalizedYupProvider>
    </LocalizationProvider>
  </StyledEngineProvider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
