import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import { Company } from '../../entities/Company';

type CompanyCardProps = {
  company: Company;
};

export default function CompanyCard(props: CompanyCardProps): JSX.Element {
  const { company } = props;
  const { t } = useTranslation(['common', 'company', 'companySize', 'companyMarket']);
  const history = useHistory();

  const size = t(`companySize:${company.company_size.substr(company.company_size.indexOf(':') + 1)}`);
  const market = t(`companyMarket:${company.company_market.substr(company.company_market.indexOf(':') + 1)}`);

  return (
    <Card variant="outlined" onClick={() => history.push(`/dashboard/${company.id}`)}>
      <CardActionArea>
        <CardHeader title={company.name} subheader={`${size} ${t('common:employees')} — ${market}`} />
        {/* <CardContent>
        </CardContent> */}
      </CardActionArea>
    </Card>
  );
}
