import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chartjs from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime, Interval } from 'luxon';
import Box from '@material-ui/core/Box';
import { Compilation } from '../../../../entities/Stats';

const chartData = {
  datasets: [
    {
      label: '# of Compilations',
      lineTension: 0,
      pointRadius: 1,
      pointHitRadius: 6,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      fill: false,
    },
  ],
};

const chartOpts = {
  locale: 3,
  legend: {
    display: false,
  },
  responsive: true,
  title: {
    display: false,
    text: 'latest Compilations',
  },
  tooltips: {
    callbacks: {
      title: function (tooltipItems: Chartjs.ChartTooltipItem[]) {
        const tooltipItem = tooltipItems[0];
        if (tooltipItem) {
          return tooltipItem.label?.replace(', 00:00:00', '');
        }
        return '';
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
      },
      adapters: {
        date: {
          locale: 'it',
        },
      },
      gridLines: {
        drawOnChartArea: false,
      },
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        maxTicksLimit: 7,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0,
      },
    },
  },
};

// Extract all DateTimes from Luxon Interval
function* days(interval: Interval) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

type CompilationChartProps = {
  compilations: Compilation[];
};

const CompilationChart = (props: CompilationChartProps): JSX.Element => {
  const { compilations } = props;
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  const { t } = useTranslation(['overview']);

  const last30Days = Array.from(days(Interval.before(DateTime.local(), { days: 30 })));

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartData.datasets[0]) {
        chartData.datasets[0].label = t('overview:chartTooltip');
      }
      const newChartInstance = new Chartjs(chartContainer.current, {
        type: 'line',
        data: chartData,
        options: chartOpts as Chart.ChartOptions, // Ugly workaround while waiting typings for chartjs 3.0
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, t]);

  useEffect(() => {
    if (compilations && chartInstance && chartInstance.data.datasets) {
      chartInstance.data.labels = (last30Days as unknown) as Date[]; // Ugly workaround while waiting typings for chartjs 3.0

      if (chartInstance.data.datasets[0]) {
        chartInstance.data.datasets[0].data = last30Days.map((date) => {
          return {
            t: date.valueOf(),
            y: compilations.find((comp) => date.hasSame(DateTime.fromJSDate(comp.day), 'day'))?.number || 0,
          };
        });
      }
      chartInstance.update();
    }
  }, [chartInstance, compilations, last30Days, t]);

  return (
    <Box width="100%" height="100%">
      <canvas ref={chartContainer} />
    </Box>
  );
};

export default CompilationChart;
