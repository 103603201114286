import { Repository } from '@top-solution/utils';
import { CompanyMarket } from '../entities/CompanyMarket';

class CompanyMarketsRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/markets',
    });
  }
  async getList(): Promise<Array<CompanyMarket>> {
    const { data } = await this.axios.get(`/`);
    return data;
  }
}

export default new CompanyMarketsRepository();
