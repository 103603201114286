import { Repository } from '@top-solution/utils';
import { Assesse } from '../entities/Assesse';
import { Campaign, CreateCampaignPayload } from '../entities/Campaign';

class CampaignRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/campaigns',
    });
  }

  async getList(companyId: string): Promise<Array<Campaign>> {
    const { data } = await this.axios.get(`/`, {
      params: {
        company_id: companyId,
      },
    });
    return data;
  }

  async getAssessees(campaignId: string): Promise<Array<Assesse>> {
    const { data } = await this.axios.get(`/${campaignId}/assessees`);
    return data;
  }

  async getReport(campaignId: string, personId: string): Promise<string> {
    const { data } = await this.axios.get(`/${campaignId}/assessees/${personId}/report`);
    return data.url;
  }

  async batchReports(campaignId: string, personIds: string[]): Promise<string> {
    const { data } = await this.axios.post(`/${campaignId}/batch_reports`, [...personIds]);
    return data;
  }

  async create(campaign: CreateCampaignPayload): Promise<Campaign> {
    const { data } = await this.axios.post(`/`, campaign);
    return data;
  }

  async delete(campaignId: Campaign['id']): Promise<Campaign> {
    const { data } = await this.axios.delete(`/${campaignId}`);
    return data;
  }

  async recall(campaignId: Campaign['id']): Promise<void> {
    const { data } = await this.axios.post(`/${campaignId}/recalls`);
    return data;
  }

  async recallAssessor(campaignId: Campaign['id'], assessorId: string): Promise<void> {
    const { data } = await this.axios.post(`/${campaignId}/assessors/${assessorId}/recalls`);
    return data;
  }
}

export default new CampaignRepository();
