import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1.5),
  },
  label: {
    opacity: 0.6,
    width: 180,
    fontSize: '92%',
  },
  value: {},
}));

export type CompanyFormForm = {
  name: string;
  company_size: string;
  company_market: string;
};

type SettingsListProps = {
  children: React.ReactNode | React.ReactNodeArray;
};

export function SettingsList(props: SettingsListProps): JSX.Element {
  const classes = useStyles();
  return <ul className={classes.list}>{props.children}</ul>;
}

type SettingsListItemProps = {
  label: string;
  value: string | number | React.ReactNode;
  editMode?: boolean;
  editComponent?: React.ReactNode;
};

export function SettingsListItem(props: SettingsListItemProps): JSX.Element {
  const classes = useStyles();

  const valueComponent =
    props.editMode && props.editComponent ? props.editComponent : <span className={classes.value}>{props.value}</span>;

  return (
    <Typography variant="body1" component="div">
      <li className={classes.listItem}>
        <label className={classes.label}>{props.label}</label>
        {valueComponent}
      </li>
    </Typography>
  );
}
