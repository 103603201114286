import { Repository } from '@top-solution/utils';
import { Assessment, CreateAssessmentPayload } from '../entities/Assessment';

class AssessmentsRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/assessments',
    });
  }

  async getList(campaignId: string): Promise<Array<Assessment>> {
    const { data } = await this.axios.get('/', {
      params: {
        campaign_id: campaignId,
      },
    });
    return data;
  }

  async create(assessment: CreateAssessmentPayload): Promise<Assessment> {
    const { data } = await this.axios.post(`/`, assessment);
    return data;
  }

  async delete(assessmentId: Assessment['id']): Promise<Assessment> {
    const { data } = await this.axios.delete(`/${assessmentId}`);
    return data;
  }
}

export default new AssessmentsRepository();
