import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from '@material-ui/core/styles';
import { Autocomplete, ProgressButton } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';
import { RequestError } from '@top-solution/utils';
import { CompanyPerson } from '../../../../entities/CompanyPerson';
import useAssessments from '../../../../hooks/store/useAssessments';
import { useCompanyPeopleResource } from '../../../../hooks/store/useCompanyPeople';
import { useLocalizedYup } from '../../../../hooks/useLocalizedYup';
import LoaderView from '../../../common/LoaderView';

const AddDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    overflow: 'visible',
    minWidth: theme.breakpoints.values.sm,
  },
}));

const AddDialogContent = styled(DialogContent)(() => ({
  overflow: 'visible',
}));

const FormItem = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 2),
}));

type AddForm = {
  assessor: CompanyPerson | null;
  assessee: CompanyPerson | null;
};

const formInitialValues = {
  assessor: null,
  assessee: null,
} as AddForm;

export default function AddAssessmentDialog(): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { yup } = useLocalizedYup();
  const { campaignId, companyId } = useParams<{ campaignId: string; companyId: string }>();
  const [companyPeople, companyPeopleRequest] = useCompanyPeopleResource(companyId);
  const { createAssessment, createAssessmentRequest } = useAssessments();
  const { enqueueSnackbar } = useSnackbar();

  const schema = useMemo(
    () =>
      yup.object().shape({
        assessor: yup.object().nullable().required(t('addAssessment:fieldRequired')),
        assessee: yup.object().nullable().required(t('addAssessment:fieldRequired')),
      }),
    [t, yup]
  );

  const { form, reset, setValue, setTouched, onSubmit } = useForm<AddForm>({
    initialValues: formInitialValues,
    schema,
    handleSubmit,
  });

  async function handleSubmit(values: AddForm) {
    if (!campaignId) {
      return;
    }

    try {
      const assessment = await createAssessment({
        assessor: values.assessor?.id || '',
        assessee: values.assessee?.id || '',
        campaign_id: campaignId,
      });

      if (assessment) {
        reset();
        setOpen(false);
        enqueueSnackbar(t('addAssessment:success'), { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(t(`addAssessment:error${(e as RequestError).code}`), { variant: 'error' });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" variant="contained" disableElevation onClick={handleClickOpen}>
        {t('addAssessment:addAssessment')}
      </Button>
      <AddDialog open={open} onClose={handleClose}>
        <DialogTitle>{t('addAssessment:title')}</DialogTitle>
        <AddDialogContent>
          <LoaderView condition={!companyPeopleRequest.inProgress}>
            <FormItem>
              <Autocomplete
                required={true}
                label={t('addAssessment:assessor')}
                value={form.values.assessor}
                onChange={(v) => {
                  setValue(v, 'assessor');
                  setTouched('assessor');
                }}
                options={companyPeople}
                getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
                error={form.errors.assessor && form.touched.assessor}
                helperText={form.touched.assessor ? form.errors.assessor?.message : ''}
              />
            </FormItem>
            <FormItem>
              <Autocomplete
                required={true}
                label={t('addAssessment:assessee')}
                value={form.values.assessee}
                onChange={(v) => {
                  setValue(v, 'assessee');
                  setTouched('assessee');
                }}
                options={companyPeople}
                getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
                error={form.errors.assessee && form.touched.assessee}
                helperText={form.touched.assessee ? form.errors.assessee?.message : ''}
              />
            </FormItem>
          </LoaderView>
        </AddDialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t('common:cancel')}
          </Button>
          <ProgressButton
            inProgress={createAssessmentRequest.inProgress}
            onClick={onSubmit}
            disabled={form.isPristine || !form.isValid}
            color="primary"
          >
            {t('common:add')}
          </ProgressButton>
        </DialogActions>
      </AddDialog>
    </>
  );
}
