import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequestState, Resource, useResource } from '@top-solution/utils';
import PositionsRepository from '../../api/PositionsRepository';
import { Position } from '../../entities/Position';
import { RootState } from '../../store/reducers';
import {
  ReadPositionsRequestAction,
  READ_POSITIONS_REQUEST,
  ReadPositionsSuccessAction,
  READ_POSITIONS_SUCCESS,
  ReadPositionsFailureAction,
  READ_POSITIONS_FAILURE,
} from '../../store/reducers/positions';

type UsePositions = {
  positions: Record<Position['name'], Position>;
  readPositionList: () => void;
  readListRequest: RequestState;
};

export default function usePositions(): UsePositions {
  const dispatch = useDispatch();
  const { t } = useTranslation(['position']);

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      PositionsRepository.updateAuthToken(token);
    }
  }, [token]);

  const positions = useSelector((state: RootState) => state.positions.positions);

  const readListRequest = useSelector((state: RootState) => state.positions.requests.readList);

  const readPositionList = useCallback(() => {
    dispatch<ReadPositionsRequestAction>({ type: READ_POSITIONS_REQUEST });

    const read = async () => {
      try {
        const positions = await PositionsRepository.getList();

        const positionsWithLabels = positions.map((position) => ({
          ...position,
          label: t(`position:${position.name.substr(position.name.indexOf(':') + 1)}`),
        }));

        dispatch<ReadPositionsSuccessAction>({
          type: READ_POSITIONS_SUCCESS,
          positions: positionsWithLabels,
        });
      } catch (error) {
        dispatch<ReadPositionsFailureAction>({ type: READ_POSITIONS_FAILURE, error });
      }
    };

    read();
  }, [dispatch, t]);

  return {
    positions,
    readPositionList,
    readListRequest,
  };
}

export function usePositionsResource(): Resource<Record<Position['name'], Position>> {
  const { positions, readPositionList, readListRequest } = usePositions();
  return useResource('positions', positions, readPositionList, readListRequest);
}
