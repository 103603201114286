export function matchStrings(strings: Array<string>, query: string | null): boolean {
  if (!query || !query.length) {
    return true;
  }

  const lowercaseQuery = query.toLowerCase().trim();

  for (let i = 0; i < strings.length; i++) {
    const string = strings[i];
    if (string) {
      if (string.toLowerCase().trim().indexOf(lowercaseQuery) >= 0) {
        return true;
      }
    }
  }

  return false;
}

export const FILTER_ALL = '__all';
