import { Repository } from '@top-solution/utils';

class UsersRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/users',
    });
  }

  async updateUser(): Promise<boolean> {
    await this.axios.post(`/me`);
    return true;
  }
}

export default new UsersRepository();
