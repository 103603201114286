import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useCompanies from '../../hooks/store/useCompanies';
import { CompanyFormForm } from '../../hooks/useCompanyForm';
import CompanyForm from '../common/CompanyForm';
import AppLayout from '../layout/Layout';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function AddCompany(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['company', 'errors']);

  const { createCompany, createCompanyRequest } = useCompanies();

  useEffect(() => {
    if (createCompanyRequest.error) {
      throw createCompanyRequest.error;
    }
  }, [createCompanyRequest.error]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, form: CompanyFormForm) => {
    const createdCompany = await createCompany(form);

    if (createdCompany) {
      history.push(`/dashboard/${createdCompany.id}`);
    }
  };

  return (
    <AppLayout showDrawer={false}>
      <Container maxWidth="lg" className={classes.container}>
        <Paper variant="outlined">
          <Box p={2}>
            <Box mb={4}>
              <Typography variant="h5">{t('company:addCompany')}</Typography>
            </Box>
            <CompanyForm onSubmit={handleSubmit} onReset={() => history.goBack()} />
          </Box>
        </Paper>
      </Container>
    </AppLayout>
  );
}
