import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import useAuth from '../../hooks/store/useAuth';

interface IPrivateRouteProps extends RouteProps {
  path: string;
}

const PrivateRoute = ({ component: Component, path, ...rest }: IPrivateRouteProps): JSX.Element => {
  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    login({ targetUrl: window.location.pathname });
  }, [isAuthenticated, login]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const render = (props: any) => (isAuthenticated === true && Component ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
