import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RequestState, Resource, useResource } from '@top-solution/utils';
import CompanyMarketsRepository from '../../api/CompanyMarketsRepository';
import { CompanyMarket } from '../../entities/CompanyMarket';
import { RootState } from '../../store/reducers';
import {
  ReadCompanyMarketsRequestAction,
  READ_COMPANY_MARKETS_REQUEST,
  ReadCompanyMarketsSuccessAction,
  READ_COMPANY_MARKETS_SUCCESS,
  ReadCompanyMarketsFailureAction,
  READ_COMPANY_MARKETS_FAILURE,
} from '../../store/reducers/companyMarkets';

type UseCompanyMarkets = {
  companyMarkets: Record<CompanyMarket['name'], CompanyMarket>;
  readCompanyMarketsList: () => void;
  readListRequest: RequestState;
};

export default function useCompanyMarkets(): UseCompanyMarkets {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      CompanyMarketsRepository.updateAuthToken(token);
    }
  }, [token]);

  const companyMarkets = useSelector((state: RootState) => state.companyMarkets.companyMarkets);

  const readListRequest = useSelector((state: RootState) => state.companyMarkets.requests.readList);

  const readCompanyMarketsList = useCallback(() => {
    dispatch<ReadCompanyMarketsRequestAction>({ type: READ_COMPANY_MARKETS_REQUEST });

    const read = async () => {
      try {
        const companyMarkets = await CompanyMarketsRepository.getList();

        dispatch<ReadCompanyMarketsSuccessAction>({
          type: READ_COMPANY_MARKETS_SUCCESS,
          companyMarkets,
        });
      } catch (error) {
        dispatch<ReadCompanyMarketsFailureAction>({ type: READ_COMPANY_MARKETS_FAILURE, error });
      }
    };

    read();
  }, [dispatch]);

  return {
    companyMarkets,
    readCompanyMarketsList,
    readListRequest,
  };
}

export function useCompanyMarketsResource(): Resource<Record<CompanyMarket['name'], CompanyMarket>> {
  const { companyMarkets, readCompanyMarketsList, readListRequest } = useCompanyMarkets();
  return useResource('companyMarket', companyMarkets, readCompanyMarketsList, readListRequest);
}
