import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashedContainer: {
      width: '100%',
      height: '100%',
    },
    dashedElement: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.grey[50],
      border: `2px dashed ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(1),
      color: theme.palette.grey[500],
      padding: theme.spacing(2),
    },
  })
);

type DashedContainerProps = {
  children?: Array<React.ReactNode> | React.ReactNode;
};

const DashedContainer = (props: DashedContainerProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.dashedContainer}>
      <Typography className={classes.dashedElement}>{props.children}</Typography>
    </div>
  );
};

export default DashedContainer;
