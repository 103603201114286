/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    appFullName: 'Top Solution 測 HAKA',
    appShortName: '測 HAKA',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    save: 'Save',
    update: 'Update',
    ok: 'OK',
    close: 'Close',
    next: 'Next',
    back: 'Back',
    finish: 'Finish',
    search: 'Search',
    email: 'Email',
    password: 'Password',
  },
  errors: {
    required: 'This field is required',
    invalidEmail: 'Please insert a valid email',
  },
  login: {
    login: 'Sign in',
    loginSubmit: 'Sign in',
    errorRequiredEmail: 'Please insert your email',
    errorRequiredPassword: 'Please insert your password',
  },
  assessmentsPage: {
    pageTitle: 'Select a poll',
    tablePollNameColumnTitle: 'Poll Name',
    tableStatusColumnTitle: 'Status',
    tableDueDateColumnTitle: 'Due Date',
    selfEvaluation: 'Self-evaluation',
  },
  assessmentStatus: {
    all: 'All',
    draft: 'Draft',
    ready: 'Ready',
    inProgress: 'In progress',
    closed: 'Closed',
    overdue: 'Overdue',
  },
  campaignStatus: {
    all: 'All',
    draft: 'Draft',
    ready: 'Ready',
    inProgress: 'In progress',
    closed: 'Closed',
  },
  assessmentPage: {
    thanksForCompiling: 'Thanks for compiling this assessment',
    completedPercentage: 'This assessment is completed at: {{percentage}}%',
    backToAssessments: 'Back to the assessments list',
    answerAllQuestions: 'Please answer all questions',
    finishModal: {
      title: 'Do you really want to finish this assessment?',
      text: 'Finishing the assessment makes no longer possible to change the answers.',
      confirm: 'Finish',
      close: 'Cancel',
    },
    closeModal: {
      title: 'Do you really want to return to the assessments list?',
      text: 'All answers chenge since the last save will be lost.',
      confirm: 'Close',
      close: 'Cancel',
    },
    savedSnackbar: {
      message: 'Assessment saved',
      error: 'An error has occurred while saving the assessment',
    },
  },
};
