import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useCompanyResource } from '../../../../hooks/store/useCompanies';
import LoaderView from '../../../common/LoaderView';
import CompanyDeleteDataDialog from './CompanyDeleteDataDialog';

function CompanyDeleteData() {
  const { t } = useTranslation();
  const { companyId } = useParams() as { companyId: string | undefined };

  const [company, companyRequest, companyErr] = useCompanyResource(companyId || '');
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (companyErr) {
      throw companyErr;
    }
  }, [companyErr]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <LoaderView condition={!companyRequest.inProgress} minHeight="140px">
      <Box mx={-3}>
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItem button onClick={() => setDialogOpen(true)}>
            <Box px={1}>
              <ListItemText primary={t('companyData:removeCompany')} />
            </Box>
          </ListItem>
        </List>
      </Box>
      {company && <CompanyDeleteDataDialog open={dialogOpen} onClose={handleDialogClose} company={company} />}
    </LoaderView>
  );
}

export default function CompanyDeleteDataParamsWrapper(): JSX.Element | null {
  const { companyId } = useParams() as { companyId: string | undefined };

  if (!companyId) {
    return null;
  }

  return <CompanyDeleteData />;
}
