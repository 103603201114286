import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Redirect, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import GlobalErrorBoundary from '../components/common/GlobalErrorBoundary';
import PrivateRoute from '../components/common/PrivateRoute';
import AddCompany from '../components/pages/AddCompany';
import Companies from '../components/pages/Companies';
import Dashboard from '../components/pages/Dashboard';
import useAuth from '../hooks/store/useAuth';

export default function App(): JSX.Element {
  const { autoRefreshToken } = useAuth();

  useEffect(() => {
    autoRefreshToken();
  }, [autoRefreshToken]);

  return (
    <GlobalErrorBoundary>
      <Helmet>
        <title>{`Haka Dashboard`}</title>
      </Helmet>
      <SnackbarProvider preventDuplicate>
        <Switch>
          <PrivateRoute exact path="/" component={Companies} />
          <PrivateRoute path="/company/add" component={AddCompany} />
          <PrivateRoute path="/dashboard/:companyId" component={Dashboard} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </SnackbarProvider>
    </GlobalErrorBoundary>
  );
}
