import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from 'react-router-dom';
import { uniq } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';
import { CompanyPerson } from '../../../../entities/CompanyPerson';
import { useBranchesResource } from '../../../../hooks/store/useBranches';
import useCompanyPeople, { useCompanyPeopleResource } from '../../../../hooks/store/useCompanyPeople';
import { usePositionsResource } from '../../../../hooks/store/usePositions';
import DataTable, { matchStrings } from '../../../common/DataTable';
import { FILTER_ALL } from '../../../common/DataTable/utils';
import LoaderView from '../../../common/LoaderView';
import SectionCard from '../../../common/SectionCard';
import DashboardAppLayout from '../../DashboardAppLayout';
import AddPersonDialog from './AddPersonDialog';
import PersonMenu from './PersonMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

function People(props: { companyId: string }) {
  const { companyId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [companyPeople, companyPeopleRequest, companyPeopleError, invalidateCompanyPeople] = useCompanyPeopleResource(
    companyId
  );
  const { createCompanyPerson, updateCompanyPerson } = useCompanyPeople();
  const [branches] = useBranchesResource(companyId);
  const [positions] = usePositionsResource();

  const departments = useMemo(
    () => uniq(companyPeople.reduce<Array<string>>((arr, person) => [...arr, ...(person.title?.split(',') || [])], [])),
    [companyPeople]
  );

  useEffect(() => {
    if (companyPeopleError) {
      throw companyPeopleError;
    }
  }, [companyPeopleError]);

  useEffect(() => {
    invalidateCompanyPeople();
  }, [invalidateCompanyPeople]);

  const handleAddPerson = (person: Omit<CompanyPerson, 'id'>) => {
    if (companyId) {
      createCompanyPerson(companyId, person);
    }
  };

  const handlePersonUpdate = (person: CompanyPerson) => {
    if (companyId) {
      updateCompanyPerson(companyId, person);
    }
  };

  return (
    <DashboardAppLayout>
      <Container maxWidth="lg" className={classes.container}>
        <Typography component="h4" variant="h4" color="inherit" className={classes.title}>
          {t('peopleManagement:title')}
        </Typography>
        <SectionCard>
          <LoaderView condition={!companyPeopleRequest.inProgress}>
            <DataTable
              columns={[
                { title: t('person:lastName'), field: 'last_name', sortable: true },
                { title: t('person:firstName'), field: 'first_name', sortable: true },
                { title: t('person:email'), field: 'email', width: 0.24 },
                {
                  title: t('person:positionAndTitle'),
                  field: 'position',
                  width: 0.15,
                  sortable: true,
                  sortFunction: (a, b) => {
                    const w = a.position.weight - b.position.weight;
                    if (w !== 0) return w;
                    return a.position.label.localeCompare(b.position.label);
                  },
                },
                {
                  title: t('person:branches'),
                  field: 'branches',
                  width: 0.25,
                  sortable: true,
                  sortFunction: (a, b) => (a.branches[0]?.name || '').localeCompare(b.branches[0]?.name || ''),
                },
              ]}
              idAccessor="id"
              rowRenderer={(o: CompanyPerson) => ({
                last_name: o.last_name,
                first_name: o.first_name,
                email: o.email,
                position: (
                  <div>
                    {t(`${o.position.name}`)}
                    <Typography color="textSecondary" variant="body2">
                      {o.title?.split(',').join(', ')}
                    </Typography>
                  </div>
                ),
                branches: (
                  <Grid container spacing={1}>
                    {o.branches.map((branch) => (
                      <Grid key={branch.id} item>
                        <Chip label={`${t(branch.name)}`} />
                      </Grid>
                    ))}
                  </Grid>
                ),
              })}
              rows={companyPeople}
              actionColumn={(person: CompanyPerson) => (
                <PersonMenu
                  key={person.id}
                  companyId={companyId}
                  person={person}
                  onPersonUpdate={handlePersonUpdate}
                  departments={departments}
                />
              )}
              headerActions={
                <AddPersonDialog companyId={companyId} onAddPerson={handleAddPerson} departments={departments} />
              }
              filters={['search', 'position', 'branch']}
              filtersComponents={(setSearchQuery) => [
                <TextField
                  key="search"
                  fullWidth
                  placeholder="Cerca"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchQuery('search', e.target.value)}
                />,
                <FormControl key={'position'} fullWidth>
                  <InputLabel shrink id="position-filter-label">
                    {t('person:position')}
                  </InputLabel>
                  <Select
                    labelId="position-filter-label"
                    defaultValue={FILTER_ALL}
                    onChange={(e) => setSearchQuery('position', e.target.value as string)}
                  >
                    <MenuItem value={FILTER_ALL}>{t('common:all')}</MenuItem>
                    {Object.values(positions).map((position) => (
                      <MenuItem key={position.name} value={position.name}>
                        {position.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>,
                <FormControl key="branch" fullWidth>
                  <InputLabel shrink id="branch-filter-label">
                    {t('person:branch')}
                  </InputLabel>
                  <Select
                    labelId="branch-filter-label"
                    defaultValue={FILTER_ALL}
                    onChange={(e) => setSearchQuery('branch', e.target.value as string)}
                  >
                    <MenuItem value={FILTER_ALL}>{t('common:all')}</MenuItem>
                    {Object.values(branches).map((branch) => (
                      <MenuItem key={branch.name} value={branch.name}>
                        {branch.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>,
                <FormControl key="department" fullWidth>
                  <InputLabel shrink id="department-filter-label">
                    {t('person:department')}
                  </InputLabel>
                  <Select
                    labelId="department-filter-label"
                    defaultValue={FILTER_ALL}
                    onChange={(e) => setSearchQuery('department', e.target.value as string)}
                  >
                    <MenuItem value={FILTER_ALL}>{t('common:all')}</MenuItem>
                    {Object.values(departments).map((department) => (
                      <MenuItem key={department} value={department}>
                        {department}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>,
              ]}
              filterFunction={(queries, row) =>
                matchStrings(
                  [
                    row.first_name,
                    row.last_name,
                    `${row.last_name} ${row.first_name}`,
                    `${row.first_name} ${row.last_name}`,
                  ],
                  queries.search || null
                ) &&
                Boolean(!queries.branch || row.branches.find((branch) => queries.branch === branch.name)) &&
                Boolean(!queries.position || row.position.name === queries.position) &&
                Boolean(!queries.department || (row.title && row.title.indexOf(queries.department as string) >= 0))
              }
            />
          </LoaderView>
        </SectionCard>
      </Container>
    </DashboardAppLayout>
  );
}

export default function PeopleWithCompanyId(): JSX.Element {
  const { companyId } = useParams<{ companyId: string }>();
  if (!companyId) {
    return <Redirect to="/" />;
  }
  return <People companyId={companyId} />;
}
