import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import useCompanies from '../../hooks/store/useCompanies';
import AppLayout from '../layout/Layout';

const DashboardAppLayout = (props: { children: React.ReactNode | React.ReactNodeArray }): JSX.Element => {
  const { children } = props;
  const { companies } = useCompanies();
  const { companyId } = useParams<{ companyId: string }>();

  let companyName = '';

  if (companyId) {
    const company = companies[companyId];

    if (company && company.name) {
      companyName = `${company.name} - `;
    }
  }

  return (
    <AppLayout showDrawer={true}>
      <Helmet>
        <title>{`${companyName}Haka Dashboard`}</title>
      </Helmet>
      {children}
    </AppLayout>
  );
};
export default DashboardAppLayout;
