import { Repository } from '@top-solution/utils';
import { Branch } from '../entities/Branch';

class BranchesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}haka`,
      resourceURL: '/v1/branches',
    });
  }

  async getList(companyId: string): Promise<Array<Branch>> {
    const { data } = await this.axios.get('/', {
      params: {
        company_id: companyId,
      },
    });
    return data;
  }
}

export default new BranchesRepository();
