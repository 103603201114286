import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableMeta, MUIDataTableProps } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles, MuiThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RequestError } from '@top-solution/utils';
import CampaignRepository from '../../../../api/CampaignsRepository';
import { Assesse } from '../../../../entities/Assesse';
import { useCampaignResource } from '../../../../hooks/store/useCampaigns';
import useMUIDatatableTranslations from '../../../../hooks/useMUIDatatableTranslations';
import ProgressButton from '../../../common/ProgressButton';
import DashboardAppLayout from '../../DashboardAppLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  wrapTableButton: {
    display: 'flex',
  },
  downloadSelected: {
    marginRight: theme.spacing(2),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Reports(): JSX.Element {
  const classes = useStyles();
  const { companyId, campaignId, pollId } = useParams<{ companyId: string; pollId: string; campaignId: string }>();
  const [campaignsById, campaignsReady] = useCampaignResource(companyId || '');
  const tableTranslation = useMUIDatatableTranslations();
  const { t } = useTranslation(['poolCampaigns', 'assessmentStatus', 'poll', 'assessment', 'reports']);
  const [assessees, setAssessees] = useState<null | Assesse[]>(null);
  const [errorAssessees, setErrorAssessees] = useState<null | RequestError>(null);
  const [loadingBatch, setLoadingBatch] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    const fn = async () => {
      try {
        const assessees = await CampaignRepository.getAssessees(campaignId);
        setAssessees(assessees);
      } catch (e) {
        setErrorAssessees(e);
      }
    };
    fn();
  }, [campaignId]);

  useEffect(() => {
    if (errorAssessees) {
      if (errorAssessees.status === 400) {
        enqueueSnackbar(t('poll:error'), { variant: 'error' });
      } else {
        throw errorAssessees;
      }
    }
  }, [enqueueSnackbar, errorAssessees, t]);

  const openReport = async (personId: string, rowIndex: number) => {
    setLoadingReport(rowIndex);
    try {
      const url = await CampaignRepository.getReport(campaignId, personId);
      window.open(url, '_blank');
    } catch (e) {
      enqueueSnackbar(t('reports:error'), { variant: 'error' });
    } finally {
      setLoadingReport(null);
    }
  };

  const createBooleanrow = (name: string) => {
    return {
      name: name,
      label: t('reports:name'),
      options: {
        sort: true,
        filter: true,
        filterOptions: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderValue: (v: any) => {
            if (v) {
              return t('reports:present');
            }
            return t('reports:miss');
          },
        },
        customFilterListOptions: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render: (v: any) => {
            if (v) {
              return t('reports:autopresent');
            }
            return t('reports:automiss');
          },
        },
        customBodyRender: function Icon(value: boolean) {
          if (value) {
            return (
              <div className={classes.wrapTableButton}>
                <CheckCircleIcon style={{ color: green[500] }} />
              </div>
            );
          }
          return (
            <div className={classes.wrapTableButton}>
              <CancelIcon color="error" />
            </div>
          );
        },
      },
    };
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'person',
      label: t('assessment:assessee'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Assesse['person']) => `${value.last_name} ${value.first_name}`,
      },
    },
    createBooleanrow('auto_completed'),
    createBooleanrow('has_report'),
    {
      name: 'has_report',
      label: t('reports:downloadReport'),
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          renderValue: (v) => {
            if (v) {
              return t('reports:present');
            }
            return t('reports:miss');
          },
        },
        customFilterListOptions: {
          render: (v) => {
            if (v) {
              return t('reports:present');
            }
            return t('reports:miss');
          },
        },
        customBodyRender: function DownloadButton(value: boolean, tableMeta: MUIDataTableMeta) {
          return (
            <div className={classes.wrapTableButton}>
              <ProgressButton
                color="primary"
                disabled={!value}
                variant="contained"
                loading={loadingReport === tableMeta.rowIndex}
                onClick={async () => {
                  await openReport(tableMeta.rowData[0]['id'], tableMeta.rowIndex);
                }}
              >
                {t('download')}
              </ProgressButton>
            </div>
          );
        },
      },
    },
  ];

  const hasReport = (dataIndex: number) => {
    if (assessees) {
      const assessee = assessees[dataIndex];
      if (assessee) {
        return assessee.has_report;
      }
    }
    return false;
  };

  type selectedRows = {
    data: {
      index: number;
      dataIndex: number;
    }[];
    lookup: {
      [key: number]: boolean;
    };
  };

  const requestBatch = async (iDPeopleSelected: string[]) => {
    setLoadingBatch(true);
    try {
      await CampaignRepository.batchReports(campaignId, iDPeopleSelected);
      enqueueSnackbar(t('reports:successBatch'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('reports:error'), { variant: 'error' });
    } finally {
      setLoadingBatch(false);
    }
  };

  const customToolbarSelect = (selectedRows: selectedRows) => {
    const indexes = selectedRows.data.map((r) => r.dataIndex);
    const iDpeopleSelected = assessees?.filter((_, i) => indexes.includes(i)).map((a) => a.person.id);
    return (
      <ProgressButton
        variant="contained"
        loading={loadingBatch}
        className={classes.downloadSelected}
        color="primary"
        onClick={async () => {
          if (iDpeopleSelected && iDpeopleSelected.length > 1) {
            await requestBatch(iDpeopleSelected);
          } else if (iDpeopleSelected && iDpeopleSelected.length === 1) {
            const selected = iDpeopleSelected[0];
            const data = selectedRows.data[0];
            if (selected && data) {
              await openReport(selected, data.index);
            }
          }
        }}
      >
        {t('reports:downloadSelected')}
      </ProgressButton>
    );
  };

  const options: MUIDataTableProps['options'] = {
    filterType: 'checkbox',
    selectableRows: 'multiple',
    download: false,
    elevation: 1,
    print: false,
    viewColumns: false,
    textLabels: tableTranslation,
    selectToolbarPlacement: 'above',
    sortOrder: {
      name: 'has_report',
      direction: 'desc',
    },
    isRowSelectable: hasReport,
    customToolbarSelect: customToolbarSelect,
  };

  // Force muidatatable toolbar to have custom gutters
  const defaultTheme = useTheme();
  const theme = createMuiTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          gutters: {
            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          },
        },
      },
    },
  });

  const campaignName = useMemo(() => {
    if (campaignsReady) {
      const campaign = campaignsById[campaignId];
      if (campaign) {
        return campaign.name;
      }
    }

    return '';
  }, [campaignId, campaignsById, campaignsReady]);

  return (
    <DashboardAppLayout>
      <Container maxWidth="md" className={classes.container}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          <Link
            color="inherit"
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              history.push({
                pathname: generatePath(match.path.replace('/reports', '').replace('/:campaignId', ''), match.params),
              });
            }}
          >
            {t(`${pollId}`)}
          </Link>
          <Link
            color="inherit"
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              history.push({
                pathname: generatePath(match.path.replace('/reports', ''), match.params),
              });
            }}
          >
            {campaignName}
          </Link>
          <Typography color="textPrimary">{t('reports:reports')}</Typography>
        </Breadcrumbs>
        <Box mt={0} mb={3}>
          <Typography component="h4" variant="h4" color="inherit">
            {t('reports:reports')}
          </Typography>
        </Box>
        {assessees ? (
          <Box width="100%">
            <MuiThemeProvider theme={theme}>
              <MUIDataTable title="" data={assessees} columns={columns} options={options} />
            </MuiThemeProvider>
          </Box>
        ) : (
          <Paper className={classes.paper}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
              <CircularProgress />
            </Box>
          </Paper>
        )}
      </Container>
    </DashboardAppLayout>
  );
}
