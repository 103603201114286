import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';

import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

// import BarChartIcon from '@material-ui/icons/BarChart';

const useStyles = makeStyles((theme) => ({
  logo: {
    minWidth: theme.spacing(5),
  },
  categoryHeader: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  haka: {
    ...theme.mixins.toolbar,
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7 !important',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  divider: {
    backgroundColor: '#404854',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  categoryList: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflowY: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
    '&::-webkit-scrollbar': {
      backgroundColor: '#232f3e',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8a9bb2',
      borderRadius: '8px',
      border: '2px solid #262f3d;',
    },
  },
}));

type LayoutDrawerMenuProps = {
  drawerOpen: boolean;
};

export const LayoutDrawerMenu = (props: LayoutDrawerMenuProps): JSX.Element => {
  const { drawerOpen } = props;
  const history = useHistory();
  const match = useRouteMatch<{ companyId: string }>();
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation('sidebar');

  const categories = useMemo(
    () => [
      {
        id: 'company',
        label: t('menu:company'),
        children: [
          { id: 'companyData', label: t('sidebar:companyData'), icon: <BusinessIcon />, path: '/company-data' },
          {
            id: 'peopleManagement',
            label: t('sidebar:peopleManagement'),
            icon: <PeopleIcon />,
            path: '/people',
          },
        ],
      },
      {
        id: 'campaign',
        label: 'campaigns',
        children: [
          {
            id: 'employeePerformance',
            label: 'employeesPerformance',
            icon: <PersonIcon />,
            path: '/polls/poll:employeesPerformance',
          },
          {
            id: 'executivePerformance',
            label: 'executivesPerformance',
            icon: <SupervisorAccountIcon />,
            path: '/polls/poll:executivesPerformance',
          },
          { id: '360', label: '360', icon: <PublicIcon />, path: '/polls/poll:360' },
        ],
      },
      // {
      //   id: 'report',
      //   label: '',
      //   children: [{ id: 'seeReport', label: 'seeReport', icon: <BarChartIcon />, path: '/' }],
      // },
    ],
    [t]
  );

  return (
    <>
      <List disablePadding>
        <ListItem
          button
          className={clsx(classes.haka, classes.item, classes.itemCategory)}
          onClick={() => history.push('/')}
        >
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.logo}>
            測
          </Typography>
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            Haka
          </Typography>
        </ListItem>
        <ListItem
          onClick={() => history.push(`/dashboard/${match?.params.companyId}/overview`)}
          button
          className={clsx(
            classes.item,
            classes.itemCategory,
            location.pathname.indexOf('/overview') > 0 && classes.itemActiveItem
          )}
        >
          <ListItemIcon
            className={clsx(classes.itemIcon, location.pathname.indexOf('/overview') > 0 && classes.itemActiveItem)}
          >
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            {t('companyOverview')}
          </ListItemText>
        </ListItem>
      </List>
      <List disablePadding className={classes.categoryList}>
        {/* Listing Categories */}
        {categories.map(({ id, label, children }, i, arr) => (
          <React.Fragment key={id}>
            <ListItem className={clsx(classes.categoryHeader, !drawerOpen && classes.hide)}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {t(label)}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, label: childLabel, icon, path }) => (
              <ListItem
                key={childId}
                button
                onClick={() => history.push(`/dashboard/${match?.params.companyId}${path}`)}
                className={clsx(classes.item, location.pathname.indexOf(path) > 0 && classes.itemActiveItem)}
              >
                <ListItemIcon
                  className={clsx(classes.itemIcon, location.pathname.indexOf(path) > 0 && classes.itemActiveItem)}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {t(childLabel)}
                </ListItemText>
              </ListItem>
            ))}
            {arr.length - 1 === i ? null : <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};
