import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequestState, Resource, useResource } from '@top-solution/utils';
import CompanyPeopleRepository from '../../api/CompanyPeopleRepository';
import { Company } from '../../entities/Company';
import { CompanyPerson } from '../../entities/CompanyPerson';
import { RootState } from '../../store/reducers';
import {
  READ_COMPANY_PEOPLE_REQUEST,
  ReadCompanyPeopleRequestAction,
  READ_COMPANY_PEOPLE_SUCCESS,
  ReadCompanyPeopleSuccessAction,
  READ_COMPANY_PEOPLE_FAILURE,
  ReadCompanyPeopleFailureAction,
  CREATE_COMPANY_PERSON_REQUEST,
  CreateCompanyPersonRequestAction,
  CREATE_COMPANY_PERSON_SUCCESS,
  CreateCompanyPersonSuccessAction,
  CREATE_COMPANY_PERSON_FAILURE,
  CreateCompanyPersonFailureAction,
  DELETE_COMPANY_PERSON_REQUEST,
  DeleteCompanyPersonRequestAction,
  DELETE_COMPANY_PERSON_SUCCESS,
  DeleteCompanyPersonSuccessAction,
  DELETE_COMPANY_PERSON_FAILURE,
  DeleteCompanyPersonFailureAction,
  UpdateCompanyPersonRequestAction,
  UpdateCompanyPersonSuccessAction,
  UpdateCompanyPersonFailureAction,
  UPDATE_COMPANY_PERSON_REQUEST,
  UPDATE_COMPANY_PERSON_SUCCESS,
  UPDATE_COMPANY_PERSON_FAILURE,
} from '../../store/reducers/companyPeople';

type UseCompanyPeople = {
  byCompany: Record<Company['id'], CompanyPerson[]>;
  readCompanyPeople: (companyId: Company['id']) => void;
  createCompanyPerson: (
    companyId: Company['id'],
    companyPerson: CreateCompanyPersonRequestAction['companyPerson']
  ) => void;
  updateCompanyPerson: (
    companyId: Company['id'],
    companyPerson: UpdateCompanyPersonRequestAction['companyPerson']
  ) => void;
  deleteCompanyPerson: (companyId: Company['id'], companyPersonId: CompanyPerson['id']) => Promise<void>;
  readCompanyPeopleRequest: RequestState;
  createCompanyPersonRequest: RequestState;
  deleteCompanyPersonRequest: RequestState;
};

export default function useCompanyPeople(): UseCompanyPeople {
  const dispatch = useDispatch();
  const { t } = useTranslation(['position']);

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      CompanyPeopleRepository.updateAuthToken(token);
    }
  }, [token]);

  const byCompany = useSelector((state: RootState) => state.companyPeople.byCompany);

  const readCompanyPeopleRequest = useSelector((state: RootState) => state.companyPeople.requests.readList);
  const createCompanyPersonRequest = useSelector((state: RootState) => state.companyPeople.requests.create);
  const deleteCompanyPersonRequest = useSelector((state: RootState) => state.companyPeople.requests.delete);

  const readCompanyPeople = useCallback(
    (companyId: string) => {
      dispatch<ReadCompanyPeopleRequestAction>({ type: READ_COMPANY_PEOPLE_REQUEST, companyId });

      const read = async () => {
        try {
          const companyPeople = await CompanyPeopleRepository.getList(companyId);

          const actionCompanyPeople = [];

          for (let i = 0; i < companyPeople.length; i++) {
            const person = companyPeople[i];

            if (person) {
              const branches = [];

              if (person.branches) {
                for (let j = 0; j < person.branches.length; j++) {
                  const branch = person.branches[j];
                  if (branch) {
                    branches.push({
                      ...branch,
                      label: t(branch.name),
                    });
                  }
                }
              }

              actionCompanyPeople.push({
                ...person,
                position: {
                  ...person.position,
                  label: t(`position:${person.position.name.substr(person.position.name.indexOf(':') + 1)}`),
                },
                branches: branches.sort((a, b) => a.name.localeCompare(b.name)),
              });
            }
          }

          dispatch<ReadCompanyPeopleSuccessAction>({
            type: READ_COMPANY_PEOPLE_SUCCESS,
            companyPeople: actionCompanyPeople,
            companyId,
          });
        } catch (error) {
          dispatch<ReadCompanyPeopleFailureAction>({ type: READ_COMPANY_PEOPLE_FAILURE, companyId, error });
        }
      };

      read();
    },
    [dispatch, t]
  );

  const createCompanyPerson = useCallback(
    (companyId: string, companyPerson: CreateCompanyPersonRequestAction['companyPerson']) => {
      dispatch<CreateCompanyPersonRequestAction>({ type: CREATE_COMPANY_PERSON_REQUEST, companyId, companyPerson });

      const create = async () => {
        try {
          const companyRes = await CompanyPeopleRepository.create(companyId, companyPerson);
          dispatch<CreateCompanyPersonSuccessAction>({ type: CREATE_COMPANY_PERSON_SUCCESS });
          await readCompanyPeople(companyId);
          return companyRes;
        } catch (error) {
          dispatch<CreateCompanyPersonFailureAction>({ type: CREATE_COMPANY_PERSON_FAILURE, error });
        }
      };

      return create();
    },
    [dispatch, readCompanyPeople]
  );

  const updateCompanyPerson = useCallback(
    (companyId: string, companyPerson: UpdateCompanyPersonRequestAction['companyPerson']) => {
      dispatch<UpdateCompanyPersonRequestAction>({ type: UPDATE_COMPANY_PERSON_REQUEST, companyId, companyPerson });

      const update = async () => {
        try {
          const companyRes = await CompanyPeopleRepository.update(companyId, companyPerson);
          dispatch<UpdateCompanyPersonSuccessAction>({ type: UPDATE_COMPANY_PERSON_SUCCESS });
          await readCompanyPeople(companyId);
          return companyRes;
        } catch (error) {
          dispatch<UpdateCompanyPersonFailureAction>({ type: UPDATE_COMPANY_PERSON_FAILURE, error });
        }
      };

      return update();
    },
    [dispatch, readCompanyPeople]
  );

  const deleteCompanyPerson = useCallback(
    (companyId: string, companyPersonId: string) => {
      dispatch<DeleteCompanyPersonRequestAction>({ type: DELETE_COMPANY_PERSON_REQUEST, companyId });

      const read = async () => {
        try {
          await CompanyPeopleRepository.delete(companyPersonId);

          dispatch<DeleteCompanyPersonSuccessAction>({
            type: DELETE_COMPANY_PERSON_SUCCESS,
            companyId,
          });
          await readCompanyPeople(companyId);
        } catch (error) {
          dispatch<DeleteCompanyPersonFailureAction>({ type: DELETE_COMPANY_PERSON_FAILURE, error });
        }
      };

      return read();
    },
    [dispatch, readCompanyPeople]
  );

  return {
    byCompany,
    readCompanyPeople,
    createCompanyPerson,
    updateCompanyPerson,
    deleteCompanyPerson,
    readCompanyPeopleRequest,
    createCompanyPersonRequest,
    deleteCompanyPersonRequest,
  };
}

export function useCompanyPeopleResource(companyId: string): Resource<CompanyPerson[]> {
  const { byCompany, readCompanyPeople, readCompanyPeopleRequest } = useCompanyPeople();

  const readCompanyWithId = useCallback(() => readCompanyPeople(companyId), [readCompanyPeople, companyId]);
  const company = useMemo(() => byCompany[companyId] || [], [byCompany, companyId]);

  const resourceName = useMemo(() => `companyPeople-${companyId}`, [companyId]);

  return useResource(resourceName, company, readCompanyWithId, readCompanyPeopleRequest);
}
