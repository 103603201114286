import { RequestError } from '@top-solution/utils';
import { Company, CompanyCreatePayload } from '../../entities/Company';
import { requestDefaultState } from '../utils';

export const READ_COMPANY_REQUEST = 'READ_COMPANY_REQUEST';
export const READ_COMPANY_SUCCESS = 'READ_COMPANY_SUCCESS';
export const READ_COMPANY_FAILURE = 'READ_COMPANY_FAILURE';

export const READ_COMPANIES_REQUEST = 'READ_COMPANIES_REQUEST';
export const READ_COMPANIES_SUCCESS = 'READ_COMPANIES_SUCCESS';
export const READ_COMPANIES_FAILURE = 'READ_COMPANIES_FAILURE';

export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

const initialState = {
  companies: {} as { [companyId: string]: Company },
  requests: {
    read: { ...requestDefaultState },
    readList: { ...requestDefaultState },
    create: { ...requestDefaultState },
    update: { ...requestDefaultState },
    delete: { ...requestDefaultState },
  },
};

export type CompaniesState = typeof initialState;

export interface ReadCompanyRequestAction {
  type: typeof READ_COMPANY_REQUEST;
}
export interface ReadCompanySuccessAction {
  type: typeof READ_COMPANY_SUCCESS;
  company: Company;
}
export interface ReadCompanyFailureAction {
  type: typeof READ_COMPANY_FAILURE;
  error: RequestError;
}

export interface ReadCompaniesRequestAction {
  type: typeof READ_COMPANIES_REQUEST;
}
export interface ReadCompaniesSuccessAction {
  type: typeof READ_COMPANIES_SUCCESS;
  companies: Array<Company>;
}
export interface ReadCompaniesFailureAction {
  type: typeof READ_COMPANIES_FAILURE;
  error: RequestError;
}

export interface CreateCompanyRequestAction {
  type: typeof CREATE_COMPANY_REQUEST;
  company: CompanyCreatePayload;
}
export interface CreateCompanySuccessAction {
  type: typeof CREATE_COMPANY_SUCCESS;
}
export interface CreateCompanyFailureAction {
  type: typeof CREATE_COMPANY_FAILURE;
  error: RequestError;
}

export interface UpdateCompanyRequestAction {
  type: typeof UPDATE_COMPANY_REQUEST;
  company: CompanyCreatePayload;
}
export interface UpdateCompanySuccessAction {
  type: typeof UPDATE_COMPANY_SUCCESS;
  companyId: string;
  company: Company;
}
export interface UpdateCompanyFailureAction {
  type: typeof UPDATE_COMPANY_FAILURE;
  error: RequestError;
}

export interface DeleteCompanyRequestAction {
  type: typeof DELETE_COMPANY_REQUEST;
  companyId: string;
}
export interface DeleteCompanySuccessAction {
  type: typeof DELETE_COMPANY_SUCCESS;
  companyId: string;
}
export interface DeleteCompanyFailureAction {
  type: typeof DELETE_COMPANY_FAILURE;
  error: RequestError;
}

type ActionType =
  | ReadCompanyRequestAction
  | ReadCompanySuccessAction
  | ReadCompanyFailureAction
  | ReadCompaniesRequestAction
  | ReadCompaniesSuccessAction
  | ReadCompaniesFailureAction
  | CreateCompanyRequestAction
  | CreateCompanySuccessAction
  | CreateCompanyFailureAction
  | UpdateCompanyRequestAction
  | UpdateCompanySuccessAction
  | UpdateCompanyFailureAction
  | DeleteCompanyRequestAction
  | DeleteCompanySuccessAction
  | DeleteCompanyFailureAction;

export default function companies(state = initialState, action: ActionType): CompaniesState {
  switch (action.type) {
    case READ_COMPANY_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          read: {
            ...state.requests.read,
            inProgress: true,
          },
        },
      };
    case READ_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.company.id]: action.company,
        },
        requests: {
          ...state.requests,
          read: {
            ...state.requests.read,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case READ_COMPANY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          read: {
            ...state.requests.read,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case READ_COMPANIES_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.companies.reduce(
          (companies, company) => ({
            ...companies,
            [company.id]: company,
          }),
          {}
        ),
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case READ_COMPANIES_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case CREATE_COMPANY_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: true,
          },
        },
      };
    case CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case CREATE_COMPANY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          create: {
            ...state.requests.create,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: true,
          },
        },
      };
    case UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: {
          ...companies,
          [action.companyId]: action.company,
        },
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case UPDATE_COMPANY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          update: {
            ...state.requests.update,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    case DELETE_COMPANY_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: true,
          },
        },
      };
    case DELETE_COMPANY_SUCCESS: {
      const newCompanies = { ...state.companies };
      delete newCompanies[action.companyId];

      return {
        ...state,
        companies: newCompanies,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case DELETE_COMPANY_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          delete: {
            ...state.requests.delete,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
