import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@top-solution/mui-inputs';
import { Branch } from '../../../../entities/Branch';
import { CompanyPerson } from '../../../../entities/CompanyPerson';
import { useBranchesResource } from '../../../../hooks/store/useBranches';
import useCompanies from '../../../../hooks/store/useCompanies';
import { usePositionsResource } from '../../../../hooks/store/usePositions';
import useAddPersonForm from '../../../../hooks/useAddPersonForm';
import LoaderView from '../../../common/LoaderView';
import ProgressButton from '../../../common/ProgressButton';

export type PersonDialogCommonProps = {
  companyId: string;
  person?: CompanyPerson;
  onAddPerson: (person: Omit<CompanyPerson, 'id'>) => void;
  departments: Array<string>;
};

type PersonDialogProps = PersonDialogCommonProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
  editMode: boolean;
};

const useStyles = makeStyles(() => ({
  datePicker: {
    maxWidth: '100%',
  },
}));

export default function PersonDialog(props: PersonDialogProps): JSX.Element {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { updateCompanyRequest, createCompanyRequest } = useCompanies();
  const [branches] = useBranchesResource(props.companyId);
  const [positions] = usePositionsResource();

  const { loadingCondition, handleSubmit, formErrors, formValues, setFormValues, resetForm } = useAddPersonForm(
    props.companyId,
    () => {
      // console.log(formValues);

      setOpen(false);
      resetForm();

      if (formValues.hired_at !== null) {
        props.onAddPerson(formValues as Omit<CompanyPerson, 'id'>);
      }
    },
    props.person
  );

  const handleClose = () => {
    setOpen(false);
  };

  const positionsMenuItems = Object.values(positions).map((position) => (
    <MenuItem key={position.name} value={position.name}>
      {position.label}
    </MenuItem>
  ));

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" maxWidth="md" open={open}>
      <DialogTitle id="simple-dialog-title">{t('peopleManagement:addDialogTitle')}</DialogTitle>
      <DialogContent>
        <p>{t('peopleManagement:addDialogDescription')}</p>
        <LoaderView condition={loadingCondition} minHeight={288}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('person:firstName')}
                variant="outlined"
                value={formValues.first_name}
                onChange={(e) => setFormValues({ ...formValues, first_name: e.target.value })}
                error={Boolean(formErrors.first_name)}
                helperText={formErrors.first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('person:lastName')}
                variant="outlined"
                value={formValues.last_name}
                onChange={(e) => setFormValues({ ...formValues, last_name: e.target.value })}
                error={Boolean(formErrors.last_name)}
                helperText={formErrors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('person:email')}
                variant="outlined"
                value={formValues.email}
                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                error={Boolean(formErrors.email)}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  multiple
                  options={Object.values(branches)}
                  getOptionLabel={(option: Branch) => option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('person:branch')}
                      error={Boolean(formErrors.branches)}
                    />
                  )}
                  value={formValues.branches}
                  onChange={(_, branches) => setFormValues({ ...formValues, branches })}
                  getOptionSelected={(option, value) => option.id === value.id}
                />
                {formErrors.branches && <FormHelperText error>{formErrors.branches}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="position-select-label" error={Boolean(formErrors.position)}>
                  {t('person:position')}
                </InputLabel>
                <Select
                  labelId="position-select-label"
                  id="demo-simple-select-outlined"
                  value={formValues.position?.name}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      position: positions[e.target.value as string] || null,
                    })
                  }
                  label={t('person:position')}
                  fullWidth
                  error={Boolean(formErrors.position)}
                >
                  {positionsMenuItems}
                </Select>
                {formErrors.position && <FormHelperText error>{formErrors.position}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  multiple
                  options={props.departments}
                  freeSolo
                  value={formValues.title?.split(',') || []}
                  onChange={(e, value) =>
                    setFormValues({ ...formValues, title: value.length ? value.join(',') : undefined })
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip key={option} label={option} {...getTagProps({ index })} />)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('person:departments')}
                      error={Boolean(formErrors.title)}
                    />
                  )}
                />
                <FormHelperText>{t('peopleManagement:departmentHelperText')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                maxDate={new Date()}
                className={classes.datePicker}
                inputFormat="dd/MM/yyyy"
                label={t('person:hiredAt')}
                value={formValues.hired_at ? new Date(formValues.hired_at) : null}
                onChange={(value) => value && setFormValues({ ...formValues, hired_at: value?.toISOString() })}
              />
            </Grid>
          </Grid>
        </LoaderView>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('common:cancel')}</Button>
        <ProgressButton
          color="primary"
          onClick={handleSubmit}
          loading={updateCompanyRequest.inProgress || createCompanyRequest.inProgress}
        >
          {props.editMode ? t('common:update') : t('common:add')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
