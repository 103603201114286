import React from 'react';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DataTableColumn } from './types';

type TableHeaderCellProps<D> = {
  column: DataTableColumn<D>;
  sortField: keyof D | undefined;
  sortAscending: boolean;
  onClick: () => void;
};

export default function TableHeaderCell<D>(props: TableHeaderCellProps<D>): JSX.Element {
  const { column } = props;

  const tableCellProps = {
    style: {
      width: column.width ? `${column.width * 100}%` : undefined,
      cursor: column.sortable ? 'pointer' : undefined,
    },
    onClick: column.sortable ? props.onClick : undefined,
  };

  return (
    <TableCell {...tableCellProps}>
      <Box display="flex" alignItems="center">
        <TableSortLabel
          active={props.sortField === column.field}
          direction={props.sortAscending ? 'asc' : 'desc'}
          onClick={props.onClick}
        >
          {column.title}
        </TableSortLabel>
      </Box>
    </TableCell>
  );
}
