import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

type SectionCardProps = {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode | React.ReactNodeArray;
};

export default function SectionCard(props: SectionCardProps): JSX.Element {
  let titleBox = null;

  if (props.title || props.icon) {
    titleBox = (
      <Typography component="h2" variant="subtitle1" color="inherit">
        <Box display="flex" mb={1.5}>
          <Box mr={1}>{props.icon}</Box>
          {props.title}
        </Box>
      </Typography>
    );
  }

  return (
    <Box mb={4}>
      <Paper variant="outlined">
        <Box px={3} py={2}>
          {titleBox}
          {props.children}
        </Box>
      </Paper>
    </Box>
  );
}
