import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type LocalizedYupValue = {
  yup: typeof yup;
};

const ResourceContext = React.createContext<LocalizedYupValue>({
  yup,
});

export const LocalizedYupProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const { t } = useTranslation();

  const localizedYup = useMemo(() => {
    yup.setLocale({
      string: {
        min: ({ min }: { min: number }) => t('form:stringMinimum', { min }),
        max: ({ max }: { max: number }) => t('form:stringMaximum', { max }),
      },
    });
    return yup;
  }, [t]);
  return <ResourceContext.Provider value={{ yup: localizedYup }}>{props.children}</ResourceContext.Provider>;
};

export function useLocalizedYup(): LocalizedYupValue {
  return useContext(ResourceContext);
}
