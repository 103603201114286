import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import MUIDataTable, { MUIDataTableColumnState, MUIDataTableData, MUIDataTableProps } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme, useTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import { Assessment } from '../../../../entities/Assessment';
import useAssessments from '../../../../hooks/store/useAssessments';
import useAuth from '../../../../hooks/store/useAuth';
import { useCampaignResource } from '../../../../hooks/store/useCampaigns';
import useMUIDatatableTranslations from '../../../../hooks/useMUIDatatableTranslations';
import { AssessmentStatus } from '../../../../lib/constants/assessment-status';
import { CampaignStatus } from '../../../../lib/constants/campaign-status';
import { UserRoles } from '../../../../lib/constants/user-roles';
import useConfirmDialog from '../../../common/ConfirmDialog/useConfirmDialog';
import DeleteDialog from '../../../common/DeleteDialog';
import DashboardAppLayout from '../../DashboardAppLayout';
import AddAssessmentDialog from './AddAssessmentDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  deleteMenuItem: {
    color: 'inherit',
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  shiftLeft: {
    marginLeft: theme.spacing(-1),
  },
}));

type TableContext = {
  rowData: Array<string>;
};

export default function PoolDetails(): JSX.Element {
  const classes = useStyles();
  const { companyId, campaignId, pollId } = useParams<{ companyId: string; pollId: string; campaignId: string }>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAssessmentId, setMenuAssessmentId] = useState<null | Assessment['id']>(null);
  const [campaignsById, campaignsReady] = useCampaignResource(companyId || '');
  const tableTranslation = useMUIDatatableTranslations();
  const { t } = useTranslation(['poolCampaigns', 'assessmentStatus', 'poll', 'assessment']);
  const { enqueueSnackbar } = useSnackbar();
  const { deleteAssessment, recallAssessor, readAssessmentList, assessmentsById, readListRequest } = useAssessments();
  const { showConfirmDialog } = useConfirmDialog();
  const { role } = useAuth();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    readAssessmentList(campaignId);
  }, [campaignId, readAssessmentList]);

  const assessments = useMemo(() => Object.values(assessmentsById), [assessmentsById]);

  const handleActionsClick = useCallback(
    (assessmentId: Assessment['id']) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setMenuAssessmentId(assessmentId);
    },
    []
  );

  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAssessmentRecall = (menuAssessmentId: string) => {
    setAnchorEl(null);
    showConfirmDialog(
      {
        title: t('poolDetails:recallConfirmTitle', {
          name: `${assessmentsById[menuAssessmentId]?.assessor.last_name}`,
        }),
        message: t('poolDetails:recallConfirmDescription'),
        confirmButtonProps: {
          color: 'secondary',
        },
      },
      async () => {
        try {
          await recallAssessor(campaignId, assessmentsById[menuAssessmentId]?.assessor.id || '');
          enqueueSnackbar(t('poolDetails:recallStarted'), { variant: 'success' });
        } catch (error) {
          if (error.status === 412) {
            enqueueSnackbar(t('poolDetails:errorAssessmentNotInProgress'), { variant: 'error' });
          } else {
            enqueueSnackbar(t('poolDetails:errorRecall'), { variant: 'error' });
          }
        }
      }
    );
  };

  const formatCSV = (
    buildHead: (columns: MUIDataTableColumnState[]) => string,
    buildBody: (data: MUIDataTableData) => string,
    columns: MUIDataTableColumnState[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Array<any>
  ) => {
    const filteredColumns = columns.filter((c) => c.name !== 'id' && c.name !== 'actions');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const convertedData = data.reduce((acc: any, curr: { data: any[] }) => {
      const parsedData = curr.data.slice(0, curr.data.length - 2).map((e) => {
        if (typeof e === 'object') {
          return `${e['last_name']} ${e['first_name']}`;
        }
        if (e && e.indexOf('assessmentStatus:') > -1) {
          return t(`assessmentStatus:${e.replace('assessmentStatus:', '')}`);
        }
        return e;
      });
      curr.data = parsedData;

      return [...acc, curr];
    }, [] as MUIDataTableData[]);

    return buildHead(filteredColumns) + buildBody(convertedData);
  };

  const columns = [
    {
      name: 'assessee',
      label: t('assessment:assessee'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value: Assessment['assessee']) => `${value.last_name} ${value.first_name}`,
      },
    },
    {
      name: 'assessor',
      label: t('assessment:assessor'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: Assessment['assessor']) => `${value.last_name} ${value.first_name}`,
      },
    },
    {
      name: 'started_at',
      label: t('assessment:startAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Assessment['started_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'updated_at',
      label: t('assessment:updatedAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Assessment['updated_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'completed_at',
      label: t('assessment:endAt'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Assessment['completed_at']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'last_recall',
      label: t('assessment:last_recall'),
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: Assessment['last_recall']) => DateTime.fromISO(value).toISODate(),
      },
    },
    {
      name: 'status',
      label: t('assessment:status'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value: Assessment['status']) =>
          t(`assessmentStatus:${value.replace('assessmentStatus:', '')}`),
      },
    },
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
      },
    },
    ...(role === UserRoles.ADMIN
      ? [
          {
            name: 'actions',
            label: t('actions'),
            options: {
              filter: false,
              sort: false,
              customBodyRender: function OpenButton(_: unknown, context: TableContext) {
                const assessmentId = context.rowData[7];
                if (typeof assessmentId === 'string') {
                  return (
                    <IconButton
                      className={classes.shiftLeft}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleActionsClick(assessmentId)}
                    >
                      <DotsVertical />
                    </IconButton>
                  );
                }
              },
            },
          },
        ]
      : []),
  ];

  const options: MUIDataTableProps['options'] = {
    filterType: 'dropdown',
    selectableRows: 'none',
    download: true,
    elevation: 1,
    print: false,
    viewColumns: false,
    textLabels: tableTranslation,
    onDownload: formatCSV,
  };

  // Force muidatatable toolbar to have custom gutters
  const defaultTheme = useTheme();
  const theme = createMuiTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          gutters: {
            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          },
        },
      },
    },
  });

  const handleDeleteAssessment = useCallback(async () => {
    if (menuAssessmentId) {
      await deleteAssessment(menuAssessmentId);
    }
  }, [deleteAssessment, menuAssessmentId]);

  return (
    <DashboardAppLayout>
      <Container maxWidth="md" className={classes.container}>
        <Box mt={0} mb={3} display="flex" justifyContent="space-between">
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <Link
              color="inherit"
              href="#"
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                history.push({
                  pathname: generatePath(match.path.replace('/:campaignId', ''), match.params),
                });
              }}
            >
              {t(`${pollId}`)}
            </Link>
            <Typography color="textPrimary">{campaignsReady ? campaignsById[campaignId]?.name : null}</Typography>
          </Breadcrumbs>
          <Button
            color="primary"
            onClick={() => {
              history.push({
                pathname: generatePath(match.path + '/reports', { ...match.params }),
              });
            }}
          >
            {t('poolDetails:seeReports')}
          </Button>
        </Box>
        <Box mt={0} mb={3} display="flex" justifyContent="space-between">
          <Typography component="h4" variant="h4" color="inherit">
            {campaignsReady ? campaignsById[campaignId]?.name : null}
          </Typography>
          {(role === UserRoles.ADMIN && campaignsById[campaignId]?.status) === CampaignStatus.READY ? (
            <AddAssessmentDialog />
          ) : null}
        </Box>
        {readListRequest.inProgress ? (
          <Paper className={classes.paper}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh">
              <CircularProgress />
            </Box>
          </Paper>
        ) : (
          <Box width="100%">
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                title={t('poolDetails:compilations')}
                data={assessments}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </Box>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleActionsMenuClose}
        >
          {menuAssessmentId &&
          (assessmentsById[menuAssessmentId]?.status === AssessmentStatus.IN_PROGRESS ||
            assessmentsById[menuAssessmentId]?.status === AssessmentStatus.READY) ? (
            <MenuItem
              className={classes.deleteMenuItem}
              onClick={() => {
                handleAssessmentRecall(menuAssessmentId);
              }}
            >
              {t('poolDetails:recall')}
            </MenuItem>
          ) : null}
          {menuAssessmentId && assessmentsById[menuAssessmentId]?.status === AssessmentStatus.DRAFT ? (
            <DeleteDialog
              onDelete={handleDeleteAssessment}
              deleteListItems={[
                <Typography key="0" variant="body1" color="textPrimary">
                  {assessmentsById[menuAssessmentId]?.assessor.last_name}
                  {' - '}
                  {assessmentsById[menuAssessmentId]?.assessee.last_name}
                </Typography>,
              ]}
              labels={{
                dialogTitle: t('poolDetails:deleteAssessment:dialogTitle'),
                dialogMessage: t('poolDetails:deleteAssessment:dialogMessage'),
              }}
              openComponent={(onClick) => (
                <MenuItem
                  className={classes.deleteMenuItem}
                  onClick={() => {
                    onClick();
                    handleActionsMenuClose();
                  }}
                >
                  {t('common:delete')}
                </MenuItem>
              )}
            />
          ) : null}
        </Menu>
      </Container>
    </DashboardAppLayout>
  );
}
