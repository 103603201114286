import { RequestError } from '@top-solution/utils';
import { Branch } from '../../entities/Branch';
import { requestDefaultState } from '../utils';

export const READ_BRANCHES_REQUEST = 'READ_BRANCHES_REQUEST';
export const READ_BRANCHES_SUCCESS = 'READ_BRANCHES_SUCCESS';
export const READ_BRANCHES_FAILURE = 'READ_BRANCHES_FAILURE';
const initialState = {
  branches: {} as { [branchId: string]: Branch },
  byCompany: {} as { [companyId: string]: Array<string> },
  requests: {
    readList: { ...requestDefaultState },
  },
};

export type BranchesState = typeof initialState;

export interface ReadBranchesRequestAction {
  type: typeof READ_BRANCHES_REQUEST;
}
export interface ReadBranchesSuccessAction {
  type: typeof READ_BRANCHES_SUCCESS;
  branches: Array<Branch>;
  companyId: string;
}
export interface ReadBranchesFailureAction {
  type: typeof READ_BRANCHES_FAILURE;
  error: RequestError;
}

type ActionType = ReadBranchesRequestAction | ReadBranchesSuccessAction | ReadBranchesFailureAction;

export default function companies(state = initialState, action: ActionType): BranchesState {
  switch (action.type) {
    case READ_BRANCHES_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: true,
          },
        },
      };
    case READ_BRANCHES_SUCCESS: {
      return {
        ...state,
        branches: action.branches.reduce(
          (branches, branch) => ({
            ...branches,
            [branch.id]: branch,
          }),
          { ...state.branches }
        ),
        byCompany: {
          ...state.byCompany,
          [action.companyId]: action.branches.map((branch) => branch.id),
        },
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: null,
          },
        },
      };
    }
    case READ_BRANCHES_FAILURE: {
      return {
        ...state,
        requests: {
          ...state.requests,
          readList: {
            ...state.requests.readList,
            inProgress: false,
            lastUpdate: Date.now(),
            error: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
}
