import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RequestState, Resource, useResource } from '@top-solution/utils';
import CompanySizesRepository from '../../api/CompanySizesRepository';
import { Company } from '../../entities/Company';
import { CompanySize } from '../../entities/CompanySize';
import { RootState } from '../../store/reducers';
import {
  ReadCompaniesRequestAction,
  READ_COMPANY_SIZES_REQUEST,
  ReadCompaniesSuccessAction,
  READ_COMPANY_SIZES_SUCCESS,
  ReadCompaniesFailureAction,
  READ_COMPANY_SIZES_FAILURE,
} from '../../store/reducers/companySizes';

type UseCompanySizes = {
  companySizes: Record<Company['id'], CompanySize>;
  readCompanySizesList: () => void;
  readCompanySizesListRequest: RequestState;
};

export default function useCompanySizes(): UseCompanySizes {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      CompanySizesRepository.updateAuthToken(token);
    }
  }, [token]);

  const companySizes = useSelector((state: RootState) => state.companySizes.companySizes);

  const readCompanySizesListRequest = useSelector((state: RootState) => state.companySizes.requests.readList);

  const readCompanySizesList = useCallback(() => {
    dispatch<ReadCompaniesRequestAction>({ type: READ_COMPANY_SIZES_REQUEST });

    const read = async () => {
      try {
        const companySizes = await CompanySizesRepository.getList();

        dispatch<ReadCompaniesSuccessAction>({
          type: READ_COMPANY_SIZES_SUCCESS,
          companySizes,
        });
      } catch (error) {
        dispatch<ReadCompaniesFailureAction>({ type: READ_COMPANY_SIZES_FAILURE, error });
      }
    };

    read();
  }, [dispatch]);

  return {
    companySizes,
    readCompanySizesList,
    readCompanySizesListRequest,
  };
}

export function useCompanySizesResource(): Resource<Record<Company['id'], CompanySize>> {
  const { companySizes, readCompanySizesList, readCompanySizesListRequest } = useCompanySizes();
  return useResource('companySizes', companySizes, readCompanySizesList, readCompanySizesListRequest);
}
