import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import useAuth from '../../hooks/store/useAuth';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

const useUsernameStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  avatar: {
    marginBottom: theme.spacing(2),
  },
}));

type MenuItemProps = {
  onMenuClose: () => void;
};

const UsernameMenuItem = React.forwardRef(function UsernameMenuItem(
  props: MenuItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { user, isAuthenticated } = useAuth();
  const classes = useUsernameStyles();

  if (!isAuthenticated || !user) {
    return null;
  }

  return (
    <MenuItem ref={ref} button={false}>
      <Grid className={classes.root} container direction="column" justifyContent="center" alignItems="center">
        <Avatar className={classes.avatar} alt={user.name} src={user.picture} />
        {user.name}
        <Typography variant="caption">{user.email}</Typography>
      </Grid>
    </MenuItem>
  );
});

const LogoutMenuItem = (props: MenuItemProps) => {
  const { onMenuClose } = props;
  const { logout, isAuthenticated } = useAuth();
  const { t } = useTranslation(['menu']);

  const handleLogout = () => {
    logout();
    onMenuClose();
  };

  if (!isAuthenticated) {
    return null;
  }

  return <MenuItem onClick={handleLogout}>{t('menu:logout')}</MenuItem>;
};

const menuId = 'app-bar-profile-menu';

export default function ProfileMenu(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      MenuListProps={{ className: classes.root }}
    >
      <UsernameMenuItem onMenuClose={handleMenuClose} />
      <Divider />
      <LogoutMenuItem onMenuClose={handleMenuClose} />
    </Menu>
  );

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      {renderMenu}
    </>
  );
}
