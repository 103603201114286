import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DataTableColumn } from './types';

const useStyles = makeStyles(() => ({
  cell: {
    textAlign: 'center',
    fontStyle: 'italic',
  },
}));

export type DataTableRowProps<D> = {
  columns: Array<DataTableColumn<D>>;
};

export const DataTableNoResultsRow = function DataTableRow<D>(props: DataTableRowProps<D>): JSX.Element {
  const { columns } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={columns.length + 1} classes={{ root: classes.cell }}>
        {t('dataTable:noResult')}
      </TableCell>
    </TableRow>
  );
};
