import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RequestError, RequestState, Resource, useResource } from '@top-solution/utils';
import CampaignsRepository from '../../api/CampaignsRepository';
import { Campaign, CreateCampaignPayload } from '../../entities/Campaign';
import { Company } from '../../entities/Company';
import { RootState } from '../../store/reducers';
import { campaignActions } from '../../store/reducers/campaigns';

type UseCampaigns = {
  campaignsById: Record<Campaign['id'], Campaign>;
  readCampaignList: (companyId: Company['id']) => void;
  createCampaign: (campaign: CreateCampaignPayload) => Promise<Campaign | undefined>;
  deleteCampaign: (campaignId: Campaign['id']) => Promise<Campaign['id'] | undefined>;
  recallCampaign: (campaignId: Campaign['id']) => void;
  readListRequest: RequestState;
  createCampaignRequest: RequestState;
  deleteCampaignRequest: RequestState;
  recallCampaignRequest: RequestState;
};

export default function useCampaigns(): UseCampaigns {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      CampaignsRepository.updateAuthToken(token);
    }
  }, [token]);

  const campaignsById = useSelector((state: RootState) => state.campaigns.byId);

  const readListRequest = useSelector((state: RootState) => state.campaigns.requests.readList);
  const createCampaignRequest = useSelector((state: RootState) => state.campaigns.requests.create);
  const deleteCampaignRequest = useSelector((state: RootState) => state.campaigns.requests.delete);
  const recallCampaignRequest = useSelector((state: RootState) => state.campaigns.requests.recall);

  const readCampaignList = useCallback(
    async (companyId: string) => {
      dispatch(campaignActions.readCampaignListRequest());
      let campaigns: Array<Campaign>;

      try {
        campaigns = await CampaignsRepository.getList(companyId);
      } catch (error) {
        dispatch(campaignActions.readCampaignListFailure({ error: new RequestError(error) }));
        return;
      }

      dispatch(campaignActions.readCampaignListSuccess({ response: campaigns }));
    },
    [dispatch]
  );

  const createCampaign: UseCampaigns['createCampaign'] = useCallback(
    async (campaign) => {
      dispatch(campaignActions.createCampaignRequest());
      let response: Campaign;

      try {
        response = await CampaignsRepository.create(campaign);
      } catch (error) {
        dispatch(campaignActions.createCampaignFailure({ error: new RequestError(error) }));
        return;
      }

      dispatch(campaignActions.createCampaignSuccess({ response }));
      return response;
    },
    [dispatch]
  );

  const deleteCampaign: UseCampaigns['deleteCampaign'] = useCallback(
    async (campaignId) => {
      dispatch(campaignActions.deleteCampaignRequest());
      try {
        await CampaignsRepository.delete(campaignId);
      } catch (error) {
        dispatch(campaignActions.deleteCampaignFailure({ error: new RequestError(error) }));
        return;
      }

      dispatch(campaignActions.deleteCampaignSuccess({ response: campaignId }));
      return campaignId;
    },
    [dispatch]
  );

  const recallCampaign: UseCampaigns['recallCampaign'] = useCallback(
    async (campaignId) => {
      dispatch(campaignActions.recallCampaignRequest());
      try {
        await CampaignsRepository.recall(campaignId);
      } catch (error) {
        dispatch(campaignActions.recallCampaignFailure({ error: new RequestError(error) }));
        throw new RequestError(error);
      }

      dispatch(campaignActions.recallCampaignSuccess());
      return campaignId;
    },
    [dispatch]
  );

  return {
    campaignsById,
    readCampaignList,
    createCampaign,
    deleteCampaign,
    recallCampaign,
    readListRequest,
    createCampaignRequest,
    deleteCampaignRequest,
    recallCampaignRequest,
  };
}

// fetch data and save in cache
export function useCampaignResource(companyId: string): Resource<Record<Campaign['id'], Campaign>> {
  const { campaignsById, readCampaignList, readListRequest } = useCampaigns();

  const readCampaignWithCompanyId = useCallback(() => readCampaignList(companyId), [readCampaignList, companyId]);

  const resourceName = useMemo(() => `campaigns-${companyId}`, [companyId]);

  return useResource(resourceName, campaignsById, readCampaignWithCompanyId, readListRequest);
}
