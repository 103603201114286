import { setInterval } from 'timers';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { User } from '@auth0/auth0-spa-js';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import UserRepository from '../../api/UserRepository';
import Auth0 from '../../auth/Auth0';
import { UserRoles } from '../../lib/constants/user-roles';
import { RootState } from '../../store/reducers';
import { LOGIN, LoginAction, RefreshTokenAction, REFRESH_TOKEN } from '../../store/reducers/auth';

type appState = {
  targetUrl: string;
};

interface Auth0JwTpayload extends JwtPayload {
  scope: string;
}

type UseAuth = {
  user: User;
  isAuthenticated: boolean;
  role: string;
  login: ({ targetUrl }: { targetUrl: string }) => void;
  logout: () => void;
  autoRefreshToken: () => void;
};

export default function useAuth(): UseAuth {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const role = useSelector((state: RootState) => state.auth.role);

  const login = useCallback(
    (appState?: appState) => {
      const fn = async () => {
        const isAuthenticated = await Auth0.isAuthenticated();
        if (!isAuthenticated) {
          await Auth0.login({ appState });
          return;
        }
        const token = await Auth0.getAccessToken();
        const decoded = jwtDecode<Auth0JwTpayload>(token);
        UserRepository.updateAuthToken(token);
        await UserRepository.updateUser();
        const user = await Auth0.getProfile();
        let role = UserRoles.CUSTOMER;
        if (decoded.scope.indexOf('manage:haka') > -1) {
          role = UserRoles.ADMIN;
        }

        if (user) {
          dispatch<LoginAction>({
            type: LOGIN,
            user,
            role,
            token,
          });
        }
      };

      fn();
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    const fn = async () => {
      await Auth0.logout();
    };

    fn();
  }, []);

  const autoRefreshToken = useCallback(() => {
    setInterval(async () => {
      const token = await Auth0.auth0?.getTokenSilently();
      dispatch<RefreshTokenAction>({
        type: REFRESH_TOKEN,
        token,
      });
    }, 60 * 1000);
  }, [dispatch]);

  return {
    user,
    isAuthenticated,
    role,
    login,
    logout,
    autoRefreshToken,
  };
}
