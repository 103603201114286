import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import PersonDialog, { PersonDialogCommonProps } from './PersonDialog';

export default function AddPersonDialog(props: PersonDialogCommonProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button variant="contained" color="primary" disableElevation onClick={() => setOpen(true)}>
        {t('peopleManagement:addDialogTitle')}
      </Button>
      <PersonDialog open={open} setOpen={setOpen} editMode={false} {...props} />
    </>
  );
}
