import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProgressButton from '../ProgressButton';
import { ConfirmDialogResult, ConfirmDialogParams } from './ConfirmDialogTypes';

export type ConfirmDialogProps = ConfirmDialogParams & {
  open: boolean;
  loading: boolean;
  onClose: ((result: ConfirmDialogResult) => void) | null;
};

export default function ConfirmDialog(props: ConfirmDialogProps): JSX.Element {
  const { t } = useTranslation();

  const handleCancel = () => {
    props.onClose && props.onClose('Cancel');
  };

  const handleConfirm = () => {
    props.onClose && props.onClose('Confirm');
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} autoFocus {...props.cancelButtonProps} disabled={props.loading}>
          {props.cancelButtonText || t('common:cancel')}
        </Button>
        <ProgressButton onClick={handleConfirm} color="primary" {...props.confirmButtonProps} loading={props.loading}>
          {props.confirmButtonText || t('common:confirm')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}
