import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import add from 'date-fns/add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DateRangePicker, ProgressButton, QtyInput, TextField } from '@top-solution/mui-inputs';
import { useForm } from '@top-solution/use-form';
import { Campaign } from '../../../../entities/Campaign';
import useCampaigns from '../../../../hooks/store/useCampaigns';
import { useLocalizedYup } from '../../../../hooks/useLocalizedYup';

const AddDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    overflow: 'visible',
    minWidth: theme.breakpoints.values.sm,
  },
}));

const AddDialogContent = styled(DialogContent)(() => ({
  overflow: 'visible',
}));

const FormItem = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 2),
}));

const ScaleFormLabel = styled(FormLabel)(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(1, 0, 2),
}));

const ScaleLabel = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 2, 3),
  '&:first-child': {
    marginLeft: 0,
  },
}));

type AddForm = Pick<Campaign, 'name' | 'description'> & {
  range: [Date, Date];
  scaleMin: number;
  scaleMax: number;
};

const formInitialValues = {
  name: '',
  description: '',
  range: [add(new Date(), { days: 1 }), add(new Date(), { weeks: 1 })],
  scaleMin: 0,
  scaleMax: 5,
} as AddForm;

export default function AddCampaignDialog(): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { yup } = useLocalizedYup();
  const { pollId, companyId } = useParams() as { pollId: string | undefined; companyId: string | undefined };

  const { createCampaign, createCampaignRequest } = useCampaigns();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().min(2).max(128),
        description: yup.string().min(2).max(512),
      }),
    [yup]
  );

  const { form, reset, setValue, setTouched, onSubmit } = useForm<AddForm>({
    initialValues: formInitialValues,
    schema,
    handleSubmit,
  });

  async function handleSubmit(values: AddForm) {
    if (!companyId || !pollId) {
      return;
    }

    const campaign = await createCampaign({
      name: values.name,
      description: values.description,
      start_at: values.range[0].toISOString(),
      end_at: values.range[1].toISOString(),
      company_id: companyId,
      poll_id: `poll:${pollId}`,
      scale: {
        min: values.scaleMin,
        max: values.scaleMax,
      },
    });

    if (campaign) {
      reset();
      setOpen(false);
    }
  }

  const handleNameChange = useCallback(
    (value) => {
      setValue(value, 'name');
      setTouched('name');
    },
    [setTouched, setValue]
  );

  const handleDescriptionChange = useCallback(
    (value) => {
      setValue(value, 'description');
      setTouched('description');
    },
    [setTouched, setValue]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleDateRangePickerChange = useCallback(
    (value) => {
      setValue(value, 'range');
    },
    [setValue]
  );

  const handleScaleMinChange = useCallback(
    (value) => {
      setValue(value, 'scaleMin');
    },
    [setValue]
  );

  const handleScaleMaxChange = useCallback(
    (value) => {
      setValue(value, 'scaleMax');
    },
    [setValue]
  );

  const today = useMemo(() => new Date(), []);

  return (
    <>
      <Button color="primary" variant="contained" disableElevation onClick={handleClickOpen}>
        {t('campaign:addCampaign')}
      </Button>
      <AddDialog open={open} onClose={handleClose}>
        <DialogTitle>{'Aggiungi campagna'}</DialogTitle>
        <AddDialogContent>
          <FormItem>
            <TextField
              label={t('campaign:name')}
              type="text"
              name="name"
              fullWidth
              autoFocus
              value={form.values.name}
              onChange={handleNameChange}
              error={form.errors.name && form.touched.name}
              helperText={form.touched.name ? form.errors.name?.message : ''}
            />
          </FormItem>
          <FormItem>
            <TextField
              label={t('campaign:description')}
              type="text"
              name="description"
              fullWidth
              multiline
              value={form.values.description}
              onChange={handleDescriptionChange}
              error={form.errors.description && form.touched.description}
              helperText={form.touched.description ? form.errors.description?.message : ''}
            />
          </FormItem>
          <FormItem>
            <ScaleFormLabel>{t('campaign:scale')}</ScaleFormLabel>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <ScaleLabel variant="body1" display="block">
                {t('common:from')}
              </ScaleLabel>
              <QtyInput
                name="scaleMin"
                value={form.values.scaleMin}
                onChange={handleScaleMinChange}
                max={form.values.scaleMax - 1}
              />
              <ScaleLabel variant="body1" display="block">
                {t('common:to')}
              </ScaleLabel>
              <QtyInput
                name="scaleMax"
                value={form.values.scaleMax}
                onChange={handleScaleMaxChange}
                min={form.values.scaleMin + 1}
              />
            </Box>
          </FormItem>
          <FormItem>
            <DateRangePicker
              name="range"
              value={form.values.range}
              onChange={handleDateRangePickerChange}
              onBlur={() => setTouched('range')}
              labels={{
                start: t('campaign:start'),
                end: t('campaign:end'),
                middle: t('common:to'),
              }}
              inputFormat={'dd/MM/yyyy'}
              maxDate={add(today, { years: 5 })}
              minDate={add(today, { days: 1 })}
              inputsWidth={220}
            />
          </FormItem>
        </AddDialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t('common:cancel')}
          </Button>
          <ProgressButton
            inProgress={createCampaignRequest.inProgress}
            onClick={onSubmit}
            disabled={form.isPristine || !form.isValid}
            color="primary"
          >
            {t('common:add')}
          </ProgressButton>
        </DialogActions>
      </AddDialog>
    </>
  );
}
