import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DataTableRowRenderer, DataTableColumn, DataTableActionColumn } from './types';

const useStyles = makeStyles((theme) => ({
  actionColumnCell: {
    padding: `0 ${theme.spacing(1)}px`,
    textAlign: 'right',
  },
  cell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export type DataTableRowProps<D> = {
  columns: Array<DataTableColumn<D>>;
  rowRenderer: DataTableRowRenderer<D>;
  actionColumn?: DataTableActionColumn<D>;
  row: D;
};

export const DataTableRow = function DataTableRow<D>(props: DataTableRowProps<D>): JSX.Element {
  const { columns, actionColumn, row } = props;
  const cells = props.rowRenderer(row);
  const classes = useStyles();

  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell key={`${column.field}`} classes={{ root: classes.cell }}>
          {cells[column.field]}
        </TableCell>
      ))}
      {actionColumn && <TableCell className={classes.actionColumnCell}>{actionColumn(row)}</TableCell>}
    </TableRow>
  );
};
