import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Table, { TableProps } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableHeaderCell from './DataTableHeaderCell';
import { DataTableNoResultsRow } from './DataTableNoResultsRow';
import { DataTableRow } from './DataTableRow';
import {
  DataTableColumn,
  DataTableRecord,
  DataTableRowRenderer,
  DataTableActionColumn,
  DataTableSearchQueries,
} from './types';
import { FILTER_ALL } from './utils';

const PAGE_SIZE_LS_KEY = 'page-size';
const initialPageSize = localStorage.getItem(PAGE_SIZE_LS_KEY);

const useStyles = makeStyles((theme) => ({
  actionColumnCell: {
    padding: `0 ${theme.spacing(1)}px`,
    textAlign: 'right',
  },
  dataTableContainer: {
    overflowX: 'auto',
  },
  dataTable: {
    minWidth: 1000,
    overflowX: 'hidden',
  },
}));

interface DataTableProps<D extends DataTableRecord> extends TableProps {
  title?: string;
  columns: Array<DataTableColumn<D>>;
  idAccessor: keyof D;
  rows: Array<D>;
  rowRenderer: DataTableRowRenderer<D>;
  actionColumn?: DataTableActionColumn<D>;
  headerActions?: React.ReactNode | React.ReactNodeArray;
  filters: Array<string>;
  filtersComponents: (setSearchQuery: (key: string, value: string) => void) => Array<React.ReactNode>;
  filterFunction: (queries: DataTableSearchQueries, row: D) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DataTable<D extends DataTableRecord>(props: DataTableProps<D>): JSX.Element {
  const classes = useStyles();
  const {
    actionColumn,
    columns,
    filterFunction,
    filtersComponents,
    headerActions,
    idAccessor,
    rows,
    rowRenderer,
    title,
    ...otherProps
  } = props;
  const [page, setPage] = React.useState(0);
  const [searchQueries, setSearchQueries] = useState<DataTableSearchQueries>({});
  const [rowsPerPage, setRowsPerPage] = React.useState(initialPageSize ? parseInt(initialPageSize, 10) : 10);
  const [sortField, setSortField] = useState(columns[0]?.field);
  const [sortAscending, setSortAscending] = useState(true);

  const sortColumn = React.useMemo(() => columns.find((column) => column.field === sortField), [columns, sortField]);

  const setSearchQuery = debounce(
    useCallback(
      (key: string, value: string) => {
        setSearchQueries({
          ...searchQueries,
          [key]: value === FILTER_ALL ? null : value,
        });
        setPage(0);
      },
      [searchQueries, setSearchQueries]
    ),
    250
  );

  const filteredRows = React.useMemo(() => rows.filter((row) => filterFunction(searchQueries, row)), [
    filterFunction,
    rows,
    searchQueries,
  ]);

  const sortedRows = React.useMemo(() => {
    const filtered = [...filteredRows];
    filtered.sort((a, b) => {
      const direction = sortAscending ? 1 : -1;

      if (sortColumn?.sortFunction) {
        return sortColumn.sortFunction(a, b) * direction;
      }

      if (!sortField) {
        return 1;
      }

      return `${a[sortField]}`.localeCompare(`${b[sortField]}`) * direction;
    });
    return filtered;
  }, [filteredRows, sortAscending, sortColumn, sortField]);

  const pagedRows = React.useMemo(() => sortedRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage), [
    page,
    rowsPerPage,
    sortedRows,
  ]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setRowsPerPage(pageSize);
    setPage(0);
    localStorage.setItem(PAGE_SIZE_LS_KEY, event.target.value);
  };

  const rowsElements =
    pagedRows.length > 0 ? (
      pagedRows.map((row) => (
        <DataTableRow
          key={row[idAccessor]}
          actionColumn={actionColumn}
          columns={columns}
          row={row}
          rowRenderer={rowRenderer}
        />
      ))
    ) : (
      <DataTableNoResultsRow columns={columns} />
    );

  return (
    <div className={classes.dataTableContainer}>
      <div className={classes.dataTable}>
        {title && (
          <Typography component="h4" variant="h6">
            {title}
          </Typography>
        )}
        <Box display="flex" alignItems="center">
          <Grid container spacing={2}>
            {filtersComponents(setSearchQuery).map((component, i) => (
              <Grid key={i} item xs>
                <Box display="flex" alignItems="flex-end" height="100%">
                  {component}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box flexShrink={0} ml={2}>
            {headerActions}
          </Box>
        </Box>
        <Table {...otherProps} style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableHeaderCell
                  key={`${column.field}`}
                  column={column}
                  sortField={sortField}
                  sortAscending={sortAscending}
                  onClick={() => {
                    if (sortField === column.field) {
                      setSortAscending(!sortAscending);
                    } else {
                      setSortField(column.field);
                      setSortAscending(true);
                    }
                  }}
                />
              ))}
              {actionColumn && <TableCell className={classes.actionColumnCell} style={{ width: '56px' }} />}
            </TableRow>
          </TableHead>
          <TableBody>{rowsElements}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                colSpan={columns.length}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}
