import { useTranslation } from 'react-i18next';
import { MUIDataTableTextLabels } from 'mui-datatables';

export default function useMUIDatatableTranslations(): Partial<MUIDataTableTextLabels> | undefined {
  const { t } = useTranslation(['table']);

  return {
    body: {
      noMatch: t('noMatch'),
      toolTip: t('toolTip'),
      columnHeaderTooltip: (column) => `${t('columnHeaderTooltip')} ${column.label}`,
    },
    pagination: {
      next: t('next'),
      previous: t('previous'),
      rowsPerPage: t('rowsPerPage'),
      displayRows: t('displayRows'),
    },
    toolbar: {
      search: t('search'),
      downloadCsv: t('downloadCsv'),
      print: t('print'),
      viewColumns: t('viewColumns'),
      filterTable: t('filterTable'),
    },
    filter: {
      all: t('all'),
      title: t('filterTitle'),
      reset: t('reset'),
    },
    viewColumns: {
      title: t('showColumnTitle'),
      titleAria: t('titleAria'),
    },
    selectedRows: {
      text: t('text'),
      delete: t('delete'),
      deleteAria: t('deleteAria'),
    },
  };
}
