import { User } from '@auth0/auth0-spa-js';

export const LOGIN = 'LOGIN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

const initialState = {
  isAuthenticated: false as boolean,
  user: {} as User,
  role: '',
  token: '',
};

export type AuthState = typeof initialState;

export interface LoginAction {
  type: typeof LOGIN;
  user: User;
  role: string;
  token: string;
}

export interface RefreshTokenAction {
  type: typeof REFRESH_TOKEN;
  token: string;
}

type ActionType = LoginAction | RefreshTokenAction;

export default function auth(state = initialState, action: ActionType): AuthState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
        role: action.role,
        token: action.token,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
}
