import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useCompanyForm, { CompanyFormForm } from '../../hooks/useCompanyForm';
import LoaderView from '../common/LoaderView';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formGrid: {
    height: '100%',
  },
  buttonsGridItem: {
    marginTop: 'auto',
  },
}));

type CompanyFormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>, form: CompanyFormForm) => void;
  onReset: (event: React.FormEvent<HTMLFormElement>) => void;
  title?: string;
};

export default function CompanyForm(props: CompanyFormProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(['company', 'errors']);

  const {
    loadingCondition,
    handleSubmit,
    formErrors,
    formValues,
    setFormValues,
    companySizes,
    companyMarkets,
  } = useCompanyForm(props.onSubmit);

  return (
    <div className={classes.root}>
      <LoaderView condition={loadingCondition} minHeight={400}>
        <Box display="flex" minHeight={400}>
          <form onSubmit={handleSubmit} onReset={props.onReset} autoComplete="off">
            <Grid className={classes.formGrid} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="companyName"
                  defaultValue=""
                  variant={'outlined'}
                  fullWidth
                  error={Boolean(formErrors.name)}
                  helperText={formErrors.name}
                  autoComplete="none"
                  label={`${t('company:name')} *`}
                  onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  name="companySize"
                  defaultValue=""
                  variant={'outlined'}
                  fullWidth
                  error={Boolean(formErrors.company_size)}
                  helperText={formErrors.company_size}
                  autoComplete="none"
                  select
                  label={`${t('company:size')} *`}
                  onChange={(e) => setFormValues({ ...formValues, company_size: e.target.value })}
                >
                  {Object.values(companySizes).map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {t(name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  name="companyMarket"
                  defaultValue=""
                  variant={'outlined'}
                  fullWidth
                  error={Boolean(formErrors.company_market)}
                  helperText={formErrors.company_market}
                  autoComplete="none"
                  select
                  label={`${t('company:market')} *`}
                  onChange={(e) => setFormValues({ ...formValues, company_market: e.target.value })}
                >
                  {Object.values(companyMarkets).map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {t(name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid className={classes.buttonsGridItem} item xs={12}>
                <Box mt={4} mr={1} display="flex" width={1}>
                  <Box ml={1} mr={'auto'}>
                    <Button variant="contained" disableElevation type="reset">
                      {t('common:cancel')}
                    </Button>
                  </Box>
                  <Button variant="contained" disableElevation color="primary" type="submit">
                    {t('common:add')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </LoaderView>
    </div>
  );
}
