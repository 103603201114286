import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chartjs from 'chart.js';
import Box from '@material-ui/core/Box';
import { Campaign } from '../../../../entities/Campaign';

type CampaignDetailRowProps = {
  assessment: Campaign['assessment_status'];
};

const PieChart = (props: CampaignDetailRowProps) => {
  const { assessment } = props;
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const [, setChartInstance] = useState<Chartjs | null>(null);
  const { t } = useTranslation(['campaignDetailRow']);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const orderedAssessmentStatus = [
        assessment.draft,
        assessment.ready,
        assessment.in_progress,
        assessment.closed,
        assessment.overdue,
      ];
      const newChartInstance = new Chartjs(chartContainer.current, {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: orderedAssessmentStatus,
              backgroundColor: ['#bdbdbd', '#03a9f4', '#ffeb3b', '#4caf50', '#f44336'],
            },
          ],
          labels: [
            `${t('draft')}: ${assessment.draft}`,
            `${t('ready')}: ${assessment.ready}`,
            `${t('in_progress')}: ${assessment.in_progress}`,
            `${t('closed')}: ${assessment.closed}`,
            `${t('overdue')}: ${assessment.overdue}`,
          ],
        },
        options: {
          tooltips: {
            callbacks: {
              label: function (chartTooltipItem, chartData) {
                const label =
                  chartData.labels && chartTooltipItem.index ? chartData.labels[chartTooltipItem.index] : '';
                return label;
              },
            },
          },
          legend: {
            display: true,
            position: 'right',
            labels: {
              // This more specific font property overrides the global property
              boxWidth: 30,
              font: {
                family: 'Roboto',
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
        } as Chart.ChartOptions, // Ugly workaround while waiting typings for chartjs 3.0,
      });
      setChartInstance(newChartInstance);
    }
  }, [assessment, chartContainer, t]);

  return (
    <Box height="100%" maxWidth="400px" margin="auto">
      <canvas ref={chartContainer} />
    </Box>
  );
};

const CampaignDetailRow = (props: CampaignDetailRowProps): JSX.Element => {
  const { assessment } = props;

  return (
    <Box width="100%" height="180px">
      <PieChart assessment={assessment} />
    </Box>
  );
};

export default CampaignDetailRow;
