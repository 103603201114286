import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import * as yup from 'yup';

import { Branch } from '../entities/Branch';
import { Position } from '../entities/Position';
import { useBranchesResource } from './store/useBranches';
import { usePositionsResource } from './store/usePositions';

export type PersonFormForm = {
  branches: Array<Branch>;
  email: string;
  first_name: string;
  hired_at?: string;
  last_name: string;
  position: null | Position;
  title?: string;
};

type PersonFormFormErrors = { [key in keyof PersonFormForm]: string };

const formInitialState: PersonFormForm = {
  first_name: '',
  last_name: '',
  email: '',
  hired_at: undefined,
  branches: [],
  position: null,
  title: undefined,
};

const formInitialErrors = (): PersonFormFormErrors => ({
  branches: '',
  email: '',
  first_name: '',
  hired_at: '',
  last_name: '',
  position: '',
  title: '',
});

type onSubmit = (event: React.MouseEvent<HTMLButtonElement>, formValues: PersonFormForm) => void;

type AddPersonFormReturnType = {
  loadingCondition: boolean;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  formErrors: PersonFormFormErrors;
  formValues: PersonFormForm;
  setFormValues: (form: PersonFormForm) => void;
  branches: Branch[];
  positions: Record<Position['name'], Position>;
  resetForm: () => void;
};

export default function useAddPersonForm(
  companyId: string,
  onSubmit: onSubmit,
  initialState = formInitialState
): AddPersonFormReturnType {
  const { t } = useTranslation(['company', 'errors']);

  const [branches, branchesRequest, branchesErr] = useBranchesResource(companyId);
  const [positions, positionsRequest, positionsErr] = usePositionsResource();

  const [formSchema, setFormSchema] = useState<null | yup.AnyObjectSchema>(null);
  const [formValues, setFormValues] = useState<PersonFormForm>(initialState);
  const [formErrors, setFormErrors] = useState<PersonFormFormErrors>(formInitialErrors());
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validate = useCallback(
    async (values) => {
      if (!formSchema) {
        return false;
      }

      try {
        await formSchema.validate(values, { abortEarly: false });
        setFormErrors(formInitialErrors());
        return true;
      } catch (err) {
        if (err.name === 'ValidationError') {
          const errors = formInitialErrors();

          (err as yup.ValidationError).inner.forEach((error) => {
            if (error.path) {
              if (Object.keys(errors).indexOf(error.path) >= 0) {
                errors[error.path as keyof PersonFormFormErrors] = error.message;
              }
            }
          });

          setFormErrors(errors);
          return Object.values(errors).filter((e) => e).length === 0;
        }

        return false;
      }
    },
    [formSchema, setFormErrors]
  );

  const debouncedValidate = useMemo(() => debounce(validate, 100), [validate]);

  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: t('errors:required'),
      },
      string: {
        min: ({ min }: { min: number }) => t('errors:invalidMin', { chars: min }),
        max: ({ max }: { max: number }) => t('errors:invalidMax', { chars: max }),
        email: t('errors:invalidEmail'),
      },
    });

    const schema = yup.object({
      first_name: yup.string().min(2).max(128).required(),
      last_name: yup.string().min(2).max(128).required(),
      email: yup.string().email().required(),
      hired_at: yup.mixed(),
      branches: yup.array<Branch>().min(1).required(),
      position: yup.mixed<Position>().required(),
      title: yup.string(),
    });

    setFormSchema(schema);
  }, [t]);

  useEffect(() => {
    if (formSubmitted) {
      debouncedValidate(formValues);
    }
  }, [debouncedValidate, formValues, formSubmitted]);

  useEffect(() => {
    if (branchesErr || positionsErr) {
      throw branchesErr || positionsErr;
    }
  }, [branchesErr, positionsErr]);

  useEffect(() => {
    setFormValues(initialState);
  }, [initialState]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setFormSubmitted(true);

    const isValid = await validate(formValues);
    if (isValid) {
      onSubmit(event, formValues);
    }
  };

  const resetForm = useCallback(() => {
    setFormValues(initialState || formInitialState);
    setFormErrors(formInitialErrors);
    setFormSubmitted(false);
  }, [setFormValues, initialState]);

  return {
    loadingCondition: !branchesRequest.inProgress && !positionsRequest.inProgress,
    handleSubmit,
    formErrors,
    formValues,
    setFormValues,
    branches,
    positions,
    resetForm,
  };
}
