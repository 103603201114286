import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequestState, Resource, useResource } from '@top-solution/utils';
import BranchesRepository from '../../api/BranchesRepository';
import { Branch } from '../../entities/Branch';
import { Company } from '../../entities/Company';
import { RootState } from '../../store/reducers';
import {
  ReadBranchesRequestAction,
  READ_BRANCHES_REQUEST,
  ReadBranchesSuccessAction,
  READ_BRANCHES_SUCCESS,
  ReadBranchesFailureAction,
  READ_BRANCHES_FAILURE,
  BranchesState,
} from '../../store/reducers/branches';

type UseBranches = {
  branches: Record<Branch['id'], Branch>;
  branchesByCompany: Record<Company['id'], Branch['id'][]>;
  readBranchList: (companyId: Company['id']) => void;
  readListRequest: RequestState;
};

export default function useBranches(): UseBranches {
  const dispatch = useDispatch();
  const { t } = useTranslation(['branch']);

  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    if (token) {
      BranchesRepository.updateAuthToken(token);
    }
  }, [token]);

  const branches = useSelector<RootState, BranchesState['branches']>((state: RootState) => state.branches.branches);
  const branchesByCompany = useSelector<RootState, BranchesState['byCompany']>(
    (state: RootState) => state.branches.byCompany
  );

  const readListRequest = useSelector((state: RootState) => state.branches.requests.readList);

  const readBranchList = useCallback(
    (companyId) => {
      dispatch<ReadBranchesRequestAction>({ type: READ_BRANCHES_REQUEST });

      const read = async () => {
        try {
          const branches = await BranchesRepository.getList(companyId);

          const branchesWithLabels = branches.map((branch) => ({
            ...branch,
            label: t(branch.name),
          }));

          dispatch<ReadBranchesSuccessAction>({
            type: READ_BRANCHES_SUCCESS,
            branches: branchesWithLabels,
            companyId,
          });
        } catch (error) {
          dispatch<ReadBranchesFailureAction>({ type: READ_BRANCHES_FAILURE, error });
        }
      };

      read();
    },
    [dispatch, t]
  );

  return {
    branches,
    branchesByCompany,
    readBranchList,
    readListRequest,
  };
}

// export function useBranchesResource() {
//   const { branches, readBranchList, readListRequest } = useBranches();
//   return useResource<typeof branches>(branches, readBranchList, readListRequest);
// }

export function useBranchesResource(companyId: string): Resource<Branch[]> {
  const { branches, branchesByCompany, readBranchList, readListRequest } = useBranches();

  const readCompanyWithId = useCallback(() => readBranchList(companyId), [readBranchList, companyId]);

  const companyBranches = useMemo(() => {
    const mappedCompanyBranches = [];
    const companyBranches = branchesByCompany[companyId];

    if (companyBranches) {
      for (let i = 0; i < companyBranches.length; i++) {
        const branchId = companyBranches[i];
        if (branchId) {
          const branch = branches[branchId];
          if (branch) {
            mappedCompanyBranches.push(branch);
          }
        }
      }
    }

    return mappedCompanyBranches;
  }, [branchesByCompany, companyId, branches]);

  const resourceName = useMemo(() => `branches-${companyId}`, [companyId]);

  return useResource(resourceName, companyBranches, readCompanyWithId, readListRequest);
}
